/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default {
    backlog: [],
    events: {},

    run()
    {
        this.backlog.forEach((callback) =>
        {
            callback(this.YT);
        });
        this.backlog = [];
    },

    register(callback)
    {
        if(this.YT)
        {
            this.Vue.nextTick(() =>
            {
                callback(this.YT);
            });
        }
        else
        {
            this.backlog.push(callback);
        }
    }
};