import { render, staticRenderFns } from "./QuestionnaireFrame.vue?vue&type=template&id=3b21cffe&scoped=true"
import script from "./QuestionnaireFrame.vue?vue&type=script&lang=js"
export * from "./QuestionnaireFrame.vue?vue&type=script&lang=js"
import style0 from "./QuestionnaireFrame.vue?vue&type=style&index=0&id=3b21cffe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b21cffe",
  null
  
)

export default component.exports