<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <PConvertibleModal v-model="isVisible" :max-width="sections.length > 0 ? '1200px' : '350px'" :hide-actions="sections.length === 0" no-padding no-toolbar no-action-spacer persistent force-action-toolbar :scrollable="true">

        <!-- Utility Modals -->
        <PPleaseWait v-model="submitState.show" :text="submitState.text" :subtext="submitState.subtext" :error="submitState.error"/>
        <PConfirmModal
                v-model="askSkip"
                title="Are you sure you want to skip the onboarding questionnaire?"
                yes-button-text="Keep going"
                no-button-text="Skip"
                max-width="600"
                @no="isVisible = false"
        >
            <p>Completing the questionnaire will help us show content that is tailored to you, helping get to the resources you need faster.</p>
            <p>If you choose to skip now, you can always retake the questionnaire at any time from the navigation bar.</p>
        </PConfirmModal>

        <!-- Loading Spinner for when we are getting the basic questions down from the server -->
        <div v-if="sections.length === 0" class="d-flex align-center justify-center flex-column pt-5 pb-5">
            <PLoadingSpinner/>
            <h1 class="text-center">Just checking a few things...</h1>
        </div>

        <v-stepper v-else v-model="currentSection" flat non-linear>
            <v-stepper-header :style="stepperHeaderStyles">
                <!-- intro -->
                <QuestionnaireStepperStep :step="1" step-icon="mdi-information-variant" :complete="currentSection > 1">Intro</QuestionnaireStepperStep>
                <v-divider style="max-width: 50px;"/>
                <template v-for="(section, sectionIndex) in sections">
                    <!-- sectionIndex + 1 = intro(1) -->
                    <!-- sectionIndex + 2 = intro(1) + start counting at 1(1) -->
                    <QuestionnaireStepperStep :key="'step'+(sectionIndex + 1)" :step="String(sectionIndex + 2)" :step-icon="section.icon" :complete="currentSection > sectionIndex + 2" editable>
                        {{ section.label }}
                    </QuestionnaireStepperStep>
                    <v-divider :key="'divider' + (sectionIndex + 1)" style="max-width: 50px;"/>
                </template>
                <!-- sections.length + 2 = intro(1) + sections(variable) + start counting at 1(1) -->
                <QuestionnaireStepperStep :step="sections.length + 2" :complete="currentSection > sections.length + 2" step-icon="mdi-party-popper">Finish</QuestionnaireStepperStep>
            </v-stepper-header>

            <v-stepper-items class="stepper-body" :class="{'is-mobile': $vuetify.breakpoint.mdAndDown}">
                <v-stepper-content :step="1">
                    <QuestionnaireIntro/>
                </v-stepper-content>
                <!-- sectionIndex + 2 = intro(1) + start counting at 1(1) -->
                <v-stepper-content v-for="(section, sectionIndex) in sections" :key="sectionIndex + 2" :step="sectionIndex + 2">
                    <QuestionnaireSection v-bind="section"/>
                </v-stepper-content>
                <v-stepper-content :step="sections.length + 2">
                    <QuestionnaireSummary/>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>

        <template v-if="sections.length > 0" #actions>
            <v-btn icon @click="askSkip = true">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn v-if="currentSection > 1" icon @click="currentSection--">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <!-- sections.length + 2 = intro(1) + sections(variable) + start counting at 1(1) -->
            <v-btn v-if="currentSection < sections.length + 2" :disabled="!canContinue" icon @click="nextPage">
                <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
            <v-btn v-else :disabled="!canContinue" icon @click="isVisible = false">
                <v-icon>mdi-send</v-icon>
            </v-btn>
        </template>

    </PConvertibleModal>
</template>

<script>

import QuestionnaireStepperStep from '@/views/Questionnaire/QuestionnaireStepperStep';
import QuestionResponseTypes from '@/views/Questionnaire/QuestionResponseTypes';
import { createCategory } from '@plust/client-common/src/PTLogger';
import PtAjax from '@plust/client-common/src/Ajax';
import QuestionnaireSection from '@/views/Questionnaire/QuestionnaireSection';
import QuestionnaireIntro from '@/views/Questionnaire/QuestionnaireIntro';
import QuestionnaireSummary from '@/views/Questionnaire/QuestionnaireSummary';
import QuestionTypes from '@/views/Questionnaire/QuestionTypes';
import RangeConstraints from '@/views/Questionnaire/RangeConstraints';

const Logger = createCategory('Questionnaire');

export default {
    name: 'QuestionnaireFrame',
    inject: [ 'getFullscreenMutex' ],
    provide()
    {
        return {
            filterDisplayedQuestions: this.filterDisplayedQuestions
        };
    },
    components: { QuestionnaireSummary, QuestionnaireIntro, QuestionnaireSection, QuestionnaireStepperStep },
    computed: {
        stepperHeaderStyles (){
            return {
                'flex-wrap': this.$vuetify.breakpoint.smAndDown ? 'nowrap' : undefined,
                'overflow-x': this.$vuetify.breakpoint.smAndDown ? "scroll" : "hidden"
            };
        },
        isVisible: {
            get: function ()
            {
                return this.visible;
            },
            set: async function (value)
            {
                if (value)
                {
                    if (this.fullscreenMutex !== null)
                        return;
                    this.fullscreenMutex = this.getFullscreenMutex();
                    await this.fullscreenMutex.waitForReady();
                    this.visible = true;
                }
                else
                {
                    this.visible = false;
                    if (this.fullscreenMutex !== null)
                    {
                        this.fullscreenMutex.release();
                        this.fullscreenMutex = null;
                    }
                    this.currentSection = 1;
                    for (let section of this.sections)
                    {
                        section.subsections = null;
                    }
                }
            }
        },
        currentSectionData()
        {
            if (this.currentSection - 2 < 0)
                return { notQuestionSection: true };
            if ((this.currentSection - 2) >= this.sections.length)
                return { notQuestionSection: true };
            return this.sections[this.currentSection - 2];
        },
        currentSectionQuestions()
        {
            return this.currentSectionData.subsections?.flatMap(x => x.questions);
        },
        allQuestions()
        {
            return this.sections.flatMap((section => section.subsections?.flatMap((subsection => subsection.questions)))).filter(question => question !== undefined);
        },
        sectionAnsweredQuestions()
        {
            return this.filterDisplayedQuestions(this.currentSectionQuestions)
                .filter(x => x.answer !== null)
                .flatMap(x =>
                {
                    if (x.type === QuestionTypes.Question)
                    {
                        return [ x.answer ];
                    }
                    else
                    {
                        return x.answer;
                    }
                });
        },
        allAnsweredQuestions()
        {
            /**
             * Filters out questions that hasn't been answered
             * QuestionGroups are replaced with their answers
             */
            return this.filterDisplayedQuestions(this.allQuestions)
                .filter(x => x.answer !== null)
                .flatMap(x =>
                {
                    if (x.type === QuestionTypes.Question)
                    {
                        return [ x.answer ];
                    }
                    else
                    {
                        return x.answer;
                    }
                });
        },
        canContinue()
        {
            return this.filterDisplayedQuestions(this.currentSectionQuestions)
                .flatMap(x =>
                {
                    if (x.type === QuestionTypes.Question)
                    {
                        return [ x.answer ];
                    }
                    else
                    {
                        return x.answer;
                    }
                }).filter(x => x.answer === null).length === 0;
        }
    },
    data: () => ({
        fullscreenMutex: null,
        visible: false,
        askSkip: false,
        currentSection: 1,
        sections: [],
        submitState: {
            show: false,
            error: false,
            text: 'Submitting Answers...',
            subtext: 'Please Wait...'
        },
        submitStateDefault: null
    }),
    watch: {
        currentSection()
        {
            this.$nextTick(() =>
            {
                if (this.currentSectionData.notQuestionSection)
                    return;
                if (this.currentSectionData.subsections === null)
                {
                    this.loadSection(this.currentSectionData.id);
                }
            });

        }
    },
    beforeDestroy()
    {
        if (this.fullscreenMutex !== null)
            this.fullscreenMutex.release();
    },

    mounted()
    {
        this.submitStateDefault = JSON.parse(JSON.stringify(this.submitState));
        setTimeout(async () =>
        {
            await this.loadSections();
            if (this.currentSectionData.notQuestionSection)
                return;
            await this.loadSection(this.currentSectionData.id);
        }, 1000); //Delay it a bit so we've got everything else ready.
    },

    methods: {
        open()
        {
            this.isVisible = true;
        },
        filterDisplayedQuestions(questions)
        {
            /**
             * if x is question group, pass x.questions back into filterDisplayedQuestions and if the returned array is empty, filter the group out.
             */
            return questions?.filter(x =>
            {
                if (x.type === QuestionTypes.QuestionGroup)
                {
                    if (this.filterDisplayedQuestions(x.questions).length === 0)
                        return false;
                }
                return this.computeQuestionDependencies(x);
            }) ?? [];
        },
        computeQuestionDependencies(question, originalQuestion = null, depth = 0)
        {
            if (originalQuestion !== null && question.id === originalQuestion.id)
                throw new Error(`Infinite recursion detected. Original Question: ${ originalQuestion.id } is cyclically dependent on itself through question ${ question.id }`);
            //If a question has no deps, it is always visible
            if ((question?.dependencies?.length ?? 0) === 0)
                return true;
            for (let dep of question.dependencies)
            {
                //1. Loop through all questions and all question group questions
                let depQ = null;
                questionLoop:
                for (let q of this.allQuestions)
                {
                    //2. Iterate over all questions, if qid matches standard question return it, otherwise check if it is a qgroup, check the qgroup questions for the id and return that, else just check the
                    // next question
                    if (q.type === QuestionTypes.Question && q.id === dep.dependency)
                    {
                        depQ = q;
                        break;
                    }
                    else if(q.type === QuestionTypes.QuestionGroup)
                    {
                        for(let questionGroupQuestion of q.questions)
                        {
                            if (questionGroupQuestion.id === dep.dependency)
                            {
                                let questionGroupQuestionClone = JSON.parse(JSON.stringify(questionGroupQuestion));
                                //3. Find the answer for the question group question in the question group and set it as questiongroupquestion.answer
                                questionGroupQuestionClone.answer = q.answer?.find(x=>x.id === questionGroupQuestion.id) ?? { id: null, answer: null, meta: null };
                                depQ = questionGroupQuestionClone;
                                break questionLoop;
                            }
                        }

                    }
                }
                //4. compute the dependencies.
                //find the dependent question
                if (depQ === undefined || depQ === null)
                    throw new Error(`Dependency not found for Question ${ dep.dependency }`);

                //Recursively check the dependencies for the dependent question. If the grandparent of this question isn't visible, neither should this one.
                if (!this.computeQuestionDependencies(depQ, question, depth + 1))
                    return false;

                //If the required answer is "Any", then just make sure any answer was provided by the user.
                if (dep.requiredAnswer === QuestionResponseTypes.Any && depQ.answer.answer !== null)
                    continue;

                if (dep.requiredAnswer === QuestionResponseTypes.PositiveOrUnknown || dep.requiredAnswer === QuestionResponseTypes.NegativeOrUnknown)
                {
                    if (depQ.answer.answer === QuestionResponseTypes.Unknown)
                        continue;
                    if ( dep.requiredAnswer === depQ.answer.answer + '_' + QuestionResponseTypes.Unknown )
                        continue;
                }

                //Otherwise make sure that the required answer matches.
                if (dep.requiredAnswer === depQ.answer.answer)
                    continue;

                //Check range constraint
                if (dep.rangeConstraint.contraint !== null)
                {
                    switch (dep.rangeConstraint.constraint)
                    {
                        case RangeConstraints.Equals:
                            if (dep.rangeConstraint.constraint !== depQ.answer.meta)
                                continue;
                            break;
                        case RangeConstraints.NotEquals:
                            if (dep.rangeConstraint.constraint === depQ.answer.meta)
                                continue;
                            break;
                        case RangeConstraints.LessThan:
                            if (depQ.answer.meta > dep.rangeConstraint.constraint)
                                continue;
                            break;
                        case RangeConstraints.LessThanOrEqual:
                            if (depQ.answer.meta >= dep.rangeConstraint.constraint)
                                continue;
                            break;
                        case RangeConstraints.GreaterThan:
                            if (depQ.answer.meta < dep.rangeConstraint.constraint)
                                continue;
                            break;
                        case RangeConstraints.GreaterThanOrEqual:
                            if (depQ.answer.meta <= dep.rangeConstraint.constraint)
                                continue;
                            break;
                    }
                }

                return false;
            }
            return true;
        },

        getQuestion(id)
        {
            for (let section of this.sections)
            {
                if (section.subsections === null)
                    continue;
                for (let subsection of section.subsections)
                {
                    for (let question of subsection.questions)
                    {
                        if (question.id === id)
                        {
                            return question;
                        }
                    }
                }
            }
            return null;
        },

        nextPage()
        {
            if (this.currentSection === 1)
                this.currentSection++;
            else if (this.currentSection > this.sections.length + 1)
                console.log('do completion event');
            else
                this.submitSectionAnswers();
        },

        async loadSections()
        {
            await PtAjax({
                url: '/data/questionnaire/sections',
                type: 'get',
                silent: { success: true },
                success: {
                    run: ({ body: { sections } }) =>
                    {
                        this.sections = sections.map(x =>
                        {
                            //Add a fake value to the data to enable vue reactivity when the full data is loaded.
                            x['subsections'] = null;
                            return x;
                        });
                    }
                }
            });
            Logger.debug(`Got ${ this.sections.length } sections.`);
        },

        async loadSection(sectionId)
        {
            Logger.debug(`Loading subsections for section ${ sectionId }`);
            await PtAjax({
                url: '/data/questionnaire/sections/' + sectionId + '/subsections',
                type: 'get',
                silent: { success: true },
                success: {
                    run: ({ body: { subsections } }) =>
                    {
                        let sectionIndex = this.sections.findIndex(x => x.id === sectionId);
                        //Add a fake value to each question data to enable vue reactivity when the full data is loaded.
                        for (let subsection of subsections)
                        {
                            for (let question of subsection.questions)
                            {
                                if (question.type === QuestionTypes.Question)
                                {
                                    question.answer = { id: null, answer: null, meta: null };
                                }
                                else if (question.type === QuestionTypes.QuestionGroup)
                                {
                                    question.answer = [];
                                    for (let subQuestion of question.questions)
                                    {
                                        subQuestion.answer = { id: null, answer: null, meta: null };
                                    }
                                }
                            }
                        }
                        this.sections[sectionIndex].subsections = subsections;
                    }
                }
            });
            Logger.debug(`Successfully loaded subsections for ${ sectionId }`);
            Logger.debug(`Loading previous answers for section ${ sectionId }...`);
            await PtAjax({
                url: '/data/questionnaire/sections/' + sectionId + '/answers',
                type: 'get',
                silent: { success: true },
                success: {
                    run: ({ body: { answers } }) =>
                    {
                        let sectionIndex = this.sections.findIndex(x => x.id === sectionId);
                        let subsections = this.sections[sectionIndex].subsections;
                        for (let answer of answers)
                        {
                            let handledAnswer = false;
                            for (let subsection of subsections)
                            {
                                for (let question of subsection.questions)
                                {
                                    if (question.type === QuestionTypes.Question)
                                    {
                                        if (answer.question === question.id)
                                        {
                                            question.answer = {
                                                id: answer.question,
                                                answer: answer.answer,
                                                meta: answer.meta
                                            };
                                            handledAnswer = true;
                                            break;
                                        }
                                    }
                                    else if (question.type === QuestionTypes.QuestionGroup)
                                    {
                                        for (let questionGroupQuestion of question.questions)
                                        {
                                            if (answer.question === questionGroupQuestion.id)
                                            {
                                                let breakMe = false;
                                                if (!Array.isArray(question.answer)) question.answer = [];
                                                for (let i = 0, j = question.answer.length; i < j; i++)
                                                {
                                                    if (question.answer[i].id === answer.question)
                                                    {
                                                        question.answer[i].answer = answer.answer;
                                                        question.answer[i].meta = answer.meta;
                                                        breakMe = true;
                                                        handledAnswer = true;
                                                        break;
                                                    }
                                                }
                                                if (breakMe) break;
                                                question.answer.push(
                                                    {
                                                        id: answer.question,
                                                        answer: answer.answer,
                                                        meta: answer.meta
                                                    }
                                                );
                                                handledAnswer = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                                if (handledAnswer)
                                    break;
                            }
                        }
                    }
                }
            });
            Logger.debug(`Successfully loaded previous answers for ${ sectionId }`);
        },

        async submitSectionAnswers()
        {
            this.submitState.show = true;
            await PtAjax({
                url: '/data/questionnaire/sections/' + this.currentSectionData.id + '/submit-response',
                type: 'post',
                data: this.sectionAnsweredQuestions,
                raw: true,
                silent: true,
                success: {
                    run: () =>
                    {
                        this.submitState.show = false;
                    }
                },
                error: {
                    run: ({ message }) =>
                    {
                        this.submitState.error = true;
                        this.submitState.text = 'Failed';
                        this.submitState.subtext = message;
                    }
                }
            });
            if (this.submitState.error)
                await new Promise((res) => setTimeout(res, 3000));
            if (!this.submitState.error)
                this.currentSection++;
            this.submitState.show = false;
            await new Promise((res) => setTimeout(res, 500));
            this.submitState = JSON.parse(JSON.stringify(this.submitStateDefault));
            await this.$store.dispatch('user/loadLatestProfileCompleteness');
        }
    }
};
</script>

<style lang="scss" scoped>
.stepper-body {
    max-height: 600px;
    height: 100%;
    overflow-y: auto;

    &.is-mobile {
        max-height: unset;
    }
}
</style>