/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

const GetPixels = require('get-pixels');
const { Vector2 } = require('./MathsLib');

//import GetPixels from 'get-pixels';
//import { Vector2 } from './MathsLib';

/**
 * Class to define a colour in RGB space
 */
class RGB
{
    constructor(r, g, b)
    {
        this.r = r;
        this.g = g;
        this.b = b;
    }

    /**
     * Converts the colour to a CSS rgb()
     * @returns string
     */
    toString()
    {
        return `rgb(${ this.r },${ this.g },${ this.b })`;
    }

    /**
     * Converts the colour to a hex code
     * @returns String
     */
    toHex()
    {
        return '#' + ((1 << 24) + (this.r << 16) + (this.g << 8) + this.b).toString(16).slice(1);
    }
}

/**
 * EdgeColourThief
 *
 * Obtains hex codes for the colours at the edge of an image
 *
 * @param {*} imgurl The URL for the image to use
 * @param {*} whiteAlpha If true then the alpha channel will be treated as it if were 255,255,255 if no colour data is present
 * @param {*} ignoreAspectRatio Vector2 of an aspect ratio to ignore
 * @return Array of hex codes for the edge colours, TRBL
 */
function EdgeColourThief(imgurl, whiteAlpha = true, ignoreAspectRatio = new Vector2(16, 9))
{
    if (imgurl === null) return Promise.reject('imgurl cannot be null');
    //Default colours
    let defaultColour = 'transparent', count = 0;
    let edgeColours =
            {
                top: defaultColour,
                right: defaultColour,
                bottom: defaultColour,
                left: defaultColour
            };

    let loadImage = (imgurl) =>
    {
        return new Promise((resolve, reject) =>
        {
            GetPixels(imgurl, function (error, imageData)
            {
                if (error) reject(error);
                else resolve(imageData);
            });
        });
    };

    let pixelArray = (imageData) =>
    {
        //This is the number of array elements per pixel (rgba)
        const pixelJump = 4;
        //Object for data about the sides
        let sides = {
            //Top is just the first line of the array
            top: {
                i: 0,
                j: imageData.shape[0] * pixelJump,
                iPlus: pixelJump,
                values: []
            },
            //Right is the 'end' of each list of imageData.shape[0] * pixelJump
            right: {
                i: imageData.shape[0] * pixelJump - pixelJump,
                j: imageData.data.length,
                iPlus: imageData.shape[0] * pixelJump,
                values: []
            },
            //Bottom is just the last 'line'
            bottom: {
                i: imageData.data.length - imageData.shape[0] * pixelJump,
                j: imageData.data.length,
                iPlus: pixelJump,
                values: []
            },
            //Left is just the first column
            left: {
                i: 0,
                j: imageData.data.length - 1 + pixelJump - imageData.shape[0] * pixelJump,
                iPlus: imageData.shape[0] * pixelJump,
                values: []
            }
        };

        //Loop through the sides
        for (let side in sides)
        {
            //Loop through the pixels for this edge, using the start & end points plus the jupms defined earlier
            for (let i = sides[side].i, j = sides[side].j, isAlpha; i < j; i += sides[side].iPlus)
            {
                isAlpha = imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2] + imageData.data[i + 3] === 0;
                sides[side].values.push([
                    (isAlpha) ? (whiteAlpha) ? 255 : 0 : imageData.data[i],
                    (isAlpha) ? (whiteAlpha) ? 255 : 0 : imageData.data[i + 1],
                    (isAlpha) ? (whiteAlpha) ? 255 : 0 : imageData.data[i + 2]
                ]);
            }
        }

        return {
            top: sides.top.values,
            right: sides.right.values,
            bottom: sides.bottom.values,
            left: sides.left.values
        };
    };

    return new Promise((resolve, reject) =>
    {
        loadImage(imgurl)
            .then(imageData =>
            {
                /**
                 * Check if the provided image has the aspect ratio to ignore
                 */
                if (
                    imageData.shape[0] / imageData.shape[1] == ignoreAspectRatio.x / ignoreAspectRatio.y &&
                    imageData.shape[1] / imageData.shape[0] == ignoreAspectRatio.y / ignoreAspectRatio.x
                )
                {
                    //Return the default colours
                    resolve(edgeColours);
                    return;
                }
                let pixels = pixelArray(imageData);

                for (let side in pixels)
                {
                    let rgb = new RGB(0, 0, 0);
                    //Reset count
                    count = 0;
                    //loop through the data
                    for (let i = 0, n = pixels[side].length; i < n; i++)
                    {
                        count++;
                        rgb.r += parseInt(pixels[side][i][0]); // red
                        rgb.g += parseInt(pixels[side][i][1]); // green
                        rgb.b += parseInt(pixels[side][i][2]); // blue
                    }

                    rgb.r = ~~(rgb.r / count);
                    rgb.g = ~~(rgb.g / count);
                    rgb.b = ~~(rgb.b / count);

                    edgeColours[side] = rgb.toHex();
                }

                resolve(edgeColours);
            })
            .catch(error =>
            {
                reject(error);
            });
    });
}

/**
 * LightenDarkenColour
 *
 * Accepts a colour code and an amount and returns the
 * colour lightened/darkened by the given amount
 *
 * @param {*} colour
 * @param {*} amount
 * @return String The modified colour
 */
function LightenDarkenColour(colour, amount)
{
    //Remove hash if sent
    if (colour[0] == '#')
        colour = colour.slice(1);

    // validate colour string
    colour = String(colour).replace(/[^0-9a-f]/gi, '');
    if (colour.length < 6)
        colour = colour[0] + colour[0] + colour[1] + colour[1] + colour[2] + colour[2];
    amount = amount || 0;

    // convert to decimal and change amountinosity
    let rgb = '#';
    for (let i = 0; i < 3; i++)
    {
        let c = parseInt(colour.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * amount)), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }

    return rgb;
}

/**
 * PASTELISE
 *
 * Accepts a hex colour and converts it to its pastel sibling
 * Based in part on code from CSSTricks https://css-tricks.com/converting-color-spaces-in-javascript/
 *
 * @param string colour
 * @return string
 * @author T J Collinson <tom.collinson@plus-t.co.uk>
 * @author Some Dude from CSS Tricks
 */
function Pastelise(colour)
{
    /**
     * These two control the 'pastelness' of the output colour
     */
    const min_saturation = 70;
    const min_lightness = 80;
    //Remove hash if sent
    if (colour[0] == '#')
        colour = colour.slice(1);
    //Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (colour.length == 3)
    {
        r = '0x' + colour[0] + colour[0];
        g = '0x' + colour[1] + colour[1];
        b = '0x' + colour[2] + colour[2];
    }
    else if (colour.length == 6)
    {
        r = '0x' + colour[0] + colour[1];
        g = '0x' + colour[2] + colour[3];
        b = '0x' + colour[4] + colour[5];
    }
    //Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin       = Math.min(r, g, b),
        cmax       = Math.max(r, g, b),
        delta      = cmax - cmin,
        h          = 0,
        lightness  = 0,
        saturation = 0;

    if (delta == 0)
        h = 0;
    else if (cmax == r)
        h = ((g - b) / delta) % 6;
    else if (cmax == g)
        h = (b - r) / delta + 2;
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
        h += 360;

    lightness = (cmax + cmin) / 2;
    saturation = delta == 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));
    saturation = +(saturation * 100).toFixed(1);
    lightness = +(lightness * 100).toFixed(1);

    if (lightness < min_lightness) lightness = min_lightness;
    if (saturation < min_saturation) saturation = min_saturation;

    //Comment out this return if you want to use hex
    //return `hsl(${ h },${ saturation }%,${ lightness }%)`;

    saturation /= 100;
    lightness /= 100;

    let c = (1 - Math.abs(2 * lightness - 1)) * saturation,
        x = c * (1 - Math.abs((h / 60) % 2 - 1)),
        m = lightness - c / 2;

    r = 0;
    g = 0;
    b = 0;

    if (0 <= h && h < 60)
    {
        r = c;
        g = x;
        b = 0;
    }
    else if (60 <= h && h < 120)
    {
        r = x;
        g = c;
        b = 0;
    }
    else if (120 <= h && h < 180)
    {
        r = 0;
        g = c;
        b = x;
    }
    else if (180 <= h && h < 240)
    {
        r = 0;
        g = x;
        b = c;
    }
    else if (240 <= h && h < 300)
    {
        r = x;
        g = 0;
        b = c;
    }
    else if (300 <= h && h < 360)
    {
        r = c;
        g = 0;
        b = x;
    }
    //Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);

    //Prepend 0s, if necessary
    if (r.length == 1)
        r = '0' + r;
    if (g.length == 1)
        g = '0' + g;
    if (b.length == 1)
        b = '0' + b;

    return `#${ r }${ g }${ b }`;
}

module.exports = {
    Pastelise,
    LightenDarkenColour,
    EdgeColourThief,
    RGB
};