<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-sheet class="tab-bar" role="tablist">
        <PScrollView :options="{alwaysShowTracks:false, overscrollEffect: 'bounce', damping: 0.1, plugins: { IdiotWuHorizontalScroll: { enabled: true } } }" :plugins="plugins">
            <div class="tab-bar-container">
                <TabButton v-for="tab in tabs" :key="tab.id" v-bind="tab" :active="activeTabIndex === tab.id" :changed-prop-name="changedPropName"/>
                <div class="tab-bar-padder"/> <!-- forces scrollbar to have extra padding -->
            </div>
        </PScrollView>
    </v-sheet>
</template>

<script>
import TabButton from '@/components/Layout/TabButton';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import IdiotWuHorizontalScroll from '@plust/client-common/src/IdiotWuHorizontalScroll';

/**
 * Tab bar used on the page body.
 *
 * TODO: make this use v-model instead of directly updating the router.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'TabBar',
    components: { TabButton },
    props: {
        /**
         * A list of tabs that should be displayed in the TabBar.
         */
        tabs: {
            type: Array,
            required: true
        },

        /**
         * The active tab index.
         */
        activeTabIndex: {
            type: [ Number, String ]
        },

        changedPropName: {
            type: String,
            default: 'tabId'
        }
    },
    computed: {
        plugins()
        {
            return [ OverscrollPlugin, IdiotWuHorizontalScroll ];
        }
    }
};
</script>

<style lang="scss" scoped>
.tab-bar {
    position: sticky;
    left: 0;
    top: 64px;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 20px 2px;
    height: 60px;
    z-index: 98;

    .tab-bar-container {
        display: inline-flex;
        align-items: center;
        padding: 5px 15px 5px 15px;
        height: 60px;

        @media(max-width: 767px) {
            height: 47px;
        }
    }

    @media(max-width: 767px) {

        height: 47px;
    }

    @media (max-width: 960px) {
        top: 60px;
        border-bottom: var(--highlight-colour) solid 1.5px;
    }

    .tab-bar-container * {
        flex-shrink: 0;
    }

    .tab-bar-padder {
        width: 1px;
        height: 15px;
    }
}
</style>

<style lang="scss">
.tab-bar .scroll-content {
    display: flex;
}
</style>