<!--
 - Copyright © 2020-2021 Positive Transition LTD
 - All rights reserved
 - For more information, please visit https://plus-t.co.uk/license
 -->

<template>
    <component class="p-icon" :is="lookup[icon]"/>
</template>

<script>
import IconList from '@/icons.js';

function GenerateComponentObject()
{
    let ComponentObject = {};
    for (let x in IconList)
    {
        let parts = x.split('-');
        let CompName = '';
        for (let x1 in parts)
        {
            CompName += parts[x1][0].toUpperCase() + parts[x1].substring(1);
        }
        ComponentObject[CompName] = IconList[x];
    }
    return ComponentObject;
}

function GenerateLookup()
{
    let LookupObject = {};
    for (let x in IconList)
    {
        let parts = x.split('-');
        let CompName = '';
        for (let x1 in parts)
        {
            CompName += parts[x1][0].toUpperCase() + parts[x1].substring(1);
        }
        LookupObject[x] = CompName;
    }
    return LookupObject;
}

export default {
    name: 'PIcon',
    components: { ...GenerateComponentObject() },
    props: {
        icon: {
            type: String,
            required: true
        }
    },
    computed: {
        lookup()
        {
            return { ...GenerateLookup() };
        }
    }
};
</script>

<style lang="scss" scoped>
.p-icon {
    fill: currentColor;

    * {
        width: 100%;
        height: 100%;
    }
}
</style>