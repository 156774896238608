<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="d-flex align-center flex-column">
        <v-img src="/assets/images/onboarding/slide2.svg" max-height="200px" contain></v-img>
        <h1 class="mt-3 text-center">Let's figure out how we can help...</h1>
        <div class="d-flex align-center flex-column mt-3">
            <v-container>
                <p class=" text-center">This quick questionnaire will help us personalise our systems to your needs. It's entirely optional, but it greatly enhances the value that you can get from using Positive Transition.</p>
                <p class=" text-center">If you aren't comfortable with answering a specific question, don't worry! Just select the "Prefer not to say/do not know" option and we'll provide basic content around that topic to help you decide later.</p>
                <p class=" text-center">
                    This questionnaire will assign you to a number of groups that identify your needs and interests. If you want to see these groups at any time, check your <span><router-link to="/settings/profile">profile settings</router-link></span>. This information will <b>never</b> be used for marketing purposes. If you have any questions about this, please consult our privacy policy.
                </p>
                <p class=" text-center">Furthermore, please keep in mind that these answers are not final. As you progress through your Positive Transition journey, your needs will change and evolve. If you feel like you've changed and aren't getting enough out of your experience, take the questionnaire again. We'll adapt to your new needs.</p>
                <p class=" text-center">Most importantly, remember: <b>there are no wrong answers.</b> Be yourself. Be as honest as you want, however the more accurately you answer, the better we can help you.</p>
                <p class=" text-center">Your answers are saved every time you complete a major section. If you feel like you want to stop, you can press the close button at any time and your progress will be retained. Once you're ready, hit the arrow down below to begin.</p>
            </v-container>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuestionnaireIntro'
};
</script>

<style scoped>

</style>