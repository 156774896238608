<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div>
        <v-dialog v-model="visible" persistent :hide-overlay="pushState" :no-click-animation="pushState" max-width="900" scrollable :disabled="pushState" :retain-focus="!pushState">
            <v-card style="height: 100%;">
                <div v-if="pushState" @click="providedPushOnBoarding(false)" class="return-activator"/>
                <v-toolbar flat>
                    <v-toolbar-title v-if="pushState">Click To Return To Onboarding</v-toolbar-title>
                    <v-spacer/>
                    <v-toolbar-items>
                        <v-btn v-if="pushState" icon>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn v-if="!pushState" icon @click="providedPushOnBoarding(true)">
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                        <v-dialog v-if="!pushState" v-model="dialogs.skip" max-width="400" persistent>
                            <template #activator="{on, attrs}">
                                <v-btn v-bind="attrs" v-on="on" icon>
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>Skip Onboarding</v-card-title>
                                <v-card-text>Are you sure you want to skip the onboarding slides?</v-card-text>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn text color="primary" @click="dialogs.skip = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="finishOnboarding">Skip</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="slide-body">
                    <v-tabs-items v-model="currentTab">
                        <v-tab-item v-for="(slide, index) in slides" :key="index" eager>
                            <OnboardingSlide v-bind="slide"></OnboardingSlide>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-toolbar class="mt-3" flat>
                    <button class="action-button back-button rubik" :class="{visible: currentTab !== 0}" @click="currentTab = Math.max(0, currentTab - 1)" tabindex="0">
                        Back
                    </button>
                    <v-spacer/>
                    <OnboardingDots :length="slides.length" :current="currentTab"/>
                    <v-spacer/>
                    <button class="action-button next-button rubik" v-show="currentTab !== numTabs" @click="currentTab = Math.min(numTabs, currentTab + 1)" tabindex="0">
                        Next
                    </button>
                    <button class="action-button next-button rubik" v-show="currentTab === numTabs" @click="finishOnboarding" tabindex="0">
                        Done
                    </button>
                </v-toolbar>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import OnboardingSlide from '@/views/Onboarding/OnboardingSlide';
import OnboardingDots from '@/views/Onboarding/OnboardingDots';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'OnboardingFrame',
    components: { OnboardingDots, OnboardingSlide },
    inject: [ 'setPushOnboarding', 'getFullscreenMutex' ],
    provide()
    {
        return {
            'manualTabSelect': this.manualTabSelect
        };
    },
    props: {
        pushState: {
            type: Boolean
        }
    },
    computed: {
        ...mapState([ 'user' ]),
        numTabs()
        {
            return this.slides.length - 1;
        },
        visible()
        {
            if (this.user.first_name === '')
                return false;
            return !this.user.onboarded || this.forceVisible;
        }
    },
    data()
    {
        return {
            currentTab: 0,
            forceVisible: false,
            dialogs: {
                skip: false
            },
            slides: [
                {
                    image: '/assets/images/onboarding/slide1.svg',
                    title: 'Thank you for signing up to Positive Transition!',
                    subheading: 'Would you like to add some sub-accounts before you begin?',
                    button: {
                        text: 'Manage my sub-accounts',
                        link: '/settings/accounts/manage'
                    }
                },
                {
                    image: '/assets/images/onboarding/slide2.svg',
                    title: 'Complete your profile',
                    subheading: 'Fill out the remaining fields in the profile section.',
                    button: {
                        text: 'Open my profile',
                        link: '/settings/profile/about-me'
                    }
                },
                {
                    image: '/assets/images/onboarding/slide3.svg',
                    title: 'Welcome to your new transition dashboard',
                    subheading: 'The dashboard is the control centre of the app.'
                },
                {
                    image: '/assets/images/onboarding/slide4.svg',
                    title: 'The Progress Compass',
                    subheading: 'The Progress Compass is your primary tool for tracking progression'
                },
                {
                    image: '/assets/images/onboarding/slide5.svg',
                    title: 'The Tasking Buttons',
                    subheading: 'See your current, pending and completed tasks here'
                },
                {
                    image: '/assets/images/onboarding/slide6.svg',
                    title: 'The Transition Elements',
                    subheading: 'Each element holds unique content and information.'
                },
                {
                    image: '/assets/images/onboarding/slide7.svg',
                    title: 'Your Calendar',
                    subheading: 'In your calendar, you can mark important milestones and events'
                }

            ]
        };
    },
    methods: {
        ...mapActions('user', [ 'setOnboardingCompleted' ]),
        providedPushOnBoarding(state)
        {
            this.setPushOnboarding(state);
        },
        manualTabSelect(newIndex)
        {
            this.currentTab = Math.min(this.numTabs, Math.max(0, newIndex));
        },
        async finishOnboarding()
        {
            this.dialogs.skip = false;
            await this.setOnboardingCompleted();
            this.$emit('complete');
        }
    }
};
</script>

<style lang="scss" scoped>
[data-push-onboarding="true"] .v-dialog__content {
    top: calc(100% - 85px);
    align-items: end;
}

.return-activator {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 50;
    cursor: pointer;
}

.slide-body {
    line-height: unset;
}

.action-button {

    cursor: pointer;
    bottom: 0;
    color: var(--highlight-colour);
    font-size: 20px;
    user-select: none;
    background: none;
    border: none;

    transition: transform 0.2s ease;

    &.next-button {
        right: 0;
        margin-right: 0.5rem;

        &:after {
            content: ">";
            font-weight: bold;
        }

        &:hover, &:focus {
            transform: translateX(5px);
        }
    }

    &.back-button {
        left: 0;
        margin-left: 0.5rem;
        opacity: 1;

        &:not(.visible) {
            opacity: 0;
        }

        &:before {
            content: "<";
            font-weight: bold;
        }

        &:hover, &:focus {
            transform: translateX(-5px);
        }
    }
}
</style>

<style lang="scss">
.onboarding-frame {
    .tabs {
        height: 100%;
        display: flex;
        flex-direction: column;

        @media(max-width: 500px) {
            height: calc(100% - 50px);
        }

        .tab-content {
            height: 100%;
        }

        .nav-link {
            background-color: white !important;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
        }
    }
}
</style>