/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default {
    NONE: 0x0,
    CAN_IGNORE: 0x1,
    NUMERIC: 0x2
};