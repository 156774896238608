<!--
  - Copyright © 2020-2023 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<script>
import Player from '@vimeo/player';

let pid = 0;
export default {
    name: 'VimeoPlayer',
    props: {
        height: {
            type: [ String, Number ]
        },
        width: {
            type: [ String, Number ]
        },
        videoId: {
            type: String
        },
        mute: {
            type: Boolean,
            default: false
        },
        playerVars: {
            type: Object,
            default: () => ({ autoplay: 0, time: 0 })
        }
    },
    data()
    {
        pid++;
        return {
            elementId: `vimeo-player-${ pid }`,
            player: {},
            duration: 0,
            currentTime: 0,
            timeInterval: null
        };
    },
    methods: {
        getDuration()
        {
            return this.duration;
        },
        getCurrentTime()
        {
            return this.currentTime;
        },
        async loadCurrentTime()
        {
            this.currentTime = await this.player.getCurrentTime();
        },
        async seekTo(time)
        {
            await this.player.setCurrentTime(time);
            this.currentTime = time;
            this.player.play();
        },
        playVideo()
        {
            this.player.play();
        },
    },
    async mounted()
    {
        this.player = new Player(this.elementId);
        this.player.on('ended', (e) => this.$emit('ended',e));
        this.player.on('play', (e) => this.$emit('playing',e));
        this.player.on('pause', (e) => this.$emit('paused',e));
        this.player.on('error',(e) => this.$emit('error',e));
        this.player.on('loaded',(e) => this.$emit('ready',e));

        this.duration = await this.player.getDuration();

        this.timeInterval = setInterval(this.loadCurrentTime, 3000);
    },
    watch:
    {
        mute()
        {
            this.player.setVolume(this.mute ? 0 : 1);
        },
        videoId()
        {
            this.player.loadVideo(this.videoId).then(async () => {
                this.duration = await this.player.getDuration();
            });
        },
        width()
        {
            this.player.setVolume(this.width);
        },
    },
    async beforeDestroy()
    {
        clearInterval(this.timeInterval);
        this.player?.destroy();
        delete this.player;
    }
};
</script>

<template>
    <div class="vimeo-player">
        <div :id="elementId" :data-vimeo-id="videoId" :data-vimeo-width="width" ></div>
    </div>
</template>

<style scoped lang="scss">

</style>