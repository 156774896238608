<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="pt-form-container">
        <template v-if="type==='textarea'">
            <v-textarea v-model="myValue" :id="css_id" :label="label" :placeholder="placeholder" :rules="validationRules" :counter="maxlength"></v-textarea>
        </template>
        <template v-else-if="type==='select'">
            <v-select v-model="myValue" :items="getOptions" :rules="validationRules" :id="css_id" :value="getValue"/>
        </template>
        <template v-else-if="type==='text'">
            <v-text-field v-model="myValue" :id="css_id" :label="label" :placeholder="placeholder" :rules="validationRules" :counter="maxlength"/>
        </template>
    </div>
</template>

<script>
/**
 * Defines form fields for use in +t applications
 * @author T J Collison <tom.collinson@plus-t.co.uk>
 */
import ValidationRules from '@plust/client-common/src/ValidationRules';

export default {
    name: 'PTFormField',
    mixins: [ ValidationRules ],
    props: {
        css_id: {
            type: String
        },
        label: {
            type: String
        },
        name: {
            type: String
        },
        placeholder: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        attributes: {
            type: Array,
            default: function ()
            {
                return [];
            }
        },
        options: {
            type: Array,
            default: function ()
            {
                return [];
            }
        },
        value: {}
    },
    computed: {

        myValue: {
            get()
            {
                return this.value;
            },
            set(value)
            {
                this.$emit('input', value);
            }
        },

        validationRules()
        {
            let theRules = [];
            if (this.required)
                theRules.push(this.rules.required);

            return theRules;
        },

        /**
         * Determines if the element is required
         */
        required()
        {
            let value = this.getAttribute('required');
            return value == null;
        },
        /**
         * Determines the maxlength attr of the element if set
         */
        maxlength()
        {
            let value = this.getAttribute('maxlength');
            return (value === false) ? '' : value;
        },
        /**
         * Returns an options array to be used by b-select
         */
        getOptions()
        {
            let opt = [];
            if (this.placeholder !== '')
            {
                opt.push({
                    text: this.placeholder,
                    value: null,
                    disabled: true
                });
            }

            for (let i = 0, j = this.options.length; i < j; i++)
            {
                opt.push({
                    value: this.options[i].value,
                    text: this.options[i].label
                });
            }
            return opt;
        },
        /**
         * Returns the default selected for a select element
         */
        getValue()
        {
            //Loop through all options
            for (let i = 0, j = this.options.length; i < j; i++)
            {
                if (('selected' in this.options[i]) && this.options[i].select) return this.options[i].value;
            }

            if (this.placeholder !== '') return null;

            return '';
        }
    },
    methods: {
        /**
         * Locates an attribute from the props
         */
        getAttribute(name)
        {
            for (let i = 0, j = this.attributes.length; i < j; i++)
            {
                if (this.attributes[i].name == name)
                {
                    return this.attributes[i].value;
                }
            }
            return false;
        }
    }
};
</script>