<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="quickbar-section" :class="{'no-divider': this.hide_divider}">
        <div v-if="!hide_divider" class="quickbar-divider"/>
        <quickbar-item v-for="item in items" :key="item.label" v-bind="item"/>
    </div>
</template>

<script>
import QuickbarItem from '@/components/Layout/Menus/Quickbar/QuickbarItem';
import MenuSectionCommon from '@/components/Layout/Menus/MenuSectionCommon';

/**
 * A section in the QuickBar.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'QuickbarSection',
    components: { QuickbarItem },
    mixins: [ MenuSectionCommon ]
};
</script>

<style lang="scss" scoped>
.quickbar-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 2px solid var(--highlight-colour);
}

.quickbar-section {

    &.no-divider {
        margin-top: 0.5rem;
    }

}
</style>