<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-app
            class="positive-transition"
            :data-hide-navigation="$route.meta.hide_navigation"
            :data-push-onboarding="pushOnboarding"

    >
        <vue-snotify/>
        <LoadingPane
            colour="green"
            @closed="afterLoadingClose"
            :transition-length="loadingTransitionLength"
        />
        <OnboardingFrame v-if="!$route.meta.hide_navigation" :push-state="this.pushOnboarding" @complete="openQuestionnaire" />
        <QuestionnaireFrame v-if="!$route.meta.hide_navigation" ref="onboardingQuestionnaire"/>
        <template v-if="!$route.meta.hide_navigation">
            <NavigationBar @opennav="toggleSupermenu" @skip-navigation="skipNavigation"/>
            <SuperMenu :sidebar_active="supermenuActive" :show-top="showQuestionnaireWidget" @changed="setSupermenuState">
                <template #top>
                    <QuestionniareSupermenuWidget v-if="showQuestionnaireWidget"/>
                </template>
            </SuperMenu>
            <QuickBar/>
        </template>

        <v-snackbar :value="out_of_date" v-if="showUpdatePrompt" :timeout="-1" top app dark>
            <div class="action-snackbar out-of-date-notif">
                <v-icon>$pt-logo-small</v-icon>
                <span>An update is available. Click Refresh to update &amp; enjoy the latest features.</span>
            </div>
            <template #action>
                <v-btn dark color="primary" @click="refreshPage">Refresh</v-btn>
            </template>
        </v-snackbar>

        <PPageBlur v-if="is_logged_out">
            <v-snackbar :value="true" :timeout="-1" top app dark>
                <div class="action-snackbar logged-out-notif">
                    <v-icon>$pt-logo-small</v-icon>
                    <span>You have been logged out. You must <a @click.prevent="doLogout" href="#">login</a> to continue.</span>
                </div>
                <template #action>
                    <v-btn dark color="primary" @click.prevent="doLogout">Login</v-btn>
                </template>
            </v-snackbar>
        </PPageBlur>

        <!-- global modals that need to be activated anywhere -->

        <FeedbackModal v-model="dialogs.feedback"/>
        <PStaticDocumentModal v-model="dialogs.privacy" title="Privacy Policy" document="privacy"/>
        <PStaticDocumentModal v-model="dialogs.cookies" title="Cookie Policy" document="cookies"/>
        <PStaticDocumentModal v-model="dialogs.terms" title="Terms and Conditions" document="terms"/>
        <PStaticDocumentModal v-model="dialogs.no_key" title="Missing Key/Service Number" document="no-key"/>

        <v-main>
            <div class="body-flex">
                <router-view
                    ref="main"
                    @moduleChanged="handleModuleChanged"
                />
                <PageFooter v-if="!$route.meta.hide_navigation"/>
            </div>
            <!--
            Offset required on desktop to take account of the quickbar
            -->
            <scrollable-indicator
                    :scroll-top="scrollTop"
                    :offset="isMobile ? 0 : 80"
                    :force="forceSICalculation"
                    :color="highlight_colour"
            />
        </v-main>
    </v-app>
</template>

<script>
import LoadingPane from '@/views/System/LoadingPane';
import NavigationBar from '@/components/Layout/NavigationBar';
import SuperMenu from '@/components/Layout/Menus/SuperMenu/SuperMenu';
import QuickBar from '@/components/Layout/Menus/Quickbar/QuickBar';
import { mapState } from 'vuex';
import OnboardingFrame from '@/views/Onboarding/OnboardingFrame';
import PStaticDocumentModal from '@/components/Layout/Modals/PStaticDocumentModal';
import PPageBlur from '@/components/PPageBlur';
import FeedbackModal from '@/views/Feedback/FeedbackModal';
import PageFooter from '@/components/Layout/PageFooter';
import QuestionnaireFrame from '@/views/Questionnaire/QuestionnaireFrame';
import QuestionniareSupermenuWidget from '@/views/Questionnaire/QuestionniareSupermenuWidget';
import ScrollableIndicator from '@/components/ScrollableIndicator';
import common from '@/views/common';

const overlayQueue = [];

export default {
    name: 'PositiveTransition',
    mixins: [ common ],
    components: { QuestionniareSupermenuWidget, PStaticDocumentModal, OnboardingFrame, QuickBar, SuperMenu, NavigationBar, LoadingPane, PPageBlur, FeedbackModal, PageFooter, QuestionnaireFrame, ScrollableIndicator },
    provide()
    {
        return {
            'supermenu': {
                set: this.setSupermenuState,
                toggle: this.toggleSupermenu,
                open: this.openSupermenu,
                close: this.closeSupermenu
            },
            'getFullscreenMutex': this.getFullscreenMutex,
            'openOnboardingQuestionnaire': () => this.$refs.onboardingQuestionnaire.open(),
            'setPushOnboarding': this.setPushOnboarding,
            'setGlobalModalState': this.setGlobalModalState
        };
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    computed: {
        ...mapState([ 'highlight_colour', 'out_of_date', 'logged_in' ]),

        showQuestionnaireWidget()
        {
            return this.$store.state.questionnaire.hasContent ?? false;
        },

        /**
         * Calculate if the user is logged out & should be notified about it
         * i.e. they aren't on the login page
         */
        is_logged_out()
        {
            return !this.logged_in && !this.$route?.meta?.insecure;
        },
        showUpdatePrompt()
        {
            return process.env.VUE_APP_PT_NO_UPDATE !== 'true';
        }
    },
    watch: {
        highlight_colour()
        {
            this.$emit('updateHead');
        },
        /**
         * Watch for changes in route so that we can run any code that needs to be done when a 'page' loads
         */
        $route()
        {
            this.initScroll();
        }
    },
    head: {
        meta()
        {
            return [
                { name: 'theme-color', content: this.$store.state.highlight_colour, id: 'browser-theme' }
            ];
        }
    },
    data()
    {
        return {
            supermenuActive: false,
            pushOnboarding: false,
            dialogs: {
                privacy: false,
                cookies: false,
                terms: false,
                no_key: false,
                feedback: false
            },
            scrolling: false,
            scrollTop: 0,
            scrollTimeout: null,
            forceSICalculation: true,
            loadingTransitionLength: 2000
        };
    },
    methods: {
        /**
         * Get a mutex lock that allows the calling code to wait for the previous fullscreen element to be removed by abusing promises.
         *
         * @returns {{waitForReady(): Promise<void>, resolve: null, release(): void, promise: Promise<unknown>}}
         */
        getFullscreenMutex()
        {
            //Extract the resolution function from a promise
            let mutexResolve = null;
            let promise = new Promise((res) =>
            {
                mutexResolve = res;
            });
            let mutex = {
                promise,
                resolve: mutexResolve,
                /**
                 * Await this function to efficiently wait for the mutex to resolve without wasting CPU cycles.
                 * Skips immediately if the lock was already released.
                 *
                 * @returns {Promise<void>}
                 */
                async waitForReady()
                {
                    await this.promise;
                },

                /**
                 * When finished with the mutex, call this to release the lock and allow the next window to display.
                 */
                release()
                {
                    let index = overlayQueue.findIndex(x => x === this);
                    //Check if this mutex has already been released.
                    if (index === -1)
                        return;
                    overlayQueue.splice(index, 1);
                    //Resolve the next lock if we were the active lock and if there are locks to resolve.
                    if (index === 0 && overlayQueue.length > 0)
                        overlayQueue[0].resolve();
                }
            };
            overlayQueue.push(mutex);
            //If we've just added the only mutex to the list, resolve it immediately.
            //But leave it in there so it can resolve the next mutex.
            if (overlayQueue.length === 1)
                overlayQueue[0].resolve();
            return mutex;
        },
        refreshPage()
        {
            window.location.reload();
        },
        setSupermenuState(state)
        {
            this.supermenuActive = state;
        },
        toggleSupermenu()
        {
            this.setSupermenuState(!this.supermenuActive);
        },
        openSupermenu()
        {
            this.setSupermenuState(true);
        },
        closeSupermenu()
        {
            this.setSupermenuState(false);
        },
        setPushOnboarding(state)
        {
            this.pushOnboarding = state;
        },
        setGlobalModalState(modalName, modalState)
        {
            this.dialogs[modalName] = modalState;
        },
        skipNavigation()
        {
            const focusable = this.$refs.main.$el.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            if (focusable.length > 0)
                focusable[0].focus();
        },
        doLogout()
        {
            this.$store.commit('set_secure_initialisation_state', false);
            this.$store.commit('set_initialisation_state', false);
            this.$store.dispatch('clearSecureStorage');
            this.$router.push({ path: '/' });
        },
        openQuestionnaire()
        {
            this.$refs.onboardingQuestionnaire.open();
        },
        handleScroll()
        {
            if (this.scrollTimeout !== null)
                clearTimeout(this.scrollTimeout);
            this.scrollTimeout = window.setTimeout(
                this.handleScrollEnd,
                50
            );
            this.scrolling = true;
            this.scrollTop = document.documentElement.scrollTop;
        },
        handleScrollEnd()
        {
            this.scrolling = false;
        },
        initScroll()
        {
            /**
             * Use nextTick to do our work after the new page has loaded
             */
            this.$nextTick(() =>{
                //Set the scrollTop to something we know it can never be to force scrollable-indicator to update
                this.scrollTop = -1;
                //Give a slight timeout before getting the real scrollTop value
                setTimeout(() => {
                    this.handleScroll();
                },10);
            });
        },
        afterLoadingClose()
        {
            setTimeout(
                this.initScroll,
                this.loadingTransitionLength
            );
        },
        /**
         * Run any code here that needs to be run after the module has been changed
         */
        handleModuleChanged()
        {
            this.scrollTop = -1;
            setTimeout(
                this.handleScroll,
                10
            );
        }
    }
};
</script>

<style lang="scss">
.positive-transition {

    .body-flex {
        display: flex;
        min-height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }

    .action-snackbar {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .v-icon {
            padding-right: 15px;
        }
    }
}
</style>