/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default [
    {
        path: '/gps',
        name: 'GP Search',
        component: () => import(/* webpackChunkName: "gp-search"*/ '@/views/GPs/GPSearch'),
        meta: {
            icon()
            {
                return '$module-health';
            }
        }
    }
];