/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import { DateTime } from 'luxon';
import PtAjax from '@plust/client-common/src/Ajax';
import { createCategory } from '@plust/client-common/src/PTLogger';

const PTLogger = createCategory('Vuex|CalendarMain');

/**
 * This Vuex module contains all of the user's calendar events.
 *
 * TODO: sync events with the server over time.
 */
export default {
    namespaced: true,
    state: () => ({
        events: []
    }),
    mutations: {
        setInitialState(state, data)
        {
            Object.assign(state, data);
        },
        clearInitialState(state)
        {
            state.events = [];
        },
        /**
         * Update event data in the local store.
         *
         * @param state
         * @param id
         * @param event
         */
        setEventData(state, { id, event })
        {
            PTLogger.debug(`Updating calendar vuex store for event ${ id }...`, event);
            let evt = state.events.find(x =>
            {
                return x.id === id;
            });
            let evtObj = JSON.parse(JSON.stringify(event));
            evtObj.id = Number(evtObj.id);
            for (let x in evtObj)
            {
                evt[x] = evtObj[x];
            }
            PTLogger.debug(`Event update in vuex successful for event ${ id }`);
        },

        /**
         * Push a new event to the local store.
         * @param state
         * @param event
         */
        pushEvent(state, event)
        {
            state.events.push(event);
        },

        removeEvent(state, { eventId })
        {
            PTLogger.debug(`Removing event with id ${ eventId }`);
            let index = state.events.findIndex(x => Number(x.id) === Number(eventId));
            if (index > -1)
            {
                state.events.splice(index, 1);
                PTLogger.debug(`Successfully removed event with id ${ eventId }`);
            }
            else
            {
                PTLogger.warning(`Can't find event with id ${ eventId } to remove.`);
            }
        },

        async setEventCompletionState(state, { eventId, completionState })
        {
            let evt = state.events.find(x => Number(x.id) === Number(eventId));
            evt.complete = completionState;
            /**
             * This param is used to tell the front end that it should still display the event in the tasking table
             * This is such that completed events should still be shown if marked as complete in the current session
             */
            evt.feComplete = completionState;
            await PtAjax({
                url: `/data/calendar/events/${ eventId }/complete`,
                type: 'post',
                data: { value: completionState },
                silent: { success: true }
            });
        }
    },
    actions: {
        /**
         * Load the initial calendar state from the server.
         *
         * TODO: make this only send relevant events and stream older events as needed.
         *
         * @param commit
         * @returns {Promise<void>}
         */
        async loadInitialState({ commit })
        {
            PTLogger.debug('Loading initial calendar state...');
            await PtAjax({
                url: '/data/calendar/events',
                type: 'get',
                silent: true,
                success: {
                    run: (data) =>
                    {
                        PTLogger.debug('CalendarMain initial state loaded.');
                        commit('setInitialState', data.body);
                    }
                },
                error: {
                    run: (data) =>
                    {
                        PTLogger.error('Failed to fetch initial calendar state.', data);
                        throw data;
                    }
                }
            });
        },

        async clearSecureState({ commit })
        {
            commit('clearInitialState');
        },

        /**
         * Create an event locally and on the server.
         * @param commit
         * @param event
         * @returns {Promise<boolean>}
         */
        async createEvent({ commit }, { event })
        {
            PTLogger.debug('Creating event...', event);
            let fail = false;
            await PtAjax({
                url: '/data/calendar/events',
                type: 'post',
                data: event,
                silent: { success: true },
                success: {
                    run: ({ body: { event } }) =>
                    {
                        commit('pushEvent', event);
                    }
                },
                error: {
                    run: () =>
                    {
                        fail = true;
                    }
                }
            });
            return !fail;
        },

        /**
         * Update an event locally and on the server.
         *
         * @param commit
         * @param state
         * @param id
         * @param event
         * @param revert
         * @returns {Promise<void>}
         */
        async updateEvent({ commit, state }, { id, event, revert })
        {
            PTLogger.debug(`Updating event ${ id } to new state...`, event);
            let evt = state.events.find(x =>
            {
                return x.id === Number(id);
            });
            evt = JSON.parse(JSON.stringify(evt));
            let evtObj = JSON.parse(JSON.stringify(event));
            let startDt = DateTime.fromSQL(evt.start, { zone: 'UTC' });
            let endDt = DateTime.fromSQL(evt.end, { zone: 'UTC' });
            let originalDuration = startDt.diff(endDt);
            for (let x in evtObj)
            {
                if ([ 'extendedProps' ].indexOf(x) >= 0)
                    continue;
                evt[x] = evtObj[x];
            }

            startDt = DateTime.fromSQL(evt.start, { zone: 'UTC' });

            if (evtObj.end === undefined)
            {
                evt.end = startDt.plus(originalDuration).toFormat('y-MM-dd HH:mm:ss');
            }

            await PtAjax({
                url: `/data/calendar/events/${ id }`,
                type: 'post',
                data: evt,
                silent: { success: true },
                success: {
                    run: () =>
                    {
                        PTLogger.debug(`Successfully requested calendar data update for event ${ id }. Committing to vuex...`);
                        commit('setEventData', { id: Number(id), event });
                    }
                },
                error: {
                    run: () =>
                    {
                        revert();
                    }
                }
            });
        },

        /**
         * Delete an event locally and on the server.
         *
         * @param commit
         * @param id
         * @param revert
         * @returns {Promise<void>}
         */
        async deleteEvent({ commit }, { id, revert })
        {
            await PtAjax({
                url: `/data/calendar/events/${ id }`,
                type: 'delete',
                silent: { success: true },
                success: {
                    run: () =>
                    {
                        commit('removeEvent', { eventId: Number(id) });
                    }
                },
                error: {
                    run: () =>
                    {
                        revert();
                    }
                }
            });
        }
    },
    getters: {
        all: (state) => state,
        currentEvents: (state) =>
        {
            let evts = [];
            let dtNow = DateTime.utc();
            let dtPlusSeven = DateTime.utc().plus({ days: 7 });
            let data = state.events;
            if (data === undefined || data === null)
                return [];
            for (let evt of data)
            {
                let dt = DateTime.fromSQL(evt.start, { zone: 'UTC' });
                if (dt >= dtNow && dt <= dtPlusSeven)
                    evts.push(evt);
            }
            return evts;
        },
        /**
         * Current tasks are defined as tasks within the next week
         *
         * @param {*} state
         * @returns []
         */
        currentTasks: (state) =>
        {
            let evts = [];
            let dtNow = DateTime.utc();
            let dtPlusSeven = DateTime.utc().plus({ days: 7 });
            let data = state.events;
            if (data === undefined || data === null)
                return [];
            for (let evt of data)
            {
                if (!evt.isTask)
                    continue;
                let dt = DateTime.fromSQL(evt.start, { zone: 'UTC' });
                if (dt >= dtNow && dt <= dtPlusSeven)
                    evts.push(evt);
            }
            return evts;
        },
        overdueEvents: (state) =>
        {
            let events = [];
            let dtNow = DateTime.utc();
            let data = state.events;
            if (data === undefined || data === null)
                return [];
            for (let event of data)
            {
                let dtEnd = DateTime.fromSQL(event.end, { zone: 'UTC' });
                if (event.allDay)
                {
                    dtEnd = dtEnd.plus({ days: 1 }).set({ hour: 0, minute: 0, second: 0 });
                }
                if (dtEnd < dtNow)
                    events.push(event);
            }
            return events;
        },
        overdueTasks: (state) =>
        {
            let events = [];
            let dtNow = DateTime.utc();
            let data = state.events;
            if (data === undefined || data === null)
                return [];
            for (let event of data)
            {
                if (!event.isTask)
                    continue;
                let dtEnd = DateTime.fromSQL(event.end, { zone: 'UTC' });
                if (event.allDay)
                {
                    dtEnd = dtEnd.plus({ days: 1 }).set({ hour: 0, minute: 0, second: 0 });
                }
                if (dtEnd < dtNow)
                    events.push(event);
            }
            return events;
        },
        upcomingEvents: (state) =>
        {
            let events = [];
            let dtNow = DateTime.utc();
            let dtThen = dtNow.plus({ months: 2 });
            let data = state.events;
            if (data === undefined || data === null)
                return [];
            for (let event of data)
            {
                let dtStart = DateTime.fromSQL(event.start, { zone: 'UTC' });
                if (event.allDay)
                {
                    dtStart = dtStart.set({ hour: 0, minute: 0, second: 0 });
                }
                if (dtStart > dtNow && dtStart < dtThen)
                    events.push(event);
            }
            return events;
        },
        /**
         * Upcomming tasks are defined as those from +7 days to +2months
         *
         * @param {*} state
         * @returns
         */
        upcomingTasks: (state) =>
        {
            let events = [];
            let dtNow = DateTime.utc().plus({ days: 7 });
            let dtThen = dtNow.plus({ months: 2 });
            let data = state.events;
            if (data === undefined || data === null)
                return [];
            for (let event of data)
            {
                if (!event.isTask)
                    continue;
                let dtStart = DateTime.fromSQL(event.start, { zone: 'UTC' });
                if (event.allDay)
                {
                    dtStart = dtStart.set({ hour: 0, minute: 0, second: 0 });
                }
                if (dtStart > dtNow && dtStart < dtThen)
                    events.push(event);
            }
            return events;
        }
    }
};