<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-dialog ref="dialog" v-model="modal" :return-value.sync="tempValue" persistent width="290px" :disabled="readonly">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="renderValue" prepend-icon="mdi-calendar" :rules="rules" :label="label" readonly v-bind="attrs" v-on="on"/>
        </template>
        <v-time-picker v-model="tempValue" scrollable>
            <v-spacer/>
            <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
            <v-btn text color="primary" @click="save">OK</v-btn>
        </v-time-picker>
    </v-dialog>
</template>

<script>

import { DateTime } from 'luxon';

export default {
    name: 'PTimeInput',
    props: {
        type: {
            type: String,
            default: 'time'
        },
        label: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        value: {},
        rules: {
            type: Array,
            default: () => ([])
        }
    },
    computed: {
        renderValue()
        {
            if(this.tempValue === null || this.tempValue === undefined)
            {
                return '--:--';
            }
            return DateTime.fromSQL(this.tempValue, { locale: 'en-GB' }).toFormat('h:mm a');
        }
    },
    data()
    {
        return {
            modal: false,
            tempValue: null
        };
    },
    mounted()
    {
        this.tempValue = this.value;
    },
    watch: {
        value()
        {
            this.tempValue = this.value;
        }
    },
    methods: {
        save()
        {
            this.$emit('input', this.tempValue);
            this.$refs.dialog.save(this.tempValue);
        }
    }
};
</script>