/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import './scss/styles.js';

//Libs
import Vue from 'vue';
import store from '@/store';
import Axios from 'axios';
import Snotify from 'vue-snotify';
import PortalVue from 'portal-vue';
import VueHead from 'vue-head';
import VueKinesis from 'vue-kinesis';
import { DynamicReactiveRefs } from 'vue-reactive-refs';
import ClientCommon from '@plust/client-common';

//Plugins
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import router from './router/index';

//Global Components (these don't need to be imported in other files)
import PositiveTransition from '@/PositiveTransition';
import PLogo from '@/components/Layout/PLogo';
import MaintenancePane from '@/views/System/MaintenancePane';
import PLoremIpsum from '@/components/PLoremIpsum';
import PProgressBar from '@/components/PProgressBar';
import NotImplemented from '@/components/NotImplemented';
import PasswordEntry from '@/components/Input/PPasswordEntry';
import PPictureGroup from '@/components/PPictureGroup';
import StaticDocument from '@/components/PStaticDocument';
import PDateInput from '@/components/Input/PDateInput';
import PTimeInput from '@/components/Input/PTimeInput';
import PFocusTrap from '@/components/Input/PFocusTrap';
import PPleaseWait from '@/components/PPleaseWait';
import PTabs from '@/components/Layout/Tabs/PTabs';
import PTabItem from '@/components/Layout/Tabs/PTabItem';
import PConvertibleModal from '@/components/Layout/Modals/PConvertibleModal';
import PScrollView from '@/components/PScrollView';
import PAlertModal from '@/components/Layout/Modals/PAlertModal';
import PLoadingSpinner from '@/components/PLoadingSpinner';
import PIcon from '@/components/PIcon';
import PConfirmModal from '@/components/Layout/Modals/PConfirmModal';
import HoverDirective from '@/plugins/HoverDirective';

//Init our error reporter
Vue.config.productionTip = false;

Vue.component('PLogo', PLogo);
Vue.component('PLoremIpsum', PLoremIpsum);
Vue.component('PProgressBar', PProgressBar);
Vue.component('NotImpl', NotImplemented);
Vue.component('PPasswordEntry', PasswordEntry);
Vue.component('PPictureGroup', PPictureGroup);
Vue.component('PStaticDocument', StaticDocument);
Vue.component('PDateInput', PDateInput);
Vue.component('PTimeInput', PTimeInput);
Vue.component('PFocusTrap', PFocusTrap);
Vue.component('PPleaseWait', PPleaseWait);
Vue.component('PTabs', PTabs);
Vue.component('PTabItem', PTabItem);
Vue.component('PConvertibleModal', PConvertibleModal);
Vue.component('PAlertModal', PAlertModal);
Vue.component('PConfirmModal', PConfirmModal);
Vue.component('PScrollView', PScrollView);
Vue.component('PLoadingSpinner', PLoadingSpinner);
Vue.component('PIcon', PIcon);

/**
 * Configure Axios for use with Vue
 * This can be removed once all the ajax requests use the new ajax function
 */
Vue.prototype.$http = Axios;
Vue.prototype.axios = Axios;

//Lib/Plugin Init
Vue.use(ClientCommon);
Vue.use(Snotify);
Vue.use(HoverDirective);
Vue.use(PortalVue);
Vue.use(VueHead);
Vue.use(DynamicReactiveRefs);
Vue.use(VueKinesis);

console.log(
    '%c ⚠ WARNING ⚠ %c\nUse of this console may allow attackers to impersonate you and steal your information using a social-engineering attack called Self-XSS.\n\n%cDo NOT enter or paste code that you don\'t understand.%c',
    'background: yellow; color: red; font-size: 25px;',
    'background: transparent; color: unset; font-size: 25px',
    'background: transparent; color: red; font-size: 25px',
    'background: transparent; color: unset; font-size: 25px'
);

(async function ()
{
    let maintResponse = await Axios({
        url: '/data/maintenance'
    }).then((resp => resp.data.body));

    let maintenanceMode = maintResponse.maintenance;
    window.maintEstimate = maintResponse.estimate ?? false;
    window.maintText = maintResponse.title;
    window.maintSubText = maintResponse.subtitle;

    //Configure Root Vue
    window.PTVue = new Vue({
        store,
        el: '#app',

        router: maintenanceMode ? undefined : router,
        i18n,
        vuetify,

        render: h => h(maintenanceMode ? MaintenancePane : PositiveTransition)
    });

    /**
     * Watch for out of date indicator from the API
     */
    window.PTVue.$on('outOfDate', () =>
    {
        store.commit('set_out_of_date', true);
    });

    window.PTVue.$on('forbidden', () =>
    {
        try
        {
            window.PTVue.$router.replace('/access-denied').catch(() =>
            {
                return false;
            });
        }
        catch
        {
            //noop
        }
    });

    /**
     * Watch for logged out indicator from the API
     */
    window.PTVue.$on('unauthorised', () =>
    {
        /**
         * We will check with the server if the user is actually logged in
         * Potentailly the same error code could be returned when the user is actaully logged in
         * e.g. the 'uinathorised' could mean that they just aren't allowed to access the particular resource
         */
        window.PTVue.$ptAjax({
            url: '/data/auth/login',
            type: 'get',
            silent: true,
            auth_check: false,
            success: {
                run: (r) =>
                {
                    if ('body' in r)
                    {
                        if ('state' in r.body)
                        {
                            if (!r.body.state)
                            {
                                //Clear user information
                                store.commit('set_logged_in', false);
                            }
                        }
                    }
                }
            }
        });
    });

//Init our error reporting
    //window.PTVue.$ptErrorReport();
})();

