<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <PConvertibleModal v-model="isOpen" :title="title" :max-width="maxWidth" no-toolbar force-desktop persistent>

        <template #activator="{on,attrs}">
            <slot name="activator" v-bind:on="on" v-bind:attrs="attrs"/>
        </template>

        <slot>

            {{ text }}

        </slot>

        <template #actions="{close}">
            <v-btn text :color="preferNegative ? primaryColour : secondaryColour" @click="$emit('no'); close()">{{ noButtonText }}</v-btn>
            <v-btn text :color="preferNegative ? secondaryColour : primaryColour" @click="$emit('yes'); close()">{{ yesButtonText }}</v-btn>
        </template>
    </PConvertibleModal>
</template>

<script>
export default {
    name: 'PConfirmModal',
    props: {
        text: {
            type: String
        },
        title: {
            type: String,
            default: 'Alert'
        },
        noButtonText: {
            type: String,
            default: 'No'
        },
        yesButtonText: {
            type: String,
            default: 'Yes'
        },
        preferNegative: {
            type: Boolean,
            default: false
        },
        primaryColour: {
            type: String,
            default: 'primary'
        },
        secondaryColour: {
            type: String,
            default: 'secondary'
        },
        maxWidth: {
            type: String,
            default: '400'
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasValueListener()
        {
            return this.$listeners && this.$listeners.input;
        },
        isOpen: {
            get()
            {
                return this.valueCache;
            },
            set(value)
            {
                if (this.hasValueListener)
                    this.$emit('input', value);
                else
                    this.valueCache = value;
            }
        }
    },
    data: () => ({
        valueCache: false
    }),
    mounted()
    {
        this.$watch(() => this.value, () => this.valueCache = this.value);
    }
};
</script>