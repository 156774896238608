<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <hr v-if="!this.mini" class="divider"/>
</template>

<script>
export default {
    name: 'SuperMenuDivider',
    props: {
        mini: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>

$content-margin: 15px;

.divider {
    border-top-width: 1px;
    border-top-color: var(--highlight-colour);
    margin: 1rem $content-margin;

    &.mini-divider {
        margin-top: 0;
    }
}
</style>