<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-img src="/assets/images/supermenu_image.svg" max-height="250" dark class="supermenu-profile-link">
        <div class="layout-container">
            <div class="button-container">
                <v-spacer/>
                <div v-if="flags.demo">
                    <v-tooltip bottom>
                        <template #activator="{on,attrs}">
                            <v-btn icon v-on="on" v-bind="attrs">
                                <v-icon>mdi-translate</v-icon>
                            </v-btn>
                        </template>
                        <span>Change Language</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template #activator="{on, attrs}">
                            <v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark" v-on="on" v-bind="attrs">
                                <v-icon>{{ $vuetify.theme.dark ? 'mdi-white-balance-sunny' : 'mdi-weather-night' }}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $vuetify.theme.dark ? 'Light Mode' : 'Dark Mode' }}</span>
                    </v-tooltip>
                </div>
                <portal-target name="supermenu-usermenu"/>
            </div>
            <div class="title-container">
                <v-list-item>
                    <v-list-item-avatar class="avatar" size="64" rounded v-ripple @click="navigateToProfile">
                        <v-img v-if="user.avatar !== null && user.avatar !== '/assets/icons/avatar.svg'" :src="user.avatar" alt="Your Avatar"/>
                        <v-icon size="85%">$avatar</v-icon>
                    </v-list-item-avatar>
                </v-list-item>

                <v-list-item two-line dark>
                    <v-list-item-content>
                        <v-list-item-title>{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
                        <v-list-item-subtitle>Member since <span v-html="user.join_date"></span></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </div>
    </v-img>
</template>

<script>

import { mapState } from 'vuex';

/**
 * A link to the profile page shown at the top of the SuperMenu.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'SuperMenuProfileLink',
    computed: {
        ...mapState([ 'user', 'flags' ]),

        /**
         * Compute the ARIA label used for screen-readers.
         *
         * @returns {string}
         */
        ariaLabel()
        {
            return `Profile shortcut. ${ this.user.first_name } ${ this.user.last_name }. Member since ${ this.user.join_date }`;
        }
    },
    methods: {
        navigateToProfile()
        {
            this.$router.push('/settings/profile/about-me');
        }
    }
};
</script>

<style lang="scss" scoped>
.button-container {
    display: flex;
}

.title-container {
    flex-basis: 0;
    flex-grow: 0;
}

.avatar {
    box-shadow: #00000073 0px 0px 13px 3px;
    border-radius: 100% !important;
}

.supermenu-profile-link {
    .layout-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 10px 15px 5px;
    }
}
</style>