/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default [
    {
        path: '/forum/:tabId?',
        name: 'Forum',
        component: () => import(/* webpackChunkName: "forum"*/ '@/views/Forum/HivebriteRedirect'),
        props: route => ({ tabId: !isNaN(Number(route.params.tabId)) ? Number(route.params.tabId) : -1 }),
        meta: {
            colour()
            {
                return '9a9da0';
            },
            icon()
            {
                return '$module-forum';
            }
        }
    }
];