/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import store from '@/store';
import { Utilities } from '@/router';

function loadRoutes()
{
    const context = require.context(__dirname, true, /routes.child.js$/i);
    return Utilities.ProcessRoutes(context);
}

export default [
    {
        name: 'Module',
        path: '/module/:moduleName/:tabId?',
        component: () => import(/* webpackChunkName: "module" */ '@/views/Module/ModuleMain'),
        props: route => ({ moduleName: route.params.moduleName, tabId: !isNaN(Number(route.params.tabId)) ? Number(route.params.tabId) : ((typeof route.params.tabId) === 'string' ? route.params.tabId : -1) }),
        meta: {
            colour(to)
            {
                return store.state.module_data.modules.find(x => x.url === to.params.moduleName).colour;
            },
            title(to)
            {
                return store.state.module_data.modules.find(x => x.url === to.params.moduleName).name;
            },
            icon(to)
            {
                return store.state.module_data.modules.find(x => x.url === to.params.moduleName).icon_class;
            }
        },
        children: [
            ...loadRoutes()
        ]
    }
];