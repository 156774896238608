<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-dialog v-model="isOpen" persistent max-width="300">
        <v-card class="wait-card">
            <div class="wait-container">
                <v-icon v-if="error" size="200px" color="red">mdi-alert-circle-outline</v-icon>
                <PLoadingSpinner v-else/>
                <br>
                <h1 class="rubik text-center">{{ text }}</h1>
                <p v-if="subtext !== null" class="rubik text-center mb-0">{{ subtext }}</p>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'PPleaseWait',
    props: {
        value: {
            type: Boolean
        },
        text: {
            type: String,
            default: 'Please wait...'
        },
        subtext: {
            type: String,
            default: null
        },
        error: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isOpen: {
            get()
            {
                return this.value;
            },
            set(value)
            {
                this.$emit('input', value);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.wait-card {

    padding: 25px;

    .wait-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}
</style>