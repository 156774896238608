<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <transition name="fade-loading-pane" :duration="transitionLength" mode="out-in" type="transition">
        <div id="loading-pane" v-if="isVisible">
            <div class="breathe breathe-top" :class="{active: !criticalError.state}" :style="calculate_colour_top"></div>
            <div class="breathe breathe-middle" :class="{active: !criticalError.state}" :style="calculate_colour_middle"></div>
            <div class="breathe breathe-bottom" :class="{active: !criticalError.state}" :style="{background: this.getBaseColour}"></div>
            <div class="pane-content d-flex flex-column">
                <PLogo class="mx-6"/>
                <div v-if="criticalError.state">
                    <v-divider class="mt-6 mb-6 white"/>
                    <h2 class="text-center rubik font-weight-bold white--text">{{ criticalError.title }}</h2>
                    <h2 class="text-center rubik r-light white--text">{{ criticalError.subtitle }}</h2>
                </div>
                <div v-else-if="tip !== false">
                    <v-divider v-if="tip !== false" class="mt-6 mb-6 white"/>
                    <h2 v-if="tip !== false" class="text-center rubik font-weight-bold white--text">{{ tipTitleText }}</h2>
                    <h2 v-if="tip !== false" class="text-center rubik r-light white--text">{{ tip }}</h2>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import { mapState } from 'vuex';
import { LightenDarkenColour } from '@plust/client-common/src/ColourMixer';

/**
 * Hides the loading of the app.
 *
 * TODO: link this with the window load event listener to ensure that no files are unloaded before we close
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 * @author T J Collinson <tom.collinson@plus-t.co.uk>
 */
export default {
    name: 'LoadingPane',
    props: {
        transitionLength: {
            type: Number,
            default: 100
        }
    },
    data()
    {
        return {
            /**
             * Failure state
             */
            failed: false,
            force: false,
            tip: false
        };
    },
    computed: {
        ...mapState([ 'highlight_colour', 'secure_initialisation_complete', 'initialisation_complete', 'criticalError' ]),

        tipTitleText()
        {
            let texts = [
                'Something to think about',
                'This might help',
                'Tip of the Day',
                'Did you know?',
                'A wise person once said:',
                'According to legend...'
            ];
            return texts[Math.floor(Math.random() * texts.length)];
        },

        isVisible()
        {
            if (this.criticalError.state)
                return true;
            if (this.force)
                return true;
            if (!this.$route.meta.insecure)
                return !this.initialisation_complete || !this.secure_initialisation_complete;
            return !this.initialisation_complete;
        },

        getBaseColour()
        {
            if (this.criticalError.state)
            {
                return '#ff0000';
            }
            return this.highlight_colour;
        },

        /**
         * Calculates the colour style of the middle breathing area
         *
         * @returns {string}
         */
        calculate_colour_middle()
        {
            return `background: ${ LightenDarkenColour(this.getBaseColour, -0.2) }`;
        },

        /**
         * Calculates the colour style of the top breathing area
         * @returns {string}
         */
        calculate_colour_top()
        {
            return `background: ${ LightenDarkenColour(this.getBaseColour, -0.4) }`;
        }
    },
    mounted()
    {
        this.getTip();
    },
    methods: {
        async getTip()
        {
            await this.$ptAjax({
                url: '/data/tips',
                type: 'get',
                silent: true,
                success: {
                    run: ({ body: { tip } }) =>
                    {
                        this.tip = tip;
                    }
                }
            });
        }
    },
    watch: {
        isVisible(value)
        {
            if (!value)
                this.$emit('closed');
            else
                this.$emit('open');
        }
    }
};
</script>

<style lang="scss" scoped>
@keyframes breathe-1 {
    0% {
        width: 400%;
        left: -150%;
        bottom: -350%
    }
    50% {
        width: 200%;
        left: -50%;
        bottom: -340%;
    }
    100% {
        width: 400%;
        left: -150%;
        bottom: -350%;
    }
}

@keyframes breathe-2 {
    0% {
        width: 225%;
        bottom: -220%;
    }
    50% {
        width: 150%;
        bottom: -210%;
    }
    100% {
        width: 225%;
        bottom: -220%;
    }
}

.breathe {
    position: fixed;

    &.breathe-top {
        width: 100%;
        height: 100%;
        top: 0;
    }

    &.breathe-bottom {
        bottom: -350%;
        left: -150%;
        width: 400%;
        height: 400%;
        border-radius: 50%;

        &.active {
            animation-name: breathe-1;
            animation-duration: 10s;
            animation-iteration-count: infinite;
        }
    }

    &.breathe-middle {
        width: 225%;
        height: 300%;
        bottom: -220%;
        border-radius: 50%;

        &.active {
            animation-name: breathe-2;
            animation-duration: 8s;
            animation-iteration-count: infinite;
        }
    }

}

.breathe.breathe-middle, .breathe.breathe-middle {
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, .05);
}

#loading-pane {

    $z: 500000;

    @include full-absolute();
    position: fixed;
    z-index: $z;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;

    .pane-content {
        z-index: $z + 10;
        width: 50vw;

        @media(max-width: 900px) {
            width: 75vw;
        }
    }

}

.fade-loading-pane-enter-active {
    transition: opacity 0.5s;
}

.fade-loading-pane-leave-active {
    transition: opacity 0.5s 1.5s;
}

.fade-loading-pane-enter, .fade-loading-pane-leave-to {
    opacity: 0 !important;
}
</style>