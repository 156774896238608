/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

/**
 * This Vuex module contains all of the data about the onboarding state.
 */

export default {
    namespaced: true,
    state: () => ({
        page: 0,
        ignore_page_load: false
    }),
    mutations: {
        setInitialState(state, data)
        {
            Object.assign(state, data);
        },
        set_page(state, newPage)
        {
            state.page = newPage;
        },
        set_ignore_next_page_load(state, value)
        {
            state.ignore_page_load = value;
        }
    },
    getters: {
        all: (state) => state
    }
};