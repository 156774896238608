/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default [
    {
        name: 'Quiz',
        path: 'quiz/:quizId',
        component: () => import( '@/views/Module/Quiz/QuizModal.vue'),
        props: (route) =>
        {
            return {
                quizId: route.params.quizId
            };
        }
    }
];