<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-navigation-drawer class="supermenu-container" width="320" fixed temporary v-model="isOpen">
        <template #prepend>
            <SuperMenuProfileLink/>
        </template>
        <PScrollView id="supermenu" role="navigation" :options="{overscrollEffect: 'bounce', damping: 0.1}" :plugins="plugins">
            <PFocusTrap :active="isOpen">
                <div v-if="hasTop">
                    <slot name="top"/>
                    <SuperMenuDivider/>
                </div>
                <br v-else>
                <SuperMenuSection v-for="(section, index) in items" :key="section.label" v-bind="section" :mini_divider="index === 0 || section.mini_divider" ref="sections"/>
                <div v-if="hasBottom">
                    <SuperMenuDivider/>
                    <slot name="bottom"/>
                </div>
            </PFocusTrap>
        </PScrollView>
    </v-navigation-drawer>
</template>

<script>
import SuperMenuSection from '@/components/Layout/Menus/SuperMenu/SuperMenuSection';
import SuperMenuProfileLink from '@/components/Layout/Menus/SuperMenu/SuperMenuProfileLink';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import { MenuCommonData } from '@/components/Layout/Menus/MenuCommon';
import SuperMenuDivider from '@/components/Layout/Menus/SuperMenu/SuperMenuDivider';

/**
 * The SuperMenu used to navigate big sections of the app.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'SuperMenu',
    components: { SuperMenuDivider, SuperMenuSection, SuperMenuProfileLink },
    mixins: [ MenuCommonData ],
    props: {
        /**
         * Should we allow the top widget area to be shown?
         */
        showTop: {
            type: Boolean,
            default: true
        },
        sidebar_active: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        plugins()
        {
            return [ OverscrollPlugin ];
        },

        /**
         * Is there content in the top slot?
         *
         * @returns {boolean}
         */
        hasTop()
        {
            if(!this.showTop)
                return false;
            return !!(this.$slots.top || this.$scopedSlots.top);
        },

        /**
         * Is there content in the bottom slot?
         *
         * @returns {boolean}
         */
        hasBottom()
        {
            return !!(this.$slots.bottom || this.$scopedSlots.bottom);
        }
    },
    data()
    {
        return {

            /**
             * Determines the loading state of the menu layout data. Is this even used?
             */
            isLoading: true,

            /**
             * Determines the failure state of the load operation for menu layout data. Is this even used?
             */
            failed: false,

            /**
             * Local isOpen model for the v-navigation-drawer means the v-model can be replicated up out of this component using events.
             */
            isOpen: false
        };
    },
    watch: {
        sidebar_active()
        {
            this.isOpen = this.sidebar_active;
        },
        isOpen()
        {
            this.$emit('changed', this.isOpen);
        }
    },
    mounted()
    {
        this.$ptAjax({
            url: '/data/menu/super',
            type: 'get',
            silent: {
                success: true,
                error: false
            },
            success: {
                run: (r) =>
                {
                    this.isLoading = false;
                    this.items = r.body.menu;
                }
            },
            error: {
                run: () =>
                {
                    this.isLoading = false;
                    this.failed = true;
                }
            }
        });

    }
};
</script>

<style lang="scss" scoped>

$supermenu-width: 320px;

$supermenu-z: 180;

.supermenu-container {

    z-index: 5000 !important;
    overflow: hidden;

    #supermenu {
        left: 0;
        height: 100%;
        width: 100%;
        z-index: $supermenu-z;
        overflow-x: hidden;
        overflow-y: hidden;

        @media(max-width: 400px) {
            padding-bottom: 60px;
        }
    }

    &::v-deep .v-navigation-drawer__content {
        overflow: hidden !important;
    }

}
</style>