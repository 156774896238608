<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="onboarding-dots">
        <div v-for="(active, index) in dots" :key="index" :class="{active: active}" class="tab-icon" tabindex="0" @click="dotClicked(index)">
            <div/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OnboardingDots',
    inject: [ 'manualTabSelect' ],
    props: {
        length: {
            type: Number,
            required: true
        },
        current: {
            type: Number,
            required: true
        }
    },
    computed: {
        dots()
        {
            let dots = [];
            for(let x = 0; x < this.length; x++)
            {
                dots.push(x === this.current);
            }
            return dots;
        }
    },
    methods: {
        dotClicked(index)
        {
            this.manualTabSelect(index);
        }
    }
};
</script>

<style lang="scss" scoped>

.onboarding-dots {
    display: flex;

    .tab-icon {
        border: 2px solid var(--highlight-colour);
        border-radius: 100%;
        margin-left: 5px;

        & > * {
            width: 5px;
            height: 5px;
            background-color: transparent;
            margin: 2px;
            border-radius: 100%
        }

        &.active {
            & > * {
                background-color: var(--highlight-colour);
            }
        }
    }
}
</style>