<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="onboarding-slide">
        <v-img :src="image" max-height="300" contain eager class="slide-img"/>
        <h1 class="rubik">{{ title }}</h1>
        <h4 class="rubik">{{ subheading }}</h4>
        <v-btn v-if="button !== null && button !== undefined" color="blue" dark size="lg" class="mt-2" @click="openTargetPage(button.link)">{{ button.text }}</v-btn>
    </div>
</template>

<script>
export default {
    name: 'OnboardingSlide',
    inject: [ 'setPushOnboarding' ],
    props: {
        title: {
            type: String
        },
        subheading: {
            type: String
        },
        image: {
            type: String
        },
        button: {
            type: Object,
            default: null
        }
    },
    methods: {
        openTargetPage(url)
        {
            if(this.$route.path !== url)
            {
                this.$router.push(url);
            }
            this.setPushOnboarding(true);
        }
    }
};
</script>

<style lang="scss" scoped>

.onboarding-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .slide-img {
        margin-bottom: 1rem;
        margin-top: 1rem;
        max-width: 100%;
        padding-left: 25px;
        padding-right: 25px;
    }
}

h1 {
    font-weight: 300;
    font-size: 32px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;

    @media(max-width: 500px) {
        font-size: 24px;
    }
}

h4 {
    font-size: 19px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;

    @media(max-width: 500px) {
        font-size: 15px;
    }
}
</style>