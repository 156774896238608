/*
 * Copyright © 2020-2023 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import runner from './runner';
import VimeoPlayer from './VimeoPlayer.vue';

export default {
    install(Vue)
    {
        runner.Vue = Vue;
        Vue.component('VimeoPlayer', VimeoPlayer);
    }
}

