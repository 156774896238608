/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        description: {
            type: String,
            default: null
        },
        flags: {
            type: Number,
            default: 0
        },
        displayOrder: {
            type: Number,
            required: true
        },
        questionNumber: {
            type: Number,
            default: null
        }
    }
};