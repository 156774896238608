<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div>
        <h2><span v-if="questionNumber !== null">{{ questionNumber }}. </span>{{ label }}</h2>
        <p class="text--secondary text-subtitle-2 font-weight-light mb-0">{{ description }}</p>
        <p v-if="max > 0" class="text--secondary mt-1 mb-0"><em>Pick at most {{ max }} item{{ max > 1 ? 's' : '' }}</em></p>
        <v-radio-group v-model="answers" class="mt-0" v-if="!multiple">
            <v-radio
                    v-for="question in orderDisplayQuestions(filterDisplayedQuestions(questions))"
                    :key="question.id"
                    :value="question.id"
                    :label="question.label"
                    :false-value="required ? 'NEGATIVE' : null"
            />
            <div v-if="optOut">
                <v-divider style="border-style: dashed" class="mt-3"/>
                <v-checkbox v-model="isNoneOfTheAbove" :label="$t('option.opt_out')" hide-details class="mt-2"/>
            </div>
        </v-radio-group>
        <template v-else>
            <v-checkbox
                    v-for="question in orderDisplayQuestions(filterDisplayedQuestions(questions))"
                    :key="question.id"
                    multiple
                    v-model="answers"
                    :value="question.id"
                    :label="question.label"
                    :disabled="allOfTheAbove || noneOfTheAbove || checkboxDisableBecauseLimitReached(question)"
                    hide-details class="mt-2"
            />
            <div v-if="optOut || allAllowed" class="mb-4">
                <v-divider style="border-style: dashed" class="mt-3"/>
                <v-checkbox v-if="optOut" v-model="isNoneOfTheAbove" :label="$t('option.opt_out')" hide-details class="mt-2"/>
                <v-checkbox v-if="allAllowed" v-model="isAllOfTheAbove" :label="$t('option.all_of_the_above')" hide-details class="mt-2"/>
            </div>
        </template>
    </div>
</template>

<script>

import QuestionCommon from '@/views/Questionnaire/QuestionViews/QuestionCommon.js';
import QuestionGroupFlags from '@/views/Questionnaire/QuestionGroupFlags.js';

export default {
    name: 'QuestionGroup',
    mixins: [ QuestionCommon ],
    inject: [ 'filterDisplayedQuestions' ],
    props: {
        questions: {
            type: Array,
            default: () => ([])
        },
        max: {
            type: Number,
            default: 0
        },
        value: {
            type: Array
        }
    },
    computed: {
        questionGroupFlags()
        {
            return QuestionGroupFlags;
        },
        isAllOfTheAbove: {
            get()
            {
                return this.allOfTheAbove;
            },
            set(value)
            {
                if (value)
                {
                    this.noneOfTheAbove = false;
                    this.answers = this.questions.map(x => x.id);
                }
                this.allOfTheAbove = value;
            }
        },
        isNoneOfTheAbove: {
            get()
            {
                return this.noneOfTheAbove;
            },
            set(value)
            {
                if (value)
                {
                    this.allOfTheAbove = false;
                    this.answers = [];
                }
                this.noneOfTheAbove = value;

            }
        },
        required()
        {
            return (this.flags & QuestionGroupFlags.REQUIRED) > 0;
        },
        multiple()
        {
            return (this.flags & QuestionGroupFlags.MULTIPLE) > 0;
        },
        optOut()
        {
            return (this.flags & QuestionGroupFlags.OPT_OUT) > 0;
        },
        allAllowed()
        {
            return (this.flags & QuestionGroupFlags.ALL_ALLOWED) > 0 && this.max === 0;
        },
        answers: {
            get()
            {
                if(!this.multiple)
                {
                    if(this.value.length === 0)
                        return null;
                    return this.value.find(x=>x.answer === "POSITIVE")?.id;
                }
                /**
                 * Create an array of IDS for the answered questions
                 * If ID is not present then should be be negative/unknown
                 */
                let answerData = [];
                for (let question of this.questions)
                {
                    let index = this.value.findIndex(x => x.id === question.id);
                    if (index >= 0 && this.value[index].answer !== 'NEGATIVE')
                        answerData.push(question.id);
                }
                return answerData;
            },
            set(value)
            {
                /**
                 * This handles standard radios
                 */
                if(!this.multiple)
                {
                    let answerData = [];
                    for(let question of this.questions)
                    {
                        if(!this.required && question.id !== value)
                            continue;
                        answerData.push({
                            id: question.id,
                            answer: question.id === value ? "POSITIVE" : "NEGATIVE",
                            meta: null
                        });
                    }
                    this.$emit('input', answerData);
                    return;
                }

                /**
                 * Emit answer objects for each answered question
                 * Multiple checkbox
                 */
                let answerData = [];

                for (let question of this.questions)
                {
                    let wasFound = false;
                    if ((wasFound = (value.findIndex(x => x === question.id) >= 0)) || this.required)
                    {
                        answerData.push({
                            id: question.id,
                            answer: wasFound ? 'POSITIVE' : (this.required ? 'NEGATIVE' : null),
                            meta: null
                        });
                    }
                }
                this.$emit('input', answerData);
            }
        }
    },
    data: () => ({
        noneOfTheAbove: false,
        allOfTheAbove: false,
        testData: []
    }),
    methods: {
        checkboxDisableBecauseLimitReached(question)
        {
            if (this.max === 0)
                return;
            if (this.answers.length === this.max)
                return this.answers.findIndex(x => x === question.id) < 0;
            return false;
        },
        orderDisplayQuestions(questions)
        {
            return questions.sort((a, b) => a.displayOrder - b.displayOrder);
        }
    }
};
</script>

<style scoped>

</style>

<i18n locale="en">
{
    "option": {
        "opt_out": "None of the above",
        "all_of_the_above": "All of the above"
    }
}
</i18n>