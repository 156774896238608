/*
 * Copyright © 2020-2022 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 *
 * @purloined from one of @ThomasCollinson's other porjects
 */

export default {
    computed: {
        isMobile(){
            return this.$vuetify.breakpoint.width <= this.$vuetify.breakpoint.thresholds.xs;
        }
    }
};
