<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-app>
        <v-main>
            <div class="background">
                <template v-if="$vuetify.breakpoint.mobile">
                    <portal-target style="width: 100%;" name="logo"/>
                    <portal-target style="width: 100%; margin-bottom: 25px;" name="timer"/>
                </template>
                <h1 style="" class="rubik">{{ fullText }}</h1>
                <h3>{{ fullSubText }}</h3>
                <template v-if="!$vuetify.breakpoint.mobile">
                    <portal-target name="timer" style="width: 100%; max-width: 700px;"/>
                    <portal-target name="logo" style="width: 100%; max-width: 500px;"/>
                </template>
                <div class="social-container">
                    <v-btn v-for="social in socials" :key="social.label" icon :href="social.link" target="_blank">
                        <v-icon color="white" x-large>{{ social.icon }}</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-main>
        <portal to="logo" slim>
            <PLogo class="logo-icon"/>
        </portal>
        <portal to="timer" slim>
            <div v-if="showTimer" class="timer">
                <div v-for="item in timer" :key="item.label" class="timer-part">
                    <h1 class="timer-value">{{ (10 > item.value ? 0 : '') }}{{ item.value }}</h1>
                    <h4 class="timer-label">{{ item.label }}</h4>
                </div>
            </div>
        </portal>
    </v-app>
</template>

<script>
import { DateTime } from 'luxon';
import Axios from 'axios';

export default {
    name: 'MaintenancePane',
    data()
    {
        return {
            targetTime: false,
            fullText: window.maintText,
            fullSubText: window.maintSubText,
            showTimer: false,
            timerHandle: null,
            checkTimerHandle: null,
            timer: {
                hours: {
                    label: 'Hours',
                    value: 0
                },
                minutes: {
                    label: 'Minutes',
                    value: 0
                },
                seconds: {
                    label: 'Seconds',
                    value: 0
                }
            },
            socials: [
                {
                    label: 'Facebook',
                    icon: 'mdi-facebook',
                    link: 'https://www.facebook.com/PositiveTransition'
                },
                {
                    label: 'Twitter',
                    icon: 'mdi-twitter',
                    link: 'https://twitter.com/Plus_Transition'
                },
                {
                    label: 'Instagram',
                    icon: 'mdi-instagram',
                    link: 'https://www.instagram.com/positive_transition'
                }
            ]
        };
    },
    methods: {
        updateTimer()
        {
            this.showTimer = true;
            let dateDiff = this.targetTime.diff(DateTime.utc(), [ 'hours', 'minutes', 'seconds' ]).toObject();
            this.timer = {
                hours: {
                    label: 'Hours',
                    value: dateDiff.hours.toFixed(0)
                },
                minutes: {
                    label: 'Minutes',
                    value: dateDiff.minutes.toFixed(0)
                },
                seconds: {
                    label: 'Seconds',
                    value: dateDiff.seconds.toFixed(0)
                }
            };
        },
        async checkWindowEnded()
        {
            let maintResponse = await Axios({
                url: '/data/maintenance'
            }).then((resp => resp.data.body));

            if (!maintResponse.maintenance)
                window.location.reload();

            this.fullText = maintResponse.title;
            this.fullSubText = maintResponse.subtitle;
            this.targetTime = DateTime.fromSeconds(window.maintEstimate);
        }
    },
    mounted()
    {
        if (this.maintEstimate === false)
            return;
        this.targetTime = DateTime.fromSeconds(window.maintEstimate);
        this.$nextTick(() =>
        {
            clearInterval(this.timerHandle);
            this.timerHandle = setInterval(() => this.updateTimer(), 1000);
            clearInterval(this.checkTimerHandle);
            this.checkTimerHandle = setInterval(() => this.checkWindowEnded(), 1000 * 60);
        });
    }
};
</script>

<style lang="scss" scoped>
.background {
    background-image: url(../../../../public/assets/images/maintenance_background.jpg);
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    & > h1 {
        font-size: 3.5em;
        text-align: center;
        line-height: 1.2;

        @media(max-width: 600px) {
            font-size: 2em;
        }
    }

    & > h3 {
        font-size: 2em;
        text-align: center;

        @media(max-width: 600px) {
            font-size: 1.5em;
        }
    }

    .timer {
        display: flex;
        width: 100%;
        justify-content: space-around;
        max-width: 700px;

        .timer-part {
            display: flex;
            flex-direction: column;
            margin-top: 50px;
            text-align: center;

            .timer-value {
                font-size: 10em;
                margin-bottom: 0;
                line-height: 1;

                @media(max-width: 600px) {
                    font-size: 5em;
                }
            }

            .timer-label {
                font-size: 2em;
                margin-top: 0;

                @media(max-width: 600px) {
                    font-size: 1.2em;
                }
            }
        }
    }

    .social-container {
        display: flex;
        margin-top: 50px;
        justify-content: space-around;
        max-width: 200px;
        width: 100%;
    }

    .logo-icon {
        margin-top: 50px;
        min-width: unset;
        max-width: 500px;
        padding-left: 50px;
        padding-right: 50px;
    }
}
</style>