<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <PConvertibleModal v-model="isOpen" :title="title" max-width="400" no-toolbar force-desktop>

        <slot>

            {{ text }}

        </slot>

        <template #actions="{close}">
            <v-btn text color="primary" @click="close">{{ okButtonText }}</v-btn>
        </template>
    </PConvertibleModal>
</template>

<script>
export default {
    name: 'PAlertModal',
    props: {
        text: {
            type: String
        },
        title: {
            type: String,
            default: 'Alert'
        },
        okButtonText: {
            type: String,
            default: 'OK'
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isOpen: {
            get()
            {
                return this.value;
            },
            set(value)
            {
                this.$emit('input', value);
            }
        }
    }
};
</script>