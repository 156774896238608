/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import PIcon from '@/components/PIcon';
import IconList from '@/icons.js';

Vue.use(Vuetify);

function GenerateIconObject()
{
    let obj = {};
    for(let x in IconList)
    {
        obj[x] = {
            component: PIcon,
            props: {
                icon: x
            }
        };
    }
    return obj;
}

export default new Vuetify({
    theme: { dark: false },
    icons: {
        values: {
            ...GenerateIconObject()
        }
    }
});
