/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import PtAjax from '@plust/client-common/src/Ajax';
import { createCategory } from '@plust/client-common/src/PTLogger';

const PTLogger = createCategory('Vuex|Progression');

/**
 * This Vuex module contains all of the user progression.
 */
export default {
    namespaced: true,
    state: () => ({ modules: [], moduleOrder: [] }),
    mutations: {
        setInitialState(state, data)
        {
            state.modules = data;
            PTLogger.debug('Successfully saved initial progression state from server.');
            state.moduleOrder = JSON.parse(window.localStorage.getItem('module-order') ?? '[]');
        },
        clearInitialState(state)
        {
            state.modules = [];
        },
        setModuleOrder(state, data)
        {
            state.moduleOrder = data;
            PTLogger.debug('Updating module tile order: ', data);
            window.localStorage.setItem('module-order', JSON.stringify(data));
        }
    },
    actions: {
        async loadInitialState({ commit })
        {
            PTLogger.debug('Loading initial state...');
            await PtAjax({
                url: '/data/module/tracked',
                type: 'get',
                silent: true,
                success: {
                    run: ({ body: { modules } }) =>
                    {
                        commit('setInitialState', modules);
                    }
                },
                error: {
                    run: (data) =>
                    {
                        PTLogger.error('Failed to get initial state.', data);
                        throw data;
                    }
                }
            });
        },

        async clearSecureState({ commit })
        {
            commit('clearInitialState');
        }
    },
    getters: {
        all: (state) => state
    }
};