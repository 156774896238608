/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import Vue from 'vue';
import Vuex from 'vuex';
import { createCategory } from '@plust/client-common/src/PTLogger';
import CalendarModule from './modules/calendar';
import ConstantsModule from './modules/constants';
import CodesModule from './modules/codes';
import ProgressionModule from './modules/progression';
import FlagsModule from './modules/flags';
import UserModule from './modules/user';
import OnboardingModule from './modules/onboarding';
import ModuleDataModule from './modules/module_data';
import FeedbackModule from './modules/feedback';
import QuestionnaireModule from './modules/questionnaire';

const PTLogger = createCategory('Vuex');

Vue.use(Vuex);

const VueStore = new Vuex.Store({
    modules: {
        calendar: CalendarModule,
        constants: ConstantsModule,
        codes: CodesModule,
        progression: ProgressionModule,
        flags: FlagsModule,
        user: UserModule,
        onboarding: OnboardingModule,
        module_data: ModuleDataModule,
        feedback: FeedbackModule,
        questionnaire: QuestionnaireModule
    },
    state: {
        highlight_colour: '#54ad3a',
        default_colour: '#54ad3a',

        criticalError: {
            state: false,
            title: "",
            subtitle: ""
        },

        onboarding_page: 0,
        onboarding_ignore_page_load: false,
        initialisation_complete: false,
        secure_initialisation_complete: false,
        force_loading_pane: false,
        out_of_date: false,
        /**
         * Min and max year for the transition date
         */
        transition_date_window: {
            past: 30,
            future: 30
        },
        /**
         * Current logged in status
         * Default to true as we'll get the real status from the server
         */
        logged_in: true
    },
    mutations: {
        set_highlight_colour(state, newColour)
        {
            state.highlight_colour = newColour;
        },
        set_initialisation_state(state, newState)
        {
            state.initialisation_complete = newState;
        },
        set_secure_initialisation_state(state, newState)
        {
            state.secure_initialisation_complete = newState;
        },
        set_force_loading_pane(state, newState)
        {
            state.force_loading_pane = newState;
        },
        set_out_of_date(state, newState)
        {
            if (process.env.PT_NO_UPDATE)
                return;
            state.out_of_date = newState;
        },
        set_logged_in(state, newState)
        {
            state.logged_in = newState;
        },
        setCriticalError(state, { title, subtitle })
        {
            state.criticalError.state = true;
            state.criticalError.title = title;
            state.criticalError.subtitle = subtitle;
        }
    },
    actions: {
        async initialise({ commit, dispatch, state })
        {
            if (state.initialisation_complete)
                return;
            let moduleCommands = [
                'codes/loadInitialState',
                'constants/loadInitialState',
                'flags/loadInitialState',
                'module_data/loadInitialState',
                'feedback/loadInitialState'
            ];
            try
            {
                let promises = [];
                for (let x of moduleCommands)
                    promises.push(dispatch(x));
                await Promise.all(promises);
                commit('set_initialisation_state', true);
            }
            catch (e)
            {
                PTLogger.error('Failure to load basic state from server', e);
                throw e;
            }
        },
        async initialiseSecure({ commit, dispatch, state })
        {
            if (state.secure_initialisation_complete)
                return;

            let moduleCommands = [
                'progression/loadInitialState',
                'user/loadInitialState',
                'calendar/loadInitialState',
                'questionnaire/loadInitialState'
            ];
            try
            {
                let promises = [];
                for (let x of moduleCommands)
                    promises.push(dispatch(x));
                await Promise.all(promises);
                commit('set_secure_initialisation_state', true);
            }
            catch (e)
            {
                PTLogger.error('Failure to get secure state.', e);
                commit('set_secure_initialisation_state', false);
                throw e;
            }
        },
        async clearSecureStorage({ commit, dispatch })
        {
            let modules = [
                'progression',
                'user',
                'calendar'
            ];
            try
            {
                let promises = [];
                for (let x of modules)
                    promises.push(dispatch(x + '/clearSecureState'));
                await Promise.all(promises);
                commit('set_secure_initialisation_state', false);
            }
            catch (e)
            {
                PTLogger.error('Failure to clear secure state.', e);
                throw e;
            }
        }
    }
});

VueStore.watch(function (state)
{
    return state.highlight_colour;
}, (value) =>
{
    document.body.style.setProperty('--highlight-colour', value);
});

//TODO: we don't want to do this long term. This is usually a bad idea... I think? But it allows external JS to manipulate global state for now.
window.PTVueStore = VueStore;

export default VueStore;