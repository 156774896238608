<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="supermenu-section">
        <SuperMenuDivider :mini="mini_divider"/>
        <h2 class="heading">{{ label }}</h2>
        <v-list class="mt-0 pt-0">
            <SuperMenuItem v-for="item in items" :key="item.label" v-bind="item" ref="menu_items"/>
        </v-list>
    </div>
</template>

<script>
import SuperMenuItem from '@/components/Layout/Menus/SuperMenu/SuperMenuItem';
import MenuSectionCommon from '@/components/Layout/Menus/MenuSectionCommon';
import SuperMenuDivider from '@/components/Layout/Menus/SuperMenu/SuperMenuDivider';

/**
 * A section in the SuperMenu that contains related entries.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'SuperMenuSection',
    components: { SuperMenuDivider, SuperMenuItem },
    mixins: [ MenuSectionCommon ],
    props: {

        /**
         * Force the divider not to have an upper margin.
         */
        mini_divider: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>

$content-margin: 15px;

.supermenu-section {

    .heading {
        margin-left: $content-margin;
        font-weight: 400;
        font-size: 1.5rem;
        margin-bottom: .5rem;
        @include rubik;
    }

    .items {
        margin: 0;
        padding: 0;
        position: relative;
        list-style: none;

        &:after {
            content: " ";
            pointer-events: none;
            @include full-absolute();
        }
    }
}
</style>