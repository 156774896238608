/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import PtAjax from '@plust/client-common/src/Ajax';
import { createCategory } from '@plust/client-common/src/PTLogger';

const PTLogger = createCategory('Vuex|ModuleData');

/**
 * This Vuex module contains all of the module basic information.
 */
export default {
    namespaced: true,
    state: () => ({ modules: [] }),
    mutations: {
        setInitialState(state, data)
        {
            state.modules = data;
            PTLogger.debug('Successfully saved module data from server.', data);
        }
    },
    actions: {
        async loadInitialState({ commit })
        {
            PTLogger.debug('Loading initial state...');
            await PtAjax({
                url: '/data/module',
                type: 'get',
                silent: { success: true },
                success: {
                    run: ({ body: { modules } }) =>
                    {
                        commit('setInitialState', modules);
                    }
                }
            });
        }
    },
    getters: {
        all: (state) => state,
        modules: (state) => state.modules
    }
};