<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-app-bar id="main-navigation" :color="highlight_colour" fixed app dark clipped-left v-resize="onResize">

        <v-app-bar-nav-icon @click="openSidebar" ref="sidebarButton"/>

        <div class="logo desktop">
            <PLogo/>
        </div>
        <div class="logo mobile">
            <div style="background-color: white; width: 40px; height: 40px; border-radius: 50%; display: flex; justify-content: center; align-items: center;">
                <v-icon color="var(--highlight-colour)">{{ pageIcon }}</v-icon>
            </div>
        </div>
        <div ref="skipNavContainer" @focusin="skipNavigationGotFocus" class="ml-8" tabindex="0">
            <v-btn v-if="showSkipNavigation" ref="skipNavButton" @focusout="skipNavigationLostFocus" text @click="$emit('skip-navigation')">Skip Navigation</v-btn>
        </div>
        <v-spacer/>
        <div class="rubik page-title">{{ pageTitle }}</div>
        <v-spacer/>
        <div v-show="!isMobile" class="menu-container">
            <QuestionnaireNavbarWidget v-if="showQuestionnaireButton"/>
            <v-badge class="calendar-button" overlap bottom :content="calendarEventCount" :value="calendarEventCount > 0" offset-y="16" offset-x="16">
                <v-btn icon to="/calendar" tabindex="0">
                    <v-icon>$calendar</v-icon>
                </v-btn>
            </v-badge>
            <UserMenu/>
            <DemoMenu v-if="flags.demo"/>
        </div>
    </v-app-bar>
</template>

<script>
import UserMenu from '@/components/Layout/Menus/UserMenu/UserMenu';
import DemoMenu from '@/components/Layout/Menus/Demo/DemoMenu';
import { mapGetters, mapState } from 'vuex';
import QuestionnaireNavbarWidget from '@/views/Questionnaire/QuestionnaireNavbarWidget';

/**
 * The main navigation bar at the top of the page.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'NavigationBar',
    components: { QuestionnaireNavbarWidget, UserMenu, DemoMenu },
    computed: {
        ...mapState([ 'flags', 'highlight_colour' ]),
        ...mapGetters('calendar', [ 'currentEvents' ]),

        showQuestionnaireButton()
        {
            return this.$store.state.questionnaire.hasContent ?? false;
        },

        calendarEventCount()
        {
            return this.currentEvents?.length ?? 0;
        },

        pageTitle()
        {
            return this.$route.meta.title !== undefined ? this.$route.meta.title(this.$route) : this.$route.name;
        },

        pageIcon()
        {
            return this.$route.meta.icon !== undefined ? this.$route.meta.icon(this.$route) : 'plust-icon';
        }
    },
    data()
    {
        return {
            isMobile: false,
            showSkipNavigation: false
        };
    },
    methods: {
        /**
         * Opens the SuperMenu.
         */
        openSidebar()
        {
            this.$emit('opennav');
        },

        onResize()
        {
            this.isMobile = window.innerWidth < 960;
        },

        skipNavigationGotFocus()
        {
            this.showSkipNavigation = true;
            this.$nextTick(() =>
            {
                this.$refs.skipNavButton.$el.focus();
            });
        },
        skipNavigationLostFocus(evt)
        {
            if (evt.relatedTarget === this.$refs.skipNavContainer)
            {
                this.$refs.sidebarButton.$el.focus();
            }
            this.showSkipNavigation = false;
        }
    }
};
</script>
<style lang="scss" scoped>

#main-navigation {
    $nav-height: 60px;
    $nav-half-height: ($nav-height / 2);
    $hamburger-height: 14px;
    $hamburger-half-height: ($hamburger-height / 2);
    $hamburger-width: 24px;
    $hamburger-half-width: ($hamburger-width / 2);

    min-height: $nav-height !important;
    z-index: 107;

    .v-app-bar__nav-icon {
        color: white;
        margin-left: 0px;
        margin-right: 10px
    }

    .page-title {
        margin: 0 auto;
        color: white;
        font-size: 1.8rem;
        position: absolute;
        width: 500px;
        left: calc(50vw - 250px);
        text-align: center;
        @media(max-width: 992px) {
            display: none;
        }
    }

    .logo {
        $icon-width: 40px;
        $icon-half-width: 20px;
        width: 100px;
        fill: white;

        &.desktop::v-deep .p-logo {
            width: 120px;
        }

        @media(min-width: 991px) {
            &.desktop {
                display: block;
                margin-left: 5px;
            }
            &.mobile {
                display: none;
            }
        }

        @media(max-width: 991px) {
            &.desktop {
                display: none;
            }
            &.mobile {
                display: block;
                padding: 0;
                height: $icon-width;
                width: $icon-width;
                position: absolute;
                left: calc(50vw - #{$icon-half-width});
                top: $nav-half-height - $icon-half-width;
            }
        }
    }

    .menu-container {
        display: flex;
        align-items: center;

        .calendar-button {
            margin-right: 15px;
        }
    }

}
</style>