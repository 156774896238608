<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <router-link v-if="isVisible" class="quickbar-item" :style="cssVariables" :to="href" :aria-label="label" tabindex="0" @click.native="handleClick" v-ripple>
        <div class="icon-container">
            <v-icon size="30px" class="item-icon" color="white">{{ icon }}</v-icon>
        </div>
        <div class="text-container rubik">{{ label }}</div>
    </router-link>
</template>

<script>/**
 * Describes an item on the Quickbar.
 *
 * TODO: give this item a unique ID for the unit tests to pick out
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
import MenuItemCommon from '@/components/Layout/Menus/MenuItemCommon';

export default {
    name: 'QuickbarItem',
    mixins: [ MenuItemCommon ],
    props: {

        /**
         * The background colour that the item should be when hovered
         */
        hover_colour: {
            type: String,
            default: '9a9da0'
        }

    },
    computed: {

        /**
         * Compute the CSS variables for the item.
         *
         * @returns {Object}
         */
        cssVariables()
        {
            return { '--hover-colour': this.hover_colour, '--idle-colour': this.idle_colour };
        }
    },
    methods: {

        /**
         * Fired when the item is clicked.
         *
         * @param evt
         */
        handleClick(evt)
        {
            if (typeof evt === 'undefined')
                return;

            let a = null;

            let path = evt.composedPath() ?? [];

            //Get the anchor element
            for (let i = 0, j = path.length; i < j; i++)
                if (path[i].classList.contains('quickbar-item'))
                {
                    a = path[i];
                    break;
                }

            //If anchor not found, exit
            if (a == null)
                return;

            //Blur the button
            setTimeout(() =>
            {
                a.blur();
            }, 100);

        }
    }
};
</script>

<style lang="scss" scoped>
.quickbar-item {
    width: 80px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.25s width, 0.25s background-color;
    background: var(--idle-colour);
    overflow-x: visible;
    box-sizing: border-box;
    height: 60px;
    z-index: 300;

    .text-container {
        display: none;
        font-size: 1.2rem;
        width: 100%;
        text-align: center;
        overflow: hidden;
        color: white;
        white-space: nowrap;
    }

    &:hover, &:focus {
        width: 250px;
        /*box-shadow: rgba(0, 0, 0, 0.4) 5px 15px 20px 2px;*/
        text-decoration: none !important;
        color: black !important;
        background-color: var(--hover-colour);
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;

        .icon-container {
            background-color: white;

            .item-icon::v-deep path {
                fill: var(--hover-colour);
            }
        }

        .text-container {
            display: unset;
            text-decoration: none;
            color: white;
        }
    }

    &.router-link-active .icon-container {
        position: relative;

        &:before {
            content: "";
            background: transparent;
            display: block;
            position: absolute;
            border: 2px solid white;
            border-radius: 50%;
            top: 2px;
            left: 2px;
            width: 46px;
            height: 46px;
        }
    }

    .icon-container {
        line-height: 1;
        border-radius: 50%;
        $size: 50px;
        width: $size;
        min-width: $size;
        height: $size;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--hover-colour);
    }
}
</style>

<style lang="scss">
.theme--dark {
    .quickbar-item {
        &.router-link-active .icon-container {
            &:before {
                border-color: #303031;
            }
        }
    }
}
</style>