/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import runner from './runner';
import Player from './Player';

export default {
    install(Vue)
    {
        runner.Vue = Vue;
        Vue.component('p-youtube', Player);
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        tag.async = true;
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = function ()
        {
            runner.YT = window.YT;
            const { PlayerState } = window.YT;

            runner.events[PlayerState.ENDED] = 'ended';
            runner.events[PlayerState.PLAYING] = 'playing';
            runner.events[PlayerState.PAUSED] = 'paused';
            runner.events[PlayerState.BUFFERING] = 'buffering';
            runner.events[PlayerState.CUED] = 'cued';

            runner.Vue.nextTick(() =>
            {
                runner.run();
            });
        };
    }
};
