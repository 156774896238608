<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div>
        <h2><span v-if="questionNumber !== null">{{ questionNumber }}. </span>{{ label }}</h2>
        <p class="text--secondary text-subtitle-2 font-weight-light mb-0">{{ description }}</p>
        <v-radio-group v-model="answer" class="mt-0" v-if="this.range === null && !this.isNumeric">
            <v-radio :value="responseTypes.Positive" :label="yesLabel"/>
            <v-radio :value="responseTypes.Negative" :label="noLabel"/>
            <v-radio :value="responseTypes.Unknown" :label="unknownLabel"/>
        </v-radio-group>
        <v-slider
                v-else
                v-model="meta"
                step="1"
                class="slider mt-10 mx-md-16"
                :min="safeRange.min"
                :max="safeRange.max"
                ticks="always"
                tick-size="6"
                thumb-label="always"
        />
    </div>
</template>

<script>
import QuestionCommon from '@/views/Questionnaire/QuestionViews/QuestionCommon.js';
import QuestionResponseTypes from '@/views/Questionnaire/QuestionResponseTypes';
import QuestionFlags from '@/views/Questionnaire/QuestionFlags';

export default {
    name: 'BasicQuestion',
    mixins: [ QuestionCommon ],
    props: {
        dependencies: {
            type: Array,
            default: () => ([])
        },
        optionLabels: {
            type: Object,
            default: () => ({ yes: null, no: null, unknown: null })
        },
        range: {
            type: Object,
            default: null
        },
        value: {
            type: Object
        }
    },
    computed: {
        responseTypes()
        {
            return QuestionResponseTypes;
        },
        isNumeric()
        {
            return (this.flags & QuestionFlags.NUMERIC) > 0;
        },
        yesLabel()
        {
            return this.optionLabels.yes ?? 'Yes';
        },
        noLabel()
        {
            return this.optionLabels.no ?? 'No';
        },
        unknownLabel()
        {
            return this.optionLabels.unknown ?? 'Prefer not to say/do not know';
        },
        safeRange()
        {
            return { min: this.range?.min ?? 0, max: this.range?.max ?? 0 };
        },
        answer: {
            get()
            {
                return this.value?.answer ?? null;
            },
            set(value)
            {
                this.$emit('input', { id: this.id, answer: value, meta: this.meta });
            }
        },
        meta: {
            get()
            {
                return this.value?.meta ?? null;
            },
            set(value)
            {
                this.$emit('input', { id: this.id, answer: this.answer, meta: value });
                this.$nextTick(() =>
                {
                    this.answer = (this.meta >= (((this.safeRange.max - this.safeRange.min) / 2))) ? 'POSITIVE' : 'NEGATIVE';
                });
            }
        }
    },
    data: () => ({})
};
</script>

<style lang="scss" scoped>
.slider::v-deep .v-slider__tick {
    border-radius: 50%;
}
</style>