<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <!-- open-on-focus is toggled here as it keeps the modal permanently open if it remains on when opened using tabindex -->
    <v-dialog ref="dialog" v-model="modal" :return-value.sync="tempValue" persistent width="290px" :disabled="readonly" :open-on-focus="!modal">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="renderValue" prepend-icon="mdi-calendar" :label="label" readonly v-bind="attrs" v-on="on"/>
        </template>
        <v-date-picker v-model="tempValue" scrollable :first-day-of-week="1" :min="minDate" :max="maxDate">
            <v-spacer/>
            <v-btn text color="primary" @click="modal = false">
                Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(tempValue); save()">
                OK
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>

import { DateTime } from 'luxon';

export default {
    name: 'PDateInput',
    props: {
        type: {
            type: String,
            default: 'date'
        },
        label: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        value: {},
        minDate: {
            type: String,
            default: undefined
        },
        maxDate: {
            type: String,
            default: undefined
        }
    },
    computed: {
        renderValue()
        {
            if(this.tempValue === null || this.tempValue === undefined)
            {
                return 'Date Not Set';
            }
            return DateTime.fromSQL(this.tempValue, { locale: 'en-GB' }).toLocaleString(DateTime.DATE_FULL);
        }
    },
    data()
    {
        return {
            modal: false,
            tempValue: null
        };
    },
    mounted()
    {
        this.tempValue = this.value;
    },
    watch: {
        value()
        {
            this.tempValue = this.value;
        }
    },
    methods: {
        save()
        {
            this.$emit('input', this.tempValue);
        }
    }
};
</script>