import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=49772215&scoped=true"
import script from "./NotFound.vue?vue&type=script&lang=js"
export * from "./NotFound.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49772215",
  null
  
)

export default component.exports