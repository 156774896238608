/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import { ScrollbarPlugin } from 'smooth-scrollbar';

export default class IdiotWuHorizontalScroll extends ScrollbarPlugin {

    static pluginName = 'IdiotWuHorizontalScroll';

    static defaultOptions = {
        enabled: false
    };

    transformDelta(delta, _evt)
    {
        if(!this.options.enabled)
            return delta;
        if(!/wheel/.test(_evt.type))
            return delta;
        const { x, y } = delta;
        return {
            y: 0,
            x: Math.abs(x) > Math.abs(y) ? x : y
        };
    }

}