<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-navigation-drawer class="quickbar-outer-wrapper" width="80px" :mobile-breakpoint="768" app clipped touchless>
        <div class="quickbar-inner-wrapper">
            <PScrollView class="quickbar" ref="container" v-pt-hover="hover" :options="{overscrollEffect: 'bounce', damping: 0.1}" :plugins="plugins">
                <quickbar-section v-for="(section, index) in items" :key="section.label" v-bind="section" :hide_divider="index === 0 || section.hide_divider"/>
            </PScrollView>
        </div>
    </v-navigation-drawer>
</template>

<script>
import QuickbarSection from '@/components/Layout/Menus/Quickbar/QuickbarSection';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import { MenuCommonData } from '@/components/Layout/Menus/MenuCommon';
import { createCategory } from '@plust/client-common/src/PTLogger';

const PTLogger = createCategory('QuickBar');

/**
 * Describes the quickbar on the left hand side of the screen.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'QuickBar',
    components: { QuickbarSection },
    mixins: [ MenuCommonData ],
    computed: {
        plugins()
        {
            return [ OverscrollPlugin ];
        }
    },
    data()
    {
        return {
            isLoading: true,
            failed: false,
            hover: false
        };
    },
    created()
    {
        this.$router.afterEach(() =>
        {
            this.reload();
        });
    },
    mounted()
    {
        this.reload();
    },
    methods: {
        reload()
        {
            PTLogger.debug('Reloading quickbar content...');
            this.isLoading = true;
            this.failed = false;
            this.$ptAjax({
                url: '/data/menu/quick',
                type: 'get',
                silent: true,
                error: {
                    run: () =>
                    {
                        PTLogger.error('Quickbar reload failed.');
                        this.isLoading = false;
                        this.failed = true;
                    }
                },
                success: {
                    run: ({ body }) =>
                    {
                        PTLogger.debug('Quickbar content reload successful.');
                        this.isLoading = false;
                        this.items = body.menu;
                    }
                }
            });
        }
    }
};

</script>

<style lang="scss" scoped>

/* How and why. */

.quickbar-outer-wrapper {
    width: 80px;
    z-index: 99 !important;
    overflow: visible;
    box-shadow: rgba(0, 0, 0, 0.4) 1px 0 20px 2px;

    .quickbar-inner-wrapper {
        overflow-y: auto;

        .quickbar {
            position: fixed;
            left: 0;
            bottom: 0;
            top: 0;
            width: 80px;
            overflow: visible !important;

            &::v-deep .scroll-content {
                position: relative;
                z-index: 30;
            }
        }

    }

    &::v-deep .v-navigation-drawer__border {
        display: none;
    }

}
</style>