<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-list-item link v-bind="binds" @click="handleClick" dense>

        <v-list-item-title>
            {{ label }}
        </v-list-item-title>

        <v-list-item-icon>
            <v-icon :color="icon_colour">{{ icon }}</v-icon>
        </v-list-item-icon>

    </v-list-item>
</template>

<script>
import MenuItemCommon from '@/components/Layout/Menus/MenuItemCommon';

export default {
    name: 'PDropdownMenuItem',
    mixins: [ MenuItemCommon ],
    computed: {
        binds()
        {
            if (this.router)
            {
                return { 'to': this.href };
            }
            return { 'href': this.href };
        }
    }
};
</script>

<style scoped>

</style>