import { render, staticRenderFns } from "./QuestionnaireIntro.vue?vue&type=template&id=7d64e860&scoped=true"
import script from "./QuestionnaireIntro.vue?vue&type=script&lang=js"
export * from "./QuestionnaireIntro.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d64e860",
  null
  
)

export default component.exports