<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="p-logo" :style="cssVariables">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.17 19.350001">
            <path
                    d="m 21.7,9.07 h 4.28 c 0.06,0 0.110001,-0.05 0.110001,-0.11 V 4.61 c 0,-0.06 -0.05,-0.11 -0.110001,-0.11 H 21.7 C 21.64,4.5 21.590001,4.45 21.590001,4.39 V 0.11 C 21.590001,0.05 21.540001,0 21.48,0 h -4.35 c -0.06,0 -0.11,0.05 -0.11,0.11 v 4.28 c 0,0.06 -0.05,0.11 -0.11,0.11 h -4.28 c -0.06,0 -0.11,0.05 -0.11,0.11 v 3.07 c 0,0.06 -0.05,0.11 -0.11,0.11 H 9.51 C 9.27,7.79 9.08,7.6 9.08,7.36 V 3.39 C 9.08,3.33 9.03,3.28 8.97,3.28 H 4.62 C 4.56,3.28 4.51,3.33 4.51,3.39 V 7.36 C 4.51,7.6 4.32,7.79 4.08,7.79 H 0.11 C 0.05,7.79 0,7.84 0,7.9 v 4.35 c 0,0.06 0.05,0.11 0.11,0.11 h 3.97 c 0.24,0 0.43,0.19 0.43,0.43 v 3.97 c 0,0.06 0.05,0.11 0.11,0.11 h 4.35 c 0.06,0 0.11,-0.05 0.11,-0.11 v -3.97 c 0,-0.24 0.19,-0.43 0.43,-0.43 h 3.97 c 0.06,0 0.11,-0.05 0.11,-0.11 V 9.18 c 0,-0.06 0.05,-0.11 0.11,-0.11 h 3.22 c 0.06,0 0.110001,0.05 0.110001,0.11 v 4.4 1.65 c 0,3.12 2.14,3.94 2.57,4.07 0.05,0.01 0.1,0.01 0.15,0.02 h 5.22 C 25.03,19.32 25.08,19.27 25.08,19.21 v -4.4 c 0,-0.06 -0.05,-0.11 -0.109999,-0.11 h -2.93 c -0.45,0.03 -0.44,-0.48 -0.44,-0.48 v -0.64 -4.4 c -0.01,-0.06 0.04,-0.11 0.1,-0.11 z"
            />
            <g transform="translate(-38.22,-59.25)">
                <path d="m 68.46,60.86 c 0.46,-0.08 1.07,-0.15 1.84,-0.15 0.94,0 1.64,0.22 2.08,0.62 0.4,0.35 0.65,0.89 0.65,1.55 0,0.67 -0.2,1.2 -0.58,1.59 -0.51,0.54 -1.33,0.81 -2.27,0.81 -0.29,0 -0.55,-0.01 -0.77,-0.07 v 2.97 h -0.96 v -7.32 z m 0.95,3.57 c 0.21,0.06 0.48,0.08 0.79,0.08 1.16,0 1.86,-0.57 1.86,-1.58 0,-0.98 -0.7,-1.46 -1.75,-1.46 -0.42,0 -0.74,0.04 -0.91,0.08 v 2.88 z"/>
                <path d="m 78.82,65.47 c 0,1.97 -1.37,2.83 -2.65,2.83 -1.44,0 -2.56,-1.05 -2.56,-2.74 0,-1.78 1.17,-2.82 2.64,-2.82 1.54,0 2.57,1.11 2.57,2.73 z m -4.22,0.06 c 0,1.17 0.67,2.05 1.61,2.05 0.93,0 1.62,-0.87 1.62,-2.07 0,-0.9 -0.45,-2.04 -1.6,-2.04 -1.13,0 -1.63,1.05 -1.63,2.06 z"/>
                <path d="m 79.91,67.2 c 0.29,0.18 0.8,0.38 1.28,0.38 0.71,0 1.03,-0.35 1.03,-0.79 0,-0.47 -0.28,-0.71 -0.99,-0.97 -0.96,-0.35 -1.42,-0.87 -1.41,-1.51 0,-0.86 0.7,-1.56 1.84,-1.56 0.54,0 1.01,0.15 1.3,0.33 l -0.24,0.7 c -0.21,-0.13 -0.59,-0.31 -1.09,-0.31 -0.57,0 -0.88,0.33 -0.88,0.73 0,0.44 0.31,0.64 1.01,0.91 0.92,0.34 1.39,0.8 1.4,1.6 0,0.94 -0.73,1.6 -1.99,1.6 -0.58,0 -1.12,-0.15 -1.5,-0.37 z"/>
                <path d="m 85.48,61.36 c 0,0.33 -0.23,0.6 -0.62,0.6 -0.35,0 -0.58,-0.27 -0.58,-0.6 0,-0.33 0.25,-0.6 0.6,-0.6 0.37,0 0.6,0.26 0.6,0.6 z m -1.08,6.82 v -5.32 h 0.96 v 5.32 z"/>
                <path d="m 88.14,61.33 v 1.53 h 1.39 v 0.73 h -1.39 v 2.87 c 0,0.66 0.19,1.04 0.73,1.04 0.25,0 0.43,-0.03 0.56,-0.07 l 0.04,0.74 c -0.19,0.07 -0.49,0.13 -0.86,0.13 -0.44,0 -0.81,-0.15 -1.04,-0.41 C 87.3,67.6 87.19,67.13 87.19,66.5 v -2.91 h -0.82 v -0.73 h 0.82 v -1.28 z"/>
                <path d="m 91.7,61.36 c 0,0.33 -0.23,0.6 -0.62,0.6 -0.35,0 -0.58,-0.27 -0.58,-0.6 0,-0.33 0.25,-0.6 0.6,-0.6 0.36,0 0.59,0.26 0.6,0.6 z m -1.08,6.82 v -5.32 h 0.96 v 5.32 z"/>
                <path d="m 93.56,62.86 1.04,2.98 c 0.18,0.49 0.32,0.93 0.44,1.37 h 0.03 c 0.12,-0.44 0.27,-0.88 0.45,-1.37 l 1.04,-2.98 h 1.01 l -2.09,5.32 h -0.92 l -2.03,-5.32 z"/>
                <path d="m 98.91,65.7 c 0.02,1.31 0.85,1.85 1.82,1.85 0.69,0 1.12,-0.12 1.47,-0.27 l 0.17,0.69 c -0.34,0.15 -0.93,0.34 -1.77,0.34 -1.63,0 -2.62,-1.09 -2.62,-2.69 0,-1.61 0.95,-2.87 2.5,-2.87 1.74,0 2.19,1.52 2.19,2.5 0,0.2 -0.01,0.35 -0.03,0.45 z m 2.83,-0.7 c 0.01,-0.61 -0.25,-1.57 -1.33,-1.57 -0.99,0 -1.41,0.89 -1.49,1.57 z"/>
            </g>
            <g transform="translate(-38.22,-59.25)">
                <path d="m 69.89,71.51 h -2.42 v -0.44 h 5.36 v 0.44 h -2.42 v 6.98 h -0.52 z"/>
                <path d="m 73.16,74.79 c 0,-0.53 -0.02,-1.07 -0.04,-1.58 h 0.48 l 0.02,1.07 h 0.03 c 0.23,-0.67 0.78,-1.19 1.5,-1.19 0.07,0 0.15,0.01 0.21,0.03 v 0.5 C 75.28,73.6 75.21,73.6 75.11,73.6 c -0.72,0 -1.24,0.63 -1.39,1.48 -0.03,0.15 -0.04,0.33 -0.04,0.5 v 2.92 h -0.52 z"/>
                <path d="m 79.26,78.48 -0.08,-0.76 h -0.02 c -0.26,0.41 -0.84,0.88 -1.68,0.88 -1.06,0 -1.55,-0.75 -1.55,-1.45 0,-1.22 1.07,-1.95 3.22,-1.93 v -0.14 c 0,-0.52 -0.1,-1.57 -1.35,-1.56 -0.46,0 -0.94,0.12 -1.33,0.39 L 76.3,73.53 c 0.48,-0.33 1.07,-0.46 1.55,-0.46 1.52,0 1.82,1.14 1.82,2.09 v 2.06 c 0,0.42 0.02,0.85 0.08,1.25 h -0.49 z m -0.12,-2.82 c -1.15,-0.03 -2.67,0.14 -2.67,1.41 0,0.76 0.5,1.1 1.05,1.1 0.88,0 1.38,-0.54 1.56,-1.06 0.04,-0.11 0.06,-0.23 0.06,-0.32 z"/>
                <path d="m 81.38,74.46 c 0,-0.48 -0.02,-0.85 -0.04,-1.26 h 0.49 l 0.04,0.96 h 0.02 c 0.29,-0.59 0.95,-1.08 1.82,-1.08 0.5,0 1.84,0.26 1.84,2.24 v 3.17 h -0.52 v -3.12 c 0,-0.96 -0.37,-1.83 -1.46,-1.83 -0.74,0 -1.38,0.53 -1.58,1.22 -0.04,0.13 -0.07,0.31 -0.07,0.47 V 78.5 H 81.4 v -4.04 z"/>
                <path d="m 87.05,77.79 c 0.28,0.18 0.71,0.37 1.19,0.37 0.83,0 1.23,-0.46 1.23,-1.01 0,-0.58 -0.35,-0.89 -1.11,-1.19 -0.86,-0.33 -1.34,-0.81 -1.34,-1.45 0,-0.76 0.6,-1.43 1.63,-1.43 0.49,0 0.9,0.15 1.17,0.34 l -0.21,0.43 c -0.18,-0.13 -0.52,-0.32 -1.05,-0.32 -0.67,0 -1.03,0.43 -1.03,0.91 0,0.55 0.37,0.79 1.09,1.08 0.85,0.34 1.36,0.76 1.36,1.56 0,0.91 -0.7,1.52 -1.78,1.52 -0.51,0 -0.99,-0.15 -1.34,-0.37 z"/>
                <path d="m 92.06,71.69 c 0,0.24 -0.16,0.46 -0.44,0.46 -0.24,0 -0.41,-0.21 -0.41,-0.46 0,-0.24 0.18,-0.46 0.43,-0.46 0.25,0.01 0.42,0.21 0.42,0.46 z m -0.69,6.79 V 73.2 h 0.52 v 5.28 z"/>
                <path d="m 94.4,71.67 v 1.53 h 1.5 v 0.42 h -1.5 v 3.43 c 0,0.67 0.21,1.09 0.78,1.09 0.27,0 0.46,-0.04 0.6,-0.08 l 0.06,0.4 c -0.17,0.07 -0.41,0.13 -0.73,0.13 -0.38,0 -0.7,-0.12 -0.91,-0.37 -0.24,-0.28 -0.32,-0.72 -0.32,-1.26 V 73.62 H 92.99 V 73.2 h 0.89 v -1.36 z"/>
                <path d="m 97.76,71.69 c 0,0.24 -0.16,0.46 -0.44,0.46 -0.24,0 -0.41,-0.21 -0.41,-0.46 0,-0.24 0.18,-0.46 0.43,-0.46 0.25,0.01 0.42,0.21 0.42,0.46 z m -0.69,6.79 V 73.2 h 0.52 v 5.28 z"/>
                <path d="m 103.88,75.79 c 0,1.97 -1.35,2.81 -2.53,2.81 -1.37,0 -2.42,-1.06 -2.42,-2.72 0,-1.81 1.19,-2.8 2.5,-2.8 1.46,0 2.45,1.1 2.45,2.71 z m -4.42,0.07 c 0,1.32 0.84,2.31 1.93,2.31 1.1,0 1.96,-1 1.96,-2.35 0,-0.97 -0.54,-2.3 -1.94,-2.3 -1.34,0 -1.95,1.2 -1.95,2.34 z"/>
                <path d="m 105.22,74.46 c 0,-0.48 -0.02,-0.85 -0.04,-1.26 h 0.49 l 0.04,0.96 h 0.02 c 0.29,-0.59 0.95,-1.08 1.82,-1.08 0.5,0 1.84,0.26 1.84,2.24 v 3.17 h -0.52 v -3.12 c 0,-0.96 -0.37,-1.83 -1.46,-1.83 -0.74,0 -1.38,0.53 -1.58,1.22 -0.04,0.13 -0.07,0.31 -0.07,0.47 v 3.27 h -0.52 v -4.04 z"/>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'PLogo',
    props: {
        colour: {
            type: String,
            default: 'white'
        }
    },
    computed: {
        cssVariables()
        {
            return { '--logo-colour': this.colour };
        }
    }
};
</script>

<style lang="scss" scoped>
.p-logo {
    display: block;

    svg {
        display: block;
        overflow: visible;

        path {
            fill: var(--logo-colour);
        }
    }
}
</style>