/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default [
    {
        name: 'Dashboard Quiz Attempts',
        path: 'attempts/:quizId/:attemptId?',
        component: () => import( '@/views/Dashboard/QuizOverview/AttemptsModal'),
        props: (route) =>
        {
            return {
                quizId: route.params.quizId,
                attemptId: isNaN(route.params.attemptId) ? false : Number(route.params.attemptId)
            };
        }
    }
];