/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default {
    NONE: 0x0,
    REQUIRED: 0x1,
    MULTIPLE: 0x2,
    OPT_OUT: 0x4,
    ALL_ALLOWED: 0x8,
    HORIZONTAL: 0x10
};