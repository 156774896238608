<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div v-if="subsections === null" class="mb-3">
        <div class="d-flex align-center justify-center flex-column">
            <PLoadingSpinner/>
            <h1>Loading the next set...</h1>
        </div>
    </div>
    <div v-else class="mb-3 mx-3">
        <!--                        <h1 :class="{'mb-1': section.preamble !== undefined && section.preamble !== null && section.preamble.length > 0}">{{ section.label }}</h1>-->
        <div v-if="preamble !== undefined && preamble !== null && preamble.length > 0">
            <p v-for="(line,index) in preamble" :key="index" class="my-0 text--secondary">{{ line }}</p>
        </div>
        <QuestionnaireSubsection v-for="(subsection, subsectionIndex) in subsections" :key="subsectionIndex" v-bind="subsection"/>
    </div>
</template>

<script>
import QuestionnaireSubsection from '@/views/Questionnaire/QuestionnaireSubsection';

export default {
    name: 'QuestionnaireSection',
    components: { QuestionnaireSubsection },
    props: {
        id: {
            type: Number
        },
        label: {
            type: String
        },
        preamble: {
            type: Array,
            default: () => ([])
        },
        subsections: {
            type: Array,
            default: () => ([])
        },
        displayOrder: {
            type: Number
        },
        icon: {
            type: String
        }
    }
};
</script>

