<!--
  - Copyright © 2020-2022 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div
        class="scrollable-indicator"
        v-if="show"
        aria-hidden="true"
        :style="`left:calc(50% + ${offset/2}px - 28px);`"
    >
        <v-btn
            fab
            elevation="1"
            @click="scroll"
            :color="color"
        >
        <v-icon
            large
            color="#ffffff"
        >mdi-chevron-down</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'ScrollableIndicator',
    props: {
        scrollTop: {
            type: Number,
            default: 0
        },
        padding: {
            type: Number,
            default: 200
        },
        offset: {
            type: Number,
            default: 0
        },
        force: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'primary'
        }
    },
    data() {
        return {
            show: false
        };
    },
    methods: {
        setFade()
        {
            window.requestAnimationFrame(() =>
            {
                if (this.scrollTop + window.innerHeight < document.documentElement.scrollHeight - this.padding)
                    this.show = true;
                else
                    this.show = false;
            });
        },
        scroll()
        {
            window.scrollBy({
                top: this.padding * 2,
                left: 0,
                behavior : "smooth"
            });
        }
    },
    mounted()
    {
        window.setTimeout(
            this.setFade,
            50
        );
    },
    watch: {
        scrollTop()
        {
            this.setFade();
        },
        force()
        {
            this.setFade();
        }
    }
};
</script>

<style scoped lang="scss">
.scrollable-indicator {
    bottom:1.5em;
    opacity:1;
    position:fixed;
    text-align:center;
    width:0;
    z-index: 3;
}
</style>