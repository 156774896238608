<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <!-- img is a required tag in <picture>, <source> defines which src to use -->
    <picture class="picture-group">
        <source v-if="webp_url !== null" :srcset="webp_url" type="image/webp"/>
        <source v-if="jpg_url !== null" :srcset="jpg_url" type="image/jpeg"/>
        <img :src="fallbackUrl" :alt="alt" style="max-height: 208px;"/>
    </picture>
</template>

<script>
/**
 * Component to enable the use of webp images, with a backup of jpg
 *
 * TODO: delete this and make it either generic or part of the video card.
 *
 */

const DEFAULT_IMAGE = '/assets/images/video-default.svg';

export default {
    name: 'VPicture',
    props: {
        webp_url: {
            type: String,
            default: null
        },
        jpg_url: {
            type: String,
            default: null
        },
        alt: {
            type: String,
            default: 'Video thumbnail'
        }
    },
    computed: {
        fallbackUrl()
        {
            if ( this.webp_url !== null)
                return this.webp_url;
            if (this.jpg_url !== null)
                return this.jpg_url;
            return DEFAULT_IMAGE;
        }
    }
};
</script>

<style lang="scss" scoped>
.picture-group {

    img {
        flex-shrink: 0;
        width: 100%;
    }

}
</style>