<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="d-flex align-center flex-column">
        <v-img src="/assets/images/onboarding/slide3.svg" max-height="200px" contain></v-img>
        <h1 class="mt-3">All done!</h1>
        <div class="d-flex align-center flex-column mt-3">
            <p class="ma-0 text-center">You have completed the profile questionnaire.</p>
            <p class="ma-0 text-center">Your answers have been saved and the content available to you has been updated.</p>
            <p class="ma-0 text-center">You can retake the questionnaire at any time from your profile, the navigation menu or the navigation menu. You may wish to do this when your circumstances change in order to access relevant content.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuestionnaireSummary'
};
</script>

<style scoped>

</style>