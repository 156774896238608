<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<script>
import { VTabItem } from 'vuetify/lib';

export default {
    name: 'PTabItem',
    extends: VTabItem,
    inject: [ 'registerTab', 'unregisterTab' ],
    props: {
        title: {
            type: String,
            default: 'Unset'
        }
    },
    mounted()
    {
        this.registerTab(this);
    },
    beforeDestroy()
    {
        this.unregisterTab(this);
    }
};
</script>