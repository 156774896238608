/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

/**
 * Common functions that implement the standard MenuAPI for sections.
 */
export default {
    props: {
        /**
         * The label for display to the user
         */
        label: {
            type: String
        },

        /**
         * Hides the divider between the sections
         */
        hide_divider: {
            type: Boolean,
            default: false
        },

        /**
         * The items to display in this section.
         */
        items: {
            type: Array,
            default: () => []
        }
    }
};