/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export const Levels = {
    Verbose: { label: 'Verbose', colour: '#fff', func: console.debug },
    Debug: { label: 'Debug', colour: '#001ac8', func: console.debug },
    Log: { label: 'Log', colour: '#387938', func: console.log },
    Warning: { label: 'Warning', colour: '#ff4800', func: console.warn },
    Error: { label: 'Error', colour: '#f00', func: console.error }
};

class PTLogger {

    constructor(categoryText = null)
    {
        this.categoryText = categoryText;
    }

    log(...text)
    {
        this.write(Levels.Log, ...text);
    }

    error(...text)
    {
        this.write(Levels.Error, ...text);
    }

    warning(...text)
    {
        this.write(Levels.Warning, ...text);
    }

    debug(...text)
    {
        this.write(Levels.Debug, ...text);
    }

    verbose(...text)
    {
        this.write(Levels.Verbose, ...text);
    }

    write(level, ...text)
    {
        let lines = [];
        if(this.categoryText !== null)
        {
            lines = [
                `%c Positive Transition %c ${ this.categoryText } %c ${ level.label } %c`,
                'background: #9a9da0; padding: 1px; border-radius: 3px 0 0 3px; color: #fff',
                'background: #685986; padding: 1px; border-radius: 3px 0 0 3px; color: #fff',
                `background: ${ level.colour }; padding: 1px; border-radius: 0 3px 3px 0; color: #fff`,
                'background: transparent; color: white'
            ];
        }
        else
        {
            lines = [
                `%c Positive Transition %c ${ level.label } %c`,
                'background: #9a9da0; padding: 1px; border-radius: 3px 0 0 3px; color: #fff',
                `background: ${ level.colour }; padding: 1px; border-radius: 0 3px 3px 0; color: #fff`,
                'background: transparent; color: white'
            ];
        }
        level.func.apply(console, [
            ...lines,
            ...text
        ]);
    }
}

export default new PTLogger();

export function createCategory(categoryText)
{
    return new PTLogger(categoryText);
}
