/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

/**
 * MathsLib
 * Maths-y classes/functions go here
 */

/**
 * Class for handling 2D vectors
 */
class Vector2
{
    constructor ( x, y )
    {
        this.x = x;
        this.y = y;
    }

    magnitude()
    {
        return Math.sqrt( this.x * this.x + this.y * this.y);
    }

    direction()
    {
        return Math.atan( this.y / this.x );
    }
}

module.exports = {
    Vector2
};