<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="static-document">
        <div v-if="loading">
            <h3>
                Loading content...
                <v-progress-circular indeterminate/>
            </h3>
        </div>
        <div v-else v-html="content">

        </div>
    </div>
</template>

<script>
export default {
    name: 'PStaticDocument',
    props: {
        document: {
            type: String,
            required: true
        }
    },
    data()
    {
        return {
            loading: true,
            content: null
        };
    },
    mounted()
    {
        this.$ptAjax({
            url: `/data/static-docs/${ this.document }`,
            type: 'get',
            silent: { success: true },
            success: {
                run: ({ body }) =>
                {
                    this.content = atob(body.content);
                    this.loading = false;
                }
            }
        });
    }
};
</script>

<style scoped>

</style>