<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-footer padless class="mt-10 mt-mb-0" style="">
        <v-card flat tile width="100%" class="footer text-right">
            <v-row class="justify-space-between">
                <v-col class="pl-5 d-flex align-center mx-0 mt-2 mt-md-0 mx-md-4 justify-center justify-md-start" cols="12" md="3" order="1" order-md="0">
                    <!-- icons -->
                    <v-btn class="footer-icon mr-1" icon :href="icon.href" target="_blank" v-for="(icon,index) in icons" :key="index">
                        <v-icon class="white--text">{{ icon.icon }}</v-icon>
                    </v-btn>
                </v-col>
                <v-col class="d-flex justify-center align-center flex-wrap flex-md-nowrap pt-2 pb-0 pb-md-2" cols="12" md="3" order="0" order-md="1">
                    <!-- links -->
                    <div v-for="(link,index) in filteredLinks" :key="index">
                        <v-btn active-class="no-active" class="footer-link font-weight-light" :to="link.to" :href="link.href" v-on="link.click?{click:link.click}:{}">{{ link.label }}</v-btn>
                    </div>
                </v-col>
                <v-col cols="12" md="3" class="py-0 py-md-2" order="2">
                    <!-- copyright text -->
                    <v-card-text v-for="item in footer_items" :key="item.label" class="text-center text-md-right">
                        <!-- <a :href="item.href" v-html="item.label"/> -->
                        <p class="mb-0 grey--text" v-html="item.label"></p>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </v-footer>
</template>

<script>
import { mapState } from 'vuex';

/**
 * This component describes the footer for the main page area.
 * It contains copyright information.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'PageFooter',
    inject: [
        'setGlobalModalState'
    ],
    computed: {
        ...mapState([ 'flags' ]),
        years()
        {
            return (this.start_year === parseInt(this.current_year)) ? this.start_year : `${ this.start_year }&nbsp;-&nbsp;${ this.current_year }`;
        },
        filteredLinks()
        {
            return this.flags.development ? this.footer_links : this.footer_links.filter(x => !x.dev);
        }
    },
    data()
    {
        //Override the context change of the getter function of label
        let that = this;
        return {
            /**
             * The year that the copyright date starts at.
             */
            start_year: 2020,

            current_year: 2020,

            /**
             * A list of items that appear in the footer.
             */
            footer_items: [
                {
                    //Use getter function so it syntactically behaves like a variable
                    get label()
                    {
                        return `Positive Transition LTD Copyright ${ that.years }<br />All Rights Reserved`;
                    },
                    href: 'https://plus-t.co.uk'
                }
            ],
            icons: [
                {
                    label: 'LinkedIn',
                    href: 'https://www.linkedin.com/company/positive-transition-ltd/',
                    icon: 'mdi-linkedin'
                },
                {
                    label: 'Twitter',
                    href: 'https://twitter.com/Plus_Transition',
                    icon: 'mdi-twitter'
                },
                {
                    label: 'Facebook',
                    href: 'https://facebook.com/PositiveTransition',
                    icon: 'mdi-facebook'
                },
                {
                    label: 'Youtube',
                    href: 'https://www.youtube.com/channel/UCDS0XoBzYQg8NZzEIJ-oZGQ',
                    icon: 'mdi-youtube'
                }
            ],
            footer_links: [
                {
                    label: 'About',
                    to: '/settings/about',
                    dev: false
                },
                {
                    label: 'Status',
                    href: 'https://status.plus-t.co.uk',
                    dev: true
                },
                {
                    label: 'Terms',
                    click: () =>
                    {
                        this.setGlobalModalState('terms', true);
                    },
                    dev: false
                },
                {
                    label: 'Privacy',
                    click: () =>
                    {
                        this.setGlobalModalState('privacy', true);
                    },
                    dev: false
                },
                {
                    label: 'Help',
                    to: '/settings/help',
                    dev: true
                },
                {
                    label: 'Feedback',
                    click: () =>
                    {
                        this.setGlobalModalState('feedback', true);
                    },
                    dev: false
                }
            ]
        };
    },
    async mounted()
    {
        await this.refreshDate();
    },
    methods: {
        async refreshDate()
        {
            await this.$ptAjax({
                url: '/data/time',
                type: 'get',
                silent: true,
                success: {
                    run: ({ body: { datetime: { y } } }) =>
                    {
                        this.current_year = y;
                    }
                },
                error: {
                    run: () =>
                    {
                        //Fallback in case the server fails.
                        this.current_year = (new Date()).getFullYear();
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.footer {
    border-top: 0.5px solid grey;
}

.footer-link {
    background: transparent !important;
    box-shadow: none;
    color: #1976d2;
}

.footer-icon {
    background: #1976d2;
}

.row {
    margin-top: 0;
    margin-bottom: 0;
}
</style>