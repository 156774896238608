<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-menu offset-y bottom>
        <template v-slot:activator="{ on, attrs }">
            <slot name="activator" v-bind:on="on" v-bind:attrs="attrs"/>
        </template>
        <v-list>
            <PDropdownMenuSection v-for="(item, index) in items" :key="item.label" v-bind="item" :hide_divider="index === 0 || item.hide_divider"/>
        </v-list>
    </v-menu>
</template>

<script>
import { MenuCommonProp } from '@/components/Layout/Menus/MenuCommon';
import PDropdownMenuSection from '@/components/Layout/Menus/DropdownMenu/PDropdownMenuSection';

export default {
    name: 'PDropdownMenu',
    components: { PDropdownMenuSection },
    mixins: [ MenuCommonProp ]
};
</script>

<style scoped>

</style>