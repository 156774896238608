<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div>

        <PConvertibleModal v-model="dialogs.thanks" title="Thank You!" max-width="350" force-desktop no-toolbar>
            We have received your feedback and will look over it as soon as possible!
            <br>
            Thank you for your feedback and helping to make Positive Transition a better place for all!

            <template #actions="{close}">
                <v-btn text @click="close">Finish</v-btn>
            </template>
        </PConvertibleModal>

        <!--<b-modal id="feedback-thanks" title="Thank You!" ok-only ok-title="Close">
            We have received your feedback and will look over it as soon as possible!
            <br>
            Thank you for your feedback and helping to make Positive Transition a better place for all!
        </b-modal>-->

        <PConvertibleModal v-model="dialogs.anonymous_info" title="Information Collected" max-width="350" persistent>
            <template #toolbar-items="{close}">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>

            This information can include:
            <ul>
                <li>Anonymised diagnostic data</li>
                <li>User Agent string</li>
                <li>Browser version</li>
                <li>Viewport state</li>
                <li>Primary input method</li>
                <li>Current page</li>
                <li>JavaScript stack traces</li>
            </ul>
        </PConvertibleModal>

        <PConvertibleModal v-model="isOpen" title="Send Feedback" max-width="800">

            <template #toolbar-items="{close}">
                <v-menu bottom right offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="setGlobalModalState('privacy', true)">
                            <v-list-item-title>Privacy Policy</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn icon @click="submitFeedback" :disabled="!valid">
                    <v-icon>mdi-send</v-icon>
                </v-btn>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>

            <v-container>
                <v-text-field label="Title" :rules="[rules.required]" hint="Describe the important bits of your issue/suggestion in a few words" persistent-hint v-model="form_data.title"/>
                <br>
                <v-select label="Feedback Type" :rules="[rules.required]" :items="issueTypes" v-model="form_data.issue_type" @change="typeChange" hint="Select the category that best matches your report." persistent-hint/>

                <br>
                <v-divider/>
                <br>

                <template v-if="fields_loading && !fields_error">
                    <v-progress-circular indeterminate/>
                </template>

                <template v-if="fields_error">
                    <v-alert border="left" colored-border color="error" elevation="2">
                        <b>Error</b><br>
                        An error occurred with your request. Please try again
                    </v-alert>
                </template>

                <template v-if="form_data.issue_type === null">
                    <v-alert border="left" colored-border color="info" elevation="2">
                        <b>Information</b><br>
                        Please select feedback type above to continue the feedback process.
                    </v-alert>
                </template>

                <template v-if="!this.fields_loading && this.form_fields.length !== 0">
                    <PTFormField v-for="(field,index) in form_fields" :key="field.id" v-model="field.value" :index="index" v-bind="field"/>
                </template>

                <br>
                <v-divider/>
                <br>

                <v-file-input v-if="flags.demo" v-model="form_data.files" multiple label="Related Files" hint="Any files that can help, such as screenshots and content files." persistent-hint/>

                <v-checkbox v-model="form_data.consent.contact" :rules="[rules.required]">
                    <template #label>I consent to Positive Transition contacting me regarding this submission using the contact details on my account.</template>
                </v-checkbox>
                <v-checkbox v-model="form_data.consent.collect" :rules="[rules.required]">
                    <template #label><span>I consent to Positive Transition collecting <a href="#" @click.stop.prevent="dialogs.anonymous_info = true">anonymous information</a> about my system when submitting this report.</span></template>
                </v-checkbox>

            </v-container>

        </PConvertibleModal>
    </div>
</template>

<script>
/**
 * The feedback modal for submitting platform feedback without leaving the site.
 *
 * TODO: remove the manual field collation and piggyback off v-form
 * TODO: rewrite this. it's a mess.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 * @author T J Collinson <tom.collinson@plus-t.co.uk>
 */

import PTFormField from '@/views/Feedback/PTFormField';
import { mapState } from 'vuex';
import ValidationRules from '@plust/client-common/src/ValidationRules';

export let fbModal = null;

export default {
    name: 'FeedbackModal',
    mixins: [ ValidationRules ],
    inject: [ 'setGlobalModalState' ],
    components: {
        PTFormField
    },
    props: {
        value: {
            type: Boolean
        }
    },
    computed: {
        ...mapState([ 'flags', 'feedback' ]),

        isOpen: {
            get: function ()
            {
                return this.value;
            },
            set: function (value)
            {
                this.$emit('input', value);
            }
        },

        /**
         * Is the form valid?
         *
         * @returns {boolean}
         */
        valid()
        {
            if (this.form_data.title === null || this.form_data.title === '')
                return false;
            if (this.form_data.issue_type === null)
                return false;
            if (!this.form_data.consent.collect || !this.form_data.consent.contact)
                return false;
            return true;
        },

        issueTypes()
        {
            let types = [
                {
                    text: 'Please select a report type',
                    value: null,
                    disabled: true
                }
            ];
            for (let i = 0, j = this.feedback?.types; i < j.length; i++)
            {
                types.push({
                    value: Number(j[i].id),
                    text: j[i].title
                });
            }
            return types;
        }
    },
    data()
    {
        return {
            /**
             * Is the form submitting?
             */
            submitting: false,

            /**
             * The content of the form data is replicated into
             */
            form_data: {
                title: null,
                issue_type: null,
                files: [],
                consent: {
                    contact: false,
                    collect: false
                },
                context: {
                    description: null,
                    module: null
                }
            },

            /**
             * Initialised on component mount, this is a json copy of the form_data section that is used to restore the form to original state.
             */
            form_data_template: {},

            /**
             * A list of issue types used for the issue type dropdown.
             */
            issue_types: [],

            /**
             * A list of modules used for the module select dropdown.
             * TODO: retrieve this from the server
             */
            modules: [
                { value: null, text: 'Please select a module' },
                { value: 'employment', text: 'Employment' },
                { value: 'finance', text: 'Finance' },
                { value: 'education', text: 'Education' }
            ],

            /**
             * true when we are loading fields for a new feedback type
             */
            fields_loading: false,

            /**
             * true if an error occurred with Ajax for form fields
             */
            fields_error: false,

            /**
             * Stores the form fields
             */
            form_fields: [],

            dialogs: {
                anonymous_info: false,
                thanks: false
            }
        };
    },
    mounted()
    {
        this.form_data_template = JSON.stringify(this.form_data);
        //Basically export the singleton. Probably a bad idea, but we'll see.
        fbModal = this;
    },
    methods: {

        /**
         * This is a hack and needs replacing. In fact the entire feedback system needs re-evaluating because it's a complete mess.
         */
        async open(type, defaultValues, title = null)
        {
            let issueData = this.feedback.types[this.feedback.types.findIndex(x => x.url_fragment === type)];
            this.form_data.issue_type = Number(issueData.id);
            this.form_data.title = title;
            this.isOpen = true;
            await this.typeChange();
            for (let x of this.form_fields)
            {
                if (defaultValues[x.name] !== undefined)
                    x.value = defaultValues[x.name];
            }
        },

        /**
         * Runs when the feedback type is changed
         * Loads the form fields for this option
         */
        async typeChange()
        {
            this.fields_loading = true;
            this.fields_error = false;

            //Find the url fragment for this
            let fragment = null;
            for (let i = 0, j = this.feedback?.types; i < j.length; i++)
            {
                if (Number(j[i].id) === this.form_data.issue_type)
                {
                    fragment = j[i].url_fragment;
                    break;
                }
            }

            if (fragment == null)
            {
                this.fields_loading = false;
                return;
            }

            //Ajax for this fragment data
            await this.$ptAjax({
                url: `/data/feedback/${ fragment }/fields`,
                type: 'get',
                silent: true,
                complete: () =>
                {
                    //this.$refs.loading_cont.$el.classList.add('shrink');
                    setTimeout(() =>
                    {
                        this.fields_loading = false;
                    }, 500);
                },
                error: {
                    run: () =>
                    {
                        this.fields_error = true;
                    }
                },
                success: {
                    run: (r) =>
                    {
                        if (r.code !== 200) throw r.message;
                        this.form_fields = r.body.fields;
                        this.fields_error = false;
                    }
                }
            });

        },

        /**
         * Fired when the form is completed to process feedback submissions.
         *
         * @returns {Promise<void>}
         */
        async submitFeedback()
        {
            this.submitting = true;

            if (!this.valid)
            {
                this.$snotify.error('Missing form properties', 'Validation Error', {
                    timeout: 5000
                });
                return;
            }

            let fd = new FormData();
            this.gatherData(fd);

            for (let i = 0, j = this.form_fields.length; i < j; i++)
            {
                let fieldData = this.form_fields[i];
                fd.append(fieldData.css_id, fieldData.value);
            }

            let data = {};

            for (let pair of fd.entries())
            {
                data[pair[0]] = pair[1];
            }

            this.$ptAjax({
                url: '/data/feedback/submit',
                type: 'post',
                data: data,
                silent: {
                    success: true,
                    error: false
                },
                success: {
                    code: 200,
                    run: (r) =>
                    {
                        if (r.code !== 200) throw r.message;
                        this.isOpen = false;
                        this.dialogs.thanks = true;
                        this.resetForm();
                    }
                },
                complete: () =>
                {
                    this.submitting = false;
                }
            });
        },

        /**
         * Resets the form back to default values.
         */
        resetForm()
        {
            this.form_data = JSON.parse(this.form_data_template);
        },

        /**
         * Collect data on the current system if consent is provided to do so.
         *
         * @returns {Object}
         */
        gatherSystemData()
        {
            if (!this.form_data.consent.collect)
                return {};
            let sysData = {};
            sysData.userAgent = navigator.userAgent;
            sysData.window = {
                w: window.innerWidth,
                h: window.innerHeight,
                sx: window.scrollX,
                sy: window.scrollY,
                orientation: (window.innerHeight > window.innerWidth) ? 'PORTRAIT' : 'LANDSCAPE'
            };
            sysData.document = {};

            for (let item in document.location)
                if (item in document.location && typeof document.location[item] === 'string')
                    sysData.document[item] = document.location[item];

            return sysData;
        },

        /**
         * Gather data from the form and the system if applicable.
         *
         * @param fd {FormData} an instance to populate.
         */
        gatherData(fd)
        {
            let trueData = {};
            for (let item in this.form_data)
            {
                if (!Object.prototype.hasOwnProperty.call(this.form_data, item) || item === 'files')
                    continue;
                trueData[item] = this.form_data[item];
            }
            trueData['sysinfo'] = this.gatherSystemData();
            this.buildFormData(fd, trueData);
            for (let file of this.form_data.files)
                fd.append('files[]', file);
        },

        /**
         * Applies data from an array to a form data instance.
         *
         * @param formData {FormData} the form data instance
         * @param data {any} the item to add to the instance
         * @param [parentKey=undefined] {string} used internally to build the keys for the form data object
         */
        buildFormData(formData, data, parentKey)
        {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File))
            {
                Object.keys(data).forEach(key =>
                {
                    this.buildFormData(formData, data[key], parentKey ? `${ parentKey }[${ key }]` : key);
                });
            }
            else
            {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        }
    }
};
</script>

<style lang="scss">
#feedback-modal {
    .modal-body {
        padding: 0;

        form {
            padding: 1rem;
        }
    }
}

.loading-cont {
    height: 100px;
    transition: height, padding-top, padding-bottom .5s;

    &.shrink {
        height: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        overflow: hidden;
    }
}
</style>