/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import PtAjax from '@plust/client-common/src/Ajax';
import VuexStore from '@/store';

export default [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "login"*/ '@/views/Login/LoginComponent'),
        meta: {
            hide_navigation: true,
            insecure: true
        },

        /**
         * Check if the user is already logged in and redirect them to the dashboard.
         * @param to
         * @param from
         * @param next
         */
        beforeEnter: (to, from, next) =>
        {
            PtAjax({
                url: '/data/auth/login',
                type: 'get',
                silent: true,
                success: {
                    run: async ({ body: { state } }) =>
                    {
                        if (state)
                        {
                            VuexStore.commit('set_logged_in', true);
                            next('/dashboard');
                        }
                        else
                            next();
                    }
                },
                error: {
                    run: async ({ code }) =>
                    {
                        //User must consent to GDPR exception ID.
                        if (code === 10007)
                        {
                            next('/consent');
                        }
                    }
                }
            });
        },

        children: [
            {
                name: 'Login',
                path: '',
                component: () => import(/* webpackChunkName: "login"*/ '@/views/Login/Views/BaseView'),
                props: () =>
                {
                    return { currentTab: 0 };
                },
                meta: {
                    hide_navigation: true,
                    insecure: true,
                    colour()
                    {
                        return '#54ad3a';
                    }
                }
            },
            {
                name: 'Register',
                path: '/register',
                component: () => import(/* webpackChunkName: "login"*/ '@/views/Login/Views/BaseView'),
                props: () =>
                {
                    return { currentTab: 1 };
                },
                meta: {
                    hide_navigation: true,
                    insecure: true,
                    colour()
                    {
                        return '#54ad3a';
                    }
                }
            },
            {
                name: 'Register New Account',
                path: 'register/:accessKey/:serviceNumber?',
                component: () => import(/* webpackChunkName: "login"*/ '@/views/Login/Views/RegistrationFullForm'),
                props: (route) =>
                {
                    return { accessKey: route.params.accessKey, serviceNumber: route.params.serviceNumber };
                },
                meta: {
                    hide_navigation: true,
                    insecure: true,
                    colour()
                    {
                        return '#54ad3a';
                    }
                }
            },
            {
                name: 'Register Sub-Account',
                path: 'register-sub-account/:accessKey',
                component: () => import(/* webpackChunkName: "login"*/ '@/views/Login/Views/RegisterSubAccountForm'),
                props: (route) =>
                {
                    return { accessKey: route.params.accessKey };
                },
                meta: {
                    hide_navigation: true,
                    insecure: true,
                    colour()
                    {
                        return '#54ad3a';
                    }
                }
            },
            {
                name: 'Reset Password',
                path: 'reset-password/:accessKey',
                component: () => import(/* webpackChunkName: "login"*/ '@/views/Login/Views/PasswordResetForm'),
                props: (route) =>
                {
                    return { accessKey: route.params.accessKey };
                },
                meta: {
                    hide_navigation: true,
                    insecure: true,
                    colour()
                    {
                        return '#54ad3a';
                    }
                }
            }
        ]
    }
];