<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-list-item class="supermenu-item" :to="href" :style="cssVariables">
        <v-list-item-icon style="margin-top: auto; margin-bottom: auto;">
            <div class="menu-icon-background">
                <v-icon class="menu-icon" color="white" size="25px">{{ icon }}</v-icon>
            </div>
        </v-list-item-icon>
        <v-list-item-title class="label-text">
            {{ label }}
        </v-list-item-title>
    </v-list-item>
</template>

<script>
import MenuItemCommon from '@/components/Layout/Menus/MenuItemCommon';

/**
 * The standard link used on the SuperMenu.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'SuperMenuItem',
    mixins: [ MenuItemCommon ],
    props: {
        hover_colour: {
            type: String,
            default: '#9a9da0'
        }
    },
    computed: {
        /**
         * Compute the CSS variables for the item.
         *
         * @returns {Object}
         */
        cssVariables()
        {
            return {
                '--icon-colour': this.icon_colour,
                '--hover-colour': this.hover_colour,
                '--idle-colour': this.idle_colour
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.supermenu-item {
    transition: 0.25s background-color, 0.25s color;
    max-height: 45px;

    .menu-icon-background {
        background-color: var(--hover-colour);
        border-radius: 50%;
        $size: 40px;
        width: $size;
        min-width: $size;
        height: $size;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover, &:focus {
        background-color: var(--hover-colour) !important;
        color: white;

        .menu-icon-background {
            background-color: white;
        }

        .menu-icon::v-deep path {
            fill: var(--hover-colour);
        }

        .label-text {
            color: white;
        }
    }

}
</style>