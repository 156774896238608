/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

const values = {
    Positive: 'POSITIVE',
    Negative: 'NEGATIVE',
    Unknown: 'UNKNOWN',
    Any: 'ANY',
    Range: 'RANGE',
    PositiveOrUnknown: 'POSITIVE_UNKNOWN',
    NegativeOrUnknown: 'NEGATIVE_UNKNOWN',
};

export default values;