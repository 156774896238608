/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import PtAjax from '@plust/client-common/src/Ajax';
import { createCategory } from '@plust/client-common/src/PTLogger';

const PTLogger = createCategory('Vuex|Questionnaire');

/**
 * This Vuex module contains all of the questionnaire.
 */
export default {
    namespaced: true,
    state: () => ({ progress: 0, hasContent: false }),
    mutations: {
        setInitialState(state, data)
        {
            state.progress = data.progress;
            state.hasContent = data.hasContent;
            PTLogger.debug('Successfully saved questionnaire data from server.');
        }
    },
    actions: {
        async loadInitialState({ commit })
        {
            PTLogger.debug('Loading initial state...');
            await PtAjax({
                url: '/data/questionnaire/state',
                type: 'get',
                silent: { success: true },
                success: {
                    run: ({ body }) =>
                    {
                        commit('setInitialState', body);
                    }
                }
            });
        }
    },
    getters: {
        all: (state) => state
    }
};