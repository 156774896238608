<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<script>
import { VIcon, VStepperStep } from 'vuetify/lib';

export default {
    name: 'v-stepper-step', //We have to lie here otherwise VStepper won't register the step properly.
    extends: VStepperStep,
    props: {
        stepIcon: {
            type: String,
            default: null
        },
        stepIconColour: {
            type: String,
            default: undefined
        }
    },
    methods: {
        /**
         * This is basically the same as the genStepContent from VStepperStep, just modified so that it pushes
         * a VIcon for the step if stepIcon is defined.
         *
         * @returns {*[]}
         */
        genStepContent()
        {
            const children = [];

            if (this.hasError)
            {
                children.push(this.genIcon(this.errorIcon));
            }
            else if (this.complete)
            {
                if (this.editable)
                {
                    children.push(this.genIcon(this.editIcon));
                }
                else
                {
                    children.push(this.genIcon(this.completeIcon));
                }
            }
            else
            {
                //TODO: undo the "size" property when the icons have been rebuilt to use the proper template.
                if (this.stepIcon !== null)
                    children.push(this.$createElement(VIcon, { props: { color: this.stepIconColour, size: '15px' } }, this.stepIcon));
                else
                    children.push(String(this.step));
            }

            return children;
        }
    }
};
</script>