<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <PConvertibleModal v-model="isOpen" :title="title" persistent max-width="500" scrollable>

        <template #toolbar-items="{close}">
            <v-btn icon @click="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>

        <v-container>
            <PStaticDocument :document="document"/>
        </v-container>

    </PConvertibleModal>
</template>

<script>
export default {
    name: 'PStaticDocumentModal',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        document: {
            type: String,
            required: true
        }
    },
    computed: {
        isOpen: {
            get()
            {
                return this.value;
            },
            set(value)
            {
                this.$emit('input', value);
            }
        }
    }
};
</script>