import { render, staticRenderFns } from "./PStaticDocument.vue?vue&type=template&id=80649a3e&scoped=true"
import script from "./PStaticDocument.vue?vue&type=script&lang=js"
export * from "./PStaticDocument.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80649a3e",
  null
  
)

export default component.exports