<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div>
        <v-toolbar class="tabs-desktop" v-if="toolbar" flat dense>
            <v-tabs v-model="tabIndex" :center-active="centerActive" :fixed-tabs="fixedTabs" grow>
                <v-tab v-for="(name, index) in tabItems" :key="index">{{ name }}</v-tab>
            </v-tabs>            
            <slot name="toolbar-after"/>
        </v-toolbar>
        <v-container class="tabs-mobile" v-if="toolbar" flat>            
            <v-tabs v-model="tabIndex" :center-active="centerActive" :fixed-tabs="fixedTabs" grow vertical>
                <v-tab v-for="(name, index) in tabItems" :key="index">{{ name }}</v-tab>
            </v-tabs>
            <slot name="toolbar-after"/>
        </v-container>
        <v-tabs v-else v-model="tabIndex" :center-active="centerActive" :fixed-tabs="fixedTabs">
            <v-tab v-for="(name, index) in tabItems" :key="index">{{ name }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabIndex">
            <slot/>
        </v-tabs-items>
    </div>
</template>

<script>
export default {
    name: 'PTabs',
    props: {
        centerActive: {
            type: Boolean,
            default: false
        },
        fixedTabs: {
            type: Boolean,
            default: false
        },
        toolbar: {
            type: Boolean,
            default: false
        }
    },
    provide()
    {
        return {
            'registerTab': this.registerTab,
            'unregisterTab': this.unregisterTab
        };
    },
    computed: {
        tabItems()
        {
            let names = [];
            for(let x of this.registeredTabs)
            {
                names.push(x.$props.title);
            }
            return names;
        }
    },
    data()
    {
        return {
            registeredTabs: [],
            tabIndex: 0
        };
    },
    methods: {
        registerTab(tab)
        {
            for(let x of this.registeredTabs)
            {
                if(tab === x)
                    return;
            }
            this.registeredTabs.push(tab);
        },
        unregisterTab(tab)
        {
            this.registeredTabs = this.registeredTabs.slice().filter(x => x !== tab);
        }
    }
};
</script>
<style lang="scss">

.tabs-desktop{
    display: none;
}

.tabs-mobile {
    display: flex;
    align-items: center;
}

@media screen and (min-width: 769px)
{
    .tabs-desktop{
        display: block;
    }

    .tabs-mobile{
        display: none;
    }
}

</style>