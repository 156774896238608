<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="youtube-player">
        <div :id="elementId"></div>
    </div>
</template>

<script>
import runner from './runner';

let pid = 0;
export default {
    name: 'YouTubePlayer',
    props: {
        height: {
            type: [ String, Number ]
        },
        width: {
            type: [ String, Number ]
        },
        videoId: {
            type: String
        },
        mute: {
            type: Boolean,
            default: false
        },
        playerVars: {
            type: Object,
            default: () => ({ autoplay: 0, time: 0 })
        }
    },
    data()
    {
        pid++;
        return {
            elementId: `yt-player-${ pid }`,
            player: {}
        };
    },
    watch: {
        width: 'setSize',
        height: 'setSize',
        videoId: 'update',
        mute: 'setMute'
    },
    methods: {
        setMute(value)
        {
            (value ? this.player.mute : this.player.unMute).call(this.player);
        },
        setSize()
        {
            this.player.setSize(this.width, this.height);
        },
        update(videoId)
        {
            const name = `${ this.playerVars.autoplay ? 'load' : 'cue' }VideoById`;
            // eslint-disable-next-line no-prototype-builtins
            if (this.player.hasOwnProperty(name))
            {
                this.player[name](videoId);
            }
            else
            {
                setTimeout(function ()
                {
                    this.update(videoId);
                }.bind(this), 100);
            }
        },
        getCurrentTime()
        {
            return this.player.getCurrentTime();
        },
        getDuration()
        {
            return this.player.getDuration();
        },
        seekTo(time)
        {
            this.player.seekTo(time);
        },
        playVideo()
        {
            this.player.playVideo();
        },
    },
    mounted()
    {
        runner.register((YouTube) =>
        {
            this.player = new YouTube.Player(this.elementId, {
                height: this.height,
                width: this.width,
                videoId: this.videoId,
                host: 'https://www.youtube-nocookie.com',
                events: {
                    onReady: (event) =>
                    {
                        this.setMute(this.mute);
                        this.$emit('ready', event);
                    },
                    onStateChange: (event) =>
                    {
                        if (event.data !== -1)
                            this.$emit(runner.events[event.data], event);
                    },
                    onError: (event) =>
                    {
                        this.$emit('error', event);
                    }
                }
            });
        });
    },
    beforeDestroy()
    {
        this.player?.destroy();
        delete this.player;
    }
};
</script>

<style lang="scss">
.youtube-player {
    iframe {
        display: block;
    }
}
</style>
