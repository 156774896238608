<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-tooltip bottom>
        <template #activator="{on,attrs}">
            <v-btn icon class="mr-3" v-on="on" v-bind="attrs" @click="openOnboardingQuestionnaire">
                <v-progress-circular :size="45" :value="trueProgress" rotate="270">
                    <span class="percent-text">{{ trueProgress }}%</span>
                </v-progress-circular>
            </v-btn>
        </template>
        <div class="d-flex flex-column align-center">
            <div>Your profile is {{ trueProgress }}% complete</div>
            <div>Click here to continue or retake the questionnaire</div>
        </div>
    </v-tooltip>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'QuestionnaireNavbarWidget',
    inject: [ 'openOnboardingQuestionnaire' ],
    computed: {
        ...mapGetters('user', [ 'profileProgress' ]),

        trueProgress()
        {
            return this.profileProgress.toFixed(0);
        }
    }
};
</script>

<style lang="scss" scoped>

.outer-ring {
    border: 2px solid white;
    border-radius: 100%;
    padding: 3px;
}

.percent-text {
    font-size: 12px;
    color: white;
    line-height: 1;
}
</style>