<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="menu-section">
        <v-divider v-if="!hide_divider"/>
        <v-subheader v-if="label !== undefined">{{ label }}</v-subheader>
        <div class="menu-section-items">
            <PDropdownMenuItem v-for="item in items" :key="item.label" v-bind="item"/>
        </div>
    </div>
</template>

<script>
import MenuSectionCommon from '@/components/Layout/Menus/MenuSectionCommon';
import PDropdownMenuItem from '@/components/Layout/Menus/DropdownMenu/PDropdownMenuItem';

export default {
    name: 'PDropdownMenuSection',
    components: { PDropdownMenuItem },
    mixins: [ MenuSectionCommon ]
};
</script>

<style scoped>

</style>