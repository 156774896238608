/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import { VuePlugin as PTAjaxVue } from './src/Ajax';
import ErrorReport from './src/ErrorReport';
import YTEmbed from './src/YTEmbed';
import VimeoEmbed from './src/VimeoEmbed';

/**
 *
 */
export default {
    install(Vue)
    {
        //Write the plugin installer here.
        Vue.use(PTAjaxVue);
        Vue.use(ErrorReport);
        Vue.use(YTEmbed);
        Vue.use(VimeoEmbed);
    }
};