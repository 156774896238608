<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div>
        <v-overlay v-if="blurOverlay" class="p-conv-overlay-main" :class="{'overlay-active': mainDialog && blurOverlay}" v-model="mainDialog" style="position: fixed;" z-index="150">
            <!-- use this overlay attach so that we only attach the generated dialog component and leave the activator in the standard document layout -->
            <div id="pConvOverlay"/>
        </v-overlay>
        <v-dialog
                v-model="mainDialog"
                :attach="blurOverlay ? '#pConvOverlay' : undefined"
                :hide-overlay="blurOverlay"
                :fullscreen="mobile"
                :width="mobile ? null : width"
                :max-width="mobile ? null : maxWidth"
                :persistent="persistent"
                :scrollable="scrollable !== false"
                :transition="mobile ? 'dialog-bottom-transition' : 'dialog-transition'"
                @keydown="checkEscape"
        >
            <template #activator="{on,attrs}">
                <slot name="activator" v-bind:on="on" v-bind:attrs="attrs"/>
            </template>
            <v-card :loading="loading">
                <v-toolbar v-if="!noToolbar" :flat="!hasExtension && !mobile" :dark="mobile" :color="mobile ? realColour : null" :max-height="hasExtension ? null : 64" :height="hasExtension ? null : 64">
                    <template #extension v-if="hasExtension">
                        <slot name="extension"/>
                    </template>
                    <v-toolbar-title>
                        <slot name="title">
                            {{ title }}
                        </slot>
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-toolbar-items>
                        <slot name="toolbar-items" v-bind:close="close" v-bind:isMobile="mobile"/>
                    </v-toolbar-items>
                </v-toolbar>
                <slot name="title" v-else-if="title !== null">
                    <v-card-title class="text-break">
                        {{ title }}
                    </v-card-title>
                </slot>
                <component :is="bodyTag" :class="{'mt-3': (mobile || hasExtension) && !noPadding, 'pa-0': noPadding }" :style="bodySize">
                    <slot v-bind:close="close"/>
                </component>
                <v-card-actions v-if="(!mobile && !forceActionToolbar) && hasActions">
                    <v-spacer v-if="!noActionSpacer"/>
                    <slot name="actions" v-bind:close="close" v-bind:isMobile="mobile"/>
                </v-card-actions>
                <v-toolbar v-else-if="(mobile || forceActionToolbar) && hasActions" min-width="100%" bottom class="action-toolbar" :style="{position: mobile ? 'fixed': 'unset'}">
                    <v-toolbar-items>
                        <slot name="actions" v-bind:close="close" v-bind:isMobile="mobile"/>
                    </v-toolbar-items>
                </v-toolbar>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { VCardText } from 'vuetify/lib';

/**
 * Base modal for the platform
 *
 * Slots:
 * - default (none): any content here is placed in the body of the modal
 * - activator (on,attrs): trigger for opening the modal from the DOM
 * - extension (none): place to put extensions of the toolbar i.e. tabs
 * - title (none) [overload]: replace the default title rendering
 * - toolbar-items (close, isMobile): add buttons to the right side of the toolbar
 * - actions (close): add buttons to the bottom of the mobile for desktop
 *
 * TODO: make scrollable scroll the modal body, not the entire modal
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'PConvertibleModal',
    components: { VCardText },
    props: {

        /**
         * The title text for the modal
         */
        title: {
            type: String,
            default: null
        },

        /**
         * Should the modal not be removed when the background is clicked?
         */
        persistent: {
            type: Boolean,
            default: false
        },

        /**
         * The max responsive width of the modal
         */
        maxWidth: {
            type: [ Number, String ],
            default: null
        },

        /**
         * The set width of the modal
         */
        width: {
            type: [ Number, String ],
            default: null
        },

        /**
         * Is the modal body scrollable?
         */
        scrollable: {
            type: [ Boolean, String ],
            default: false
        },

        /**
         * Is the modal loading?
         */
        loading: {
            type: Boolean,
            default: false
        },

        /**
         * The tag that should be used for the modal body. Defaults to 'v-card-text'.
         */
        bodyTag: {
            type: String,
            default: 'v-card-text'
        },

        /**
         * Force desktop display.
         */
        forceDesktop: {
            type: Boolean,
            default: false
        },

        /**
         * Force mobile display.
         */
        forceMobile: {
            type: Boolean,
            default: false
        },

        /**
         * Force the "action" bar to use a toolbar instead
         */
        forceActionToolbar: {
            type: Boolean,
            default: false
        },

        /**
         * Disable the toolbar and force the standard 'v-card-title' tag to be used for non-complex modals.
         */
        noToolbar: {
            type: Boolean,
            default: false
        },

        /**
         * Forced highlight colour.
         */
        colour: {
            type: String,
            default: null
        },

        /**
         * Part of the v-model for synchronisation
         */
        value: {
            type: Boolean,
            default: false
        },

        /**
         * Make the action bar start on the left
         */
        noActionSpacer: {
            type: Boolean,
            default: false
        },

        /**
         * Stop escape key closing the modal
         */
        noEscapeClose: {
            type: Boolean,
            default: false
        },

        /**
         * Blur everything behind the modal. Notice: turning this on may have unintended consequences until I figure out how to overwrite vuetify's overlayable component.
         * Known Issues:
         * - Overlay sometimes doesn't fix to full screen
         * - Close animations don't work
         */
        blurOverlay: {
            type: Boolean,
            default: false
        },

        /**
         * Disable all padding on the card.
         */
        noPadding: {
            type: Boolean,
            default: false
        },

        /**
         * Forcibly hide the action bar, even if there is something in the slot.
         */
        hideActions: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState([ 'highlight_colour' ]),

        /**
         * Should the dialog be displayed?
         *
         * Handles v-model updates.
         */
        mainDialog: {
            get: function ()
            {
                return this.value;
            },
            set: function (value)
            {
                this.$emit('input', value);
            }
        },

        /**
         * Should the dialog be displayed in mobile view?
         *
         * @returns {boolean}
         */
        mobile()
        {
            if (this.forceDesktop)
                return false;
            if (this.forceMobile)
                return true;
            return this.$vuetify.breakpoint.mdAndDown;
        },

        realColour()
        {
            return this.colour ?? this.highlight_colour;
        },

        /**
         * Is there content in the extension slot?
         *
         * @returns {boolean}
         */
        hasExtension()
        {
            return !!(this.$slots.extension || this.$scopedSlots.extension);
        },

        /**
         * Is there content in the actions slot?
         *
         * @returns {boolean}
         */
        hasActions()
        {
            if (this.hideActions)
                return false;
            return !!(this.$slots.actions || this.$scopedSlots.actions);
        },

        bodySize()
        {
            if (this.scrollable === false || this.scrollable === true)
                return {};
            return { 'max-height': this.scrollable, 'overflow-y': 'auto' };
        }

    },
    methods: {

        /**
         * Method passed to the slot scopes to close the modal.
         */
        close()
        {
            this.mainDialog = false;
        },

        checkEscape(e)
        {
            if (this.noEscapeClose)
                return;
            if (e.keyCode === 27 || e.key === 'Escape' || e.code === 'Escape')
                this.close();
        }
    }
};
</script>

<style lang="scss">
.p-conv-overlay-main.overlay-active:before {
    backdrop-filter: blur(3px);
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
}
</style>

<style lang="scss" scoped>
.action-toolbar {
    &::v-deep .v-toolbar__items {
        width: 100%;
    }
}
</style>