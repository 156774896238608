/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export class PointerType {

    constructor(name, value)
    {
        this.name = name;
        this.value = value;
    }

}

export const PointerTypes = {
    /**
     * The device is less feature complete than a TI-83
     */
    None: new PointerType('None', 'none'),

    /**
     * Steve Jobs was wrong and the best pointing device is NOT the human finger.
     */
    Coarse: new PointerType('Coarse', 'coarse'),

    /**
     * Mouse and keyboard is the best HID ever created.
     */
    Fine: new PointerType('Fine', 'fine')
};

/**
 * Describes a hover setting
 */
export class HoverType {

    constructor(name, value)
    {
        this.name = name;
        this.value = value;
    }

}

export const HoverTypes = {
    /**
     * This device can hover over an element.
     */
    Hover: new HoverType('Hover', 'hover'),

    /**
     * This device can hover on demand, such as a mobile device ruining things by pretending to hover on a long tap
     */
    OnDemand: new HoverType('OnDemand', 'on-demand'),

    /**
     * What's the problem with a mouse and keyboard?!
     */
    None: new HoverType('None', 'none')
};

/**
 * Describes a device
 */
export class DeviceType {

    /**
     * Create DeviceType
     *
     * @param name {string}
     * @param pointer {PointerType}
     * @param hover {HoverType}
     * @param isUnknown {boolean}
     */
    constructor(name, pointer, hover, isUnknown = false)
    {
        this.name = name;
        this.pointer = pointer;
        this.hover = hover;
        this.isUnknown = isUnknown;
    }

}

export const DeviceTypes = {

    /**
     * Touch device
     */
    Touch: new DeviceType('Touch', PointerTypes.Coarse, HoverTypes.None),

    /**
     * Drawing tablets
     */
    Stylus: new DeviceType('Stylus', PointerTypes.Fine, HoverTypes.None),

    /**
     * Stuff like a Wiimote, Kinect, VR controller
     */
    PhysicalHID: new DeviceType('PhysicalHID', PointerTypes.Coarse, HoverTypes.Hover),

    /**
     * The best HID device
     */
    Mouse: new DeviceType('Mouse', PointerTypes.Fine, HoverTypes.Hover),

    Unknown: new DeviceType('Unknown', PointerTypes.None, HoverTypes.None, true)

};

/**
 * Check the pointer query.
 *
 * @param type {PointerType} the type of pointing device we are checking for
 * @param any {boolean} false = primary device, true = any device
 * @returns {null|boolean} null = check not supported (probably an apple device), boolean = the capability
 */
export function pointer(type, any = false)
{
    if(!window.matchMedia)
        return null;
    return matchMedia(`(${ any ? 'any-' : '' }pointer: ${ type.value })`).matches;
}

/**
 * Check the hover query
 * @param type {HoverType} the type of hover that is capable by the system
 * @param any {boolean} false = primary device, true = any device
 * @returns {null|boolean} null = check not supported (probably an apple device), boolean = the capability
 */
export function hover(type, any = false)
{
    if(!window.matchMedia)
        return null;
    return matchMedia(`(${ any ? 'any-' : '' }hover: ${ type.value })`).matches;
}

/**
 * Breakpoint on a specific screen width
 * @param amt the width in any CSS unit
 * @param above false = below true = above
 * @returns {null|boolean} null = not supported (probably an apple device), boolean = the condition
 */
export function screenWidth(amt, above = false)
{
    if(!window.matchMedia)
        return null;
    return matchMedia(`(${ above ? 'min' : 'max' }-width: ${ amt })`).matches;
}

export function wantsDarkMode()
{
    if(!window.matchMedia)
        return null;
    return matchMedia('(prefers-colour-scheme: dark)').matches;
}

/**
 * Get the estimated device type
 *
 * @returns {DeviceType}
 */
export function getDeviceType()
{
    for(let x in DeviceTypes)
    {
        let dev = DeviceTypes[x];
        if(dev.isUnknown)
            continue;
        if(pointer(dev.pointer) && hover(dev.hover))
            return dev;
    }
    return DeviceTypes.Unknown;
}

export default {
    pointer,
    hover,
    screenWidth,
    getDeviceType,
    wantsDarkMode,
    DeviceType,
    DeviceTypes,
    PointerType,
    PointerTypes,
    HoverType,
    HoverTypes
};