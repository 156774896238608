<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->
<!--
  - Simple component for creating a blurred fullscreen overlay
  - Has the option to accept an element
  -->
<template>
    <div class="pt-overlay">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'PPageBlur'
}
</script>

<style lang="scss">
    .pt-overlay{
        backdrop-filter: blur(4px) saturate(0.5) grayscale(.5);
        width: 100%;
        height: 100%;
        z-index: 110;
        top: 0;
        left: 0;
        position: fixed;
    }
</style>
