/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import { Utilities } from '@/router';

function loadRoutes()
{
    const context = require.context(__dirname, true, /routes.child.js$/i);
    return Utilities.ProcessRoutes(context);
}

export default [
    {
        path: '/dashboard/:tabId?',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard"*/ '@/views/Dashboard/DashboardMain'),
        props: route => ({ tabId: !isNaN(Number(route.params.tabId)) ? Number(route.params.tabId) : ((typeof route.params.tabId) === 'string' ? route.params.tabId : -1) }),
        meta: {
            colour()
            {
                return '147abe';
            },
            icon()
            {
                return '$dashboard';
            }
        },
        children: [
            ...loadRoutes()
        ]
    }
];