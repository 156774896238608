<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="focus-trap" ref="base">
        <slot/>
    </div>
</template>

<script>
/**
 * Trap keyboard focus within an area.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 * @created 17/12/2020
 */
export default {
    name: 'PFocusTrap',
    props: {
        /**
         * Start/stop the trap without explicitly destroying it
         */
        active: {
            type: Boolean,
            default: false
        }
    },
    data()
    {
        return {
            /**
             * Storage for the trap listener, just so each trap listener is unique and doesn't remove other trap listeners.
             */
            trapListener: null
        };
    },
    mounted()
    {
        //Create the trap listener in the context of this specific focus trap.
        this.trapListener = (e) =>
        {
            if(!this.active)
                return;

            //Emit an escape event if we press escape.
            let isEscPressed = e.key === 'Escape' || e.keyCode === 27;
            if(isEscPressed)
            {
                this.$emit('escape');
                return;
            }

            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if(!isTabPressed)
                return;

            //Use a bit of recursion to select the focus trap
            let parentTest = (ele) =>
            {
                let parent = ele.parentElement;
                if(parent === null)
                    return false;
                if(parent.className === 'focus-trap')
                    return true;
                return parentTest(parent);
            };

            //Get all focusable elements in the focus trap
            const focusables = this.$refs.base.querySelectorAll('button, [href], input, select, textara, [tabindex]:not([tabindex="-1"])');
            const firstEle = focusables[0];
            const lastEle = focusables[focusables.length - 1];

            //If we aren't focused in the focus trap, focus the first focusable item in the focus trap.
            if(!parentTest(e.target))
            {
                firstEle.focus();
                e.preventDefault();
                return;
            }

            //If holding shift, go backwards.
            if(e.shiftKey)
            {
                if(document.activeElement === firstEle)
                {
                    lastEle.focus();
                    e.preventDefault();
                }
            }
            else
            {
                if(document.activeElement === lastEle)
                {
                    firstEle.focus();
                    e.preventDefault();
                }
            }
        };
        document.addEventListener('keydown', this.trapListener);
    },
    destroyed()
    {
        //Remove the listener from the DOM on destroy because it's not useful anymore.
        document.removeEventListener('keydown', this.trapListener);
    }
};
</script>