/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

/**
 * Ajax
 *
 * @author T J Collinson <tom.collinson@plus-t.co.uk>
 *
 * Wrapper for Axios that automatically handles +t JSON schema
 */
import Axios from 'axios';
import qs from 'qs';
import HTTPVerbs from './HTTPVerbs';

let codes = [];

function PtAjax(options)
{
    /*
     * Function to check the returned code
     * against the list of provided 'success' codes
     */
    function checkCode(code)
    {
        code = code || false;
        if (!code) return false;
        if (!('success' in options)) return false;
        if (!('code' in options.success)) return false;
        if (Array.isArray(options.success.code))
        {
            //An array of possible codes was set - loop through them
            for (let i = 0, j = options.success.code.length; i < j; i++)
            {
                if (code === options.success.code[i]) return true;
            }
        }
        else
        {
            //A single code was set, simple check against this code
            if (code === options.success.code) return true;
        }
        //No match
        return false;
    }

    /**
     * Obtains the response message from the response JSON
     * @param {*} response
     */
    function getMessage(response)
    {
        //Response map - locate the desired param in the JSON response
        let responsemap = options.success.responsemap.split('/');
        let message;
        //Loop through the response JSON and find the requested param
        for (let i = 0, j = responsemap.length, temp; i < j; i++)
        {
            if (i === 0) temp = response;
            if (!(responsemap[i] in temp)) return options.success.message;
            temp = temp[responsemap[i]];
            if (i === j - 1) message = temp;
        }

        return message;
    }

    /**
     * Obtain the code from the response
     * @param {*} response
     */
    function getCodeMap(response)
    {
        let codemap = options.success.codemap.split('/');
        let code;
        //Loop through the response JSON and find the requested param
        for (let i = 0, j = codemap.length, temp; i < j; i++)
        {
            if (i === 0) temp = response;
            if (!(codemap[i] in temp)) throw new Error('The code map ' + options.success.codemap + ' does not match the response schema at ' + codemap[i]);
            temp = temp[codemap[i]];
            if (i === j - 1) code = temp;
        }
        return code;
    }

    /**
     * Generates inner html for notifications
     * @param {*} type => enum(error|success)
     * @param {*} title => String
     * @param {*} message => Mixed, Array|String
     */
    let notifyInner = (type, title, message) =>
    {
        //Generate message html
        let msg = '';
        if (Array.isArray(message))
        {
            for (let i = 0, j = message.length; i < j; i++)
            {
                msg += `<p>${ message[i] }</p>`;
            }
        }
        else msg = message;
        return `<div class="snotifyToast__title">${ title }</div>
            <div class="snotifyToast__body">
                ${ msg }
            </div>
            <div class="snotify-icon snotify-icon--${ type }"></div>`;
    };

    /**
     * notySuccess
     * Loads a scuccess notification
     * @param {*} opt
     *
     */
    let notySuccess = (opt) =>
    {
        if (options.silent.success) return;
        opt = opt || {};
        opt.message = opt.message || options.success.message;
        opt.title = opt.title || options.success.title;
        let success_details = options.success.toast;
        success_details.html = notifyInner('success', opt.title, opt.message);
        window.PTVue.$snotify.success('', '', success_details);
    };

    /**
     * notyError
     * @param {*} opt
     * Loads an error notification
     */
    let notyError = (opt, r) =>
    {
        /**
         * If the error was generated elsewhere,
         * throw it back
         */
        if (!('response' in r))
        {
            throw r;
        }
        if (options.silent.error) return;
        opt = opt || {};
        opt.message = opt.message || r.response.data.message || options.error.message;
        opt.title = opt.title || `Error (code ${ r.response.data.code })` || options.error.title;
        let error_details = options.error.toast;
        error_details.html = notifyInner('error', opt.title, opt.message);
        window.PTVue.$snotify.error('', '', error_details);
    };

    /**
     * Get the default codes, previously obtained from the server
     * Store will be undefined if running the ajax from the store itself, so then just use "this".
     */
    codes = window.PTVueStore !== undefined ? window.PTVueStore.state.codes : codes;
    if (typeof options !== 'object') throw new Error('The ajax function requires an object as its first and only argument');
    if (!('url' in options)) throw new Error('The options parameter must contain a url');
    //Set defaults for success object
    if ('success' in options)
    {
        if ('run' in options.success)
        {
            if (typeof options.success.run !== 'function') throw new Error('The success param must be a function');
        }
        //Default success code to 200
        options.success.code = options.success.code || 200;
        //Default codemap to "code"
        options.success.codemap = options.success.codemap || 'code';
        //Default responsemap to "message"
        options.success.responsemap = options.success.responsemap || 'message';
        //Default toast
        if (!('toast' in options.success))
        {
            options.success.toast = {};
        }
        options.success.message = options.success.message || [];
        options.success.title = options.success.title || codes.success_title;
    }
    else
    {
        options.success = {
            toast: {},
            message: [],
            title: codes.success_title,
            code: 200,
            codemap: 'code'
        };
    }
    //Set defaults for error object
    if ('error' in options)
    {
        if ('run' in options.error)
        {
            if (typeof options.error.run !== 'function') throw new Error('The error param must be a function');
        }
        if (!('toast' in options.error))
        {
            options.error.toast = {};
        }
        options.error.message = options.error.message || codes.generic_error;
        options.error.title = options.error.title || codes.error_title;
    }
    else
    {
        //Create empty error => toast
        options.error = {
            toast: {},
            message: codes.generic_error,
            title: codes.error_title
        };
    }

    /**
     * Set defaults for 'silent'
     * This tells us whether or not a toast should be displayed
     */
    if (!('silent' in options)) options.silent = { success: false, error: false };
    if (typeof options.silent == 'boolean')
    {
        options.silent = {
            success: options.silent,
            error: options.silent
        };
    }

    // Sets default for loading object
    if ('loading' in options)
    {
        if (typeof options.loading === 'object')
        {
            if (!('toast' in options.loading))
            {
                options.loading.toast = {};
            }
            //Set default toaster settings
            options.loading.message = options.loading.message || 'Please wait...';
            options.loading.title = options.loading.title || 'Loading';
            options.loading.toast.timeout = options.loading.toast.timeout || 0;
            options.loading.toast.position = options.loading.toast.position || 'centerCenter';
            options.loading.toast.backdrop = options.loading.toast.backdrop || 0.5;
            if (options.loading.toast.backdrop == 'none') delete options.loading.toast.backdrop;
            options.loading.toast.closeOnClick = ('closeOnClick' in options.loading.toast) ? options.loading.toast.closeOnClick : false;
            options.loading.toast.html = notifyInner('async', options.loading.message, options.loading.title);
        }
        else options.loading = false;
    }
    else
    {
        options.loading = false;
    }

    /**
     * Set default toasts
     */
    //Error
    options.error.toast.timeout = options.error.toast.timeout || 0;
    options.error.toast.position = options.error.toast.position || 'centerCenter';
    options.error.toast.backdrop = options.error.toast.backdrop || 0.5;
    if (options.error.toast.backdrop == 'none') delete options.error.toast.backdrop;
    options.error.toast.closeOnClick = ('closeOnClick' in options.error.toast) ? options.error.toast.closeOnClick : false;
    options.error.toast.buttons = options.error.toast.buttons || [
        {
            text: 'OK',
            action: (toast) =>
            {
                window.PTVue.$snotify.remove(toast.id);
            }
        }
    ];
    //Success
    options.success.toast.timeout = options.success.toast.timeout || 0;
    options.success.toast.position = options.success.toast.position || 'centerCenter';
    options.success.toast.backdrop = options.success.toast.backdrop || 0.5;
    if (options.success.toast.backdrop == 'none') delete options.success.toast.backdrop;
    options.success.toast.closeOnClick = ('closeOnClick' in options.success.toast) ? options.success.toast.closeOnClick : false;
    options.success.toast.buttons = options.success.toast.buttons || [
        {
            text: 'OK',
            action: (toast) =>
            {
                window.PTVue.$snotify.remove(toast.id);
            }
        }
    ];

    //Default content
    options.contentType = options.contentType || 'application/x-www-form-urlencoded; charset=UTF-8';
    //Default the type to post
    options.type = options.type || HTTPVerbs.POST;
    options.raw = options.raw || false;
    if (options.raw) options.contentType = 'application/json';
    //Default the ubnauthorised check to true
    options.auth_check = options.auth_check || true;

    /**
     * This checks that the version received from the server matches that stored in the app
     * If not, then we emit an event to tell Vue that the app is out of date
     *
     * @param {*} r
     */
    let check_version = (r) =>
    {
        if (('version' in r) && ('VUE_APP_VERSION' in process.env))
        {
            if (r.version !== process.env.VUE_APP_VERSION)
            {
                window.PTVue.$emit('outOfDate', r.version);
            }
        }
    };

    /**
     * This checks if the received code from the API matches our 'unauthorised' code
     * If so, we should inform Vue such that it can check if the user is logged in
     *
     * @param {*} r
     */
    let check_authed = (r) =>
    {
        if (!r) return true;
        if (r?.response?.status === window.PTVue.$store.getters['constants/all']?.API.UNAUTHORISED.CODE)
        {
            window.PTVue.$emit('unauthorised');
            return false;
        }
        return true;
    };

    /**
     * Check if site is in maintenance mode
     *
     * @param {*} r
     * @returns bool
     */
    let maintenance_mode = (r) =>
    {
        if (!r) return false;
        if (r?.code === window.PTVue.$store.getters['constants/all']?.API.MAINTENANCE.CODE)
        {
            if (r?.code !== undefined)
            {
                window.location.reload();
            }
        }
        return false;
    };

    let forbidden = (r, code) =>
    {
        let data = r?.response?.data;
        //TODO: stop using the response message to determine forbidden state
        if (code === 403 && data?.message?.indexOf('access') >= 0)
        {
            window.PTVue.$emit('forbidden');
            return false;
        }
    };

    /**
     * handle_response
     * Handles successful server responses
     * @param {*} r The decoded json response from the server
     */
    let handle_response = (r) =>
    {
        if (options.loader !== null) window.PTVue.$snotify.remove(options.loader.id);

        r = r.data;
        //Check we have the right application version
        check_version(r);
        //Message flag
        let message = false, code = false;
        //Check for success options
        if ('success' in options)
        {
            /*
                * Parse the response where necessary
                */
            code = getCodeMap(r);
            if ('run' in options.success)
            {
                //If code+codemap sent then check the status in the JSON before running function
                if (!checkCode(code))
                {
                    try
                    {
                        message = getMessage(r);
                    }
                    catch
                    {
                        message = options.error.message;
                    }
                    options.error.title = options.error.title + ' (code ' + code + ')';
                    options.error.message = message;
                    throw r;
                }
                else
                {
                    //Success so run the success function
                    options.success.run(r);
                    //Noty message should be run even with callback
                    let _message;
                    try
                    {
                        _message = getMessage(r);
                    }
                    catch
                    {
                        _message = options.success.message;
                    }
                    notySuccess({
                        title: options.success.title,
                        message: _message
                    });
                }
            }
            else if ('responsemap' in options.success)
            {
                message = getMessage(r);
                if (('code' in options.success) && ('codemap' in options.success))
                {
                    //If this code is not options.success.code, use setError
                    if (!checkCode(code))
                    {
                        options.error.title = options.error.title + ' (code ' + code + ')';
                        options.error.message = message;
                        throw r;
                    }
                }

            }
            //Simple response message, just set and display success
            else if ('message' in options.success) message = options.success.message;
            if (typeof message !== 'boolean')
            {
                notySuccess({
                    title: options.success.title,
                    message: message
                });
            }
        }

        //Run the generic complete function
        if ('complete' in options && typeof options.complete === 'function') options.complete();
    };

    let handle_error = (r) =>
    {
        if (options.loading !== false) window.PTVue.$snotify.remove(options.loader.id);
        //Check if in maintenance mode
        if (maintenance_mode(r)) return;
        if (forbidden(r, r?.response?.status)) return;
        //Check that we have the correct application version
        check_version(r);
        //Check that the user is authorised

        //Run provided error function
        if ('run' in options.error) options.error.run(r);

        if (options.auth_check)
            if (!check_authed(r)) return;
        //Display provided error message
        notyError(null, r);
        //Run the generic complete function
        if ('complete' in options && typeof options.complete === 'function') options.complete();
    };

    if (('data' in options) && typeof options.data === 'object' && !options.raw)
    {
        options.data = qs.stringify(options.data);
    }

    let headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': options.contentType
    };

    options.axios_config = options.axios_config ?? {};

    let axios_options = {
        url: options.url,
        method: options.type.toLowerCase(),
        headers: headers,
        ...options.axios_config
    };

    if (options.type.toLowerCase() === HTTPVerbs.POST && ('data' in options)) axios_options.data = options.data;

    if (options.loading !== false)
    {
        options.loader = window.PTVue.$snotify.info('', '', options.loading.toast);
    }
    else options.loader = null;

    return Axios(axios_options).then(handle_response).catch(handle_error);

}

export default PtAjax;

export const VuePlugin = {
    install(Vue)
    {
        Vue.prototype.$ptAjax = PtAjax;
    }
};
