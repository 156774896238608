/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default [
    {
        name: 'Medical History',
        path: '/medical-history',
        component: () => import(/* webpackChunkName: "personal"*/ '@/views/Fake/FakeImage'),
        props: {
            image: '/assets/images/example/medical-history.jpg',
            alt: 'Medical History Example'
        },
        meta: {
            colour()
            {
                return '84bc26';
            },
            title()
            {
                return 'Medical History';
            },
            icon()
            {
                return 'plust-icon';
            }
        }
    },
    {
        name: 'Leave Records',
        path: '/leave-records',
        component: () => import(/* webpackChunkName: "personal"*/ '@/views/Fake/FakeImage'),
        props: {
            image: '/assets/images/example/leave-records.jpg',
            alt: 'Medical History Example'
        },
        meta: {
            colour()
            {
                return '3052A0';
            },
            title()
            {
                return 'Leave Records';
            },
            icon()
            {
                return 'plust-icon';
            }
        }
    },
    {
        name: 'Pay History',
        path: '/pay-history',
        component: () => import(/* webpackChunkName: "personal"*/ '@/views/Fake/FakeImage'),
        props: {
            image: '/assets/images/example/pay-history.jpg',
            alt: 'Medical History Example'
        },
        meta: {
            colour()
            {
                return '1376A8';
            },
            title()
            {
                return 'Pay History';
            },
            icon()
            {
                return 'plust-icon';
            }
        }
    },
    {
        name: 'Service History',
        path: '/service-history',
        component: () => import(/* webpackChunkName: "personal"*/ '@/views/Fake/FakeImage'),
        props: {
            image: '/assets/images/example/service-history.jpg',
            alt: 'Medical History Example'
        },
        meta: {
            colour()
            {
                return '3052A0';
            },
            title()
            {
                return 'Service History';
            },
            icon()
            {
                return 'plust-icon';
            }
        }
    }
];