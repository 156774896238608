<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="mt-3" v-if="isVisible">
        <div class="d-flex align-center mb-3">
            <v-divider/>
            <h1 class="mx-5" v-html="label"></h1>
            <v-divider/>
        </div>
        <div v-if="preamble !== undefined && preamble !== null && preamble.length > 0" class="mb-3">
            <p v-for="(line,index) in preamble" :key="index" class="my-0 text--secondary" v-html="line"></p>
        </div>

        <component v-for="(question,questionIndex) in orderDisplayQuestions(filterDisplayedQuestions(questions))" :key="questionIndex" v-bind="question" :question-number="questionIndex + 1" :is="componentLookup[question.type]" v-model="question.answer"/>
    </div>
</template>
<script>
import BasicQuestion from '@/views/Questionnaire/QuestionViews/BasicQuestion';
import QuestionGroup from '@/views/Questionnaire/QuestionViews/QuestionGroup';

export default {
    name: 'QuestionnaireSubsection',
    components: { BasicQuestion, QuestionGroup },
    inject: [ 'filterDisplayedQuestions' ],
    props: {
        id: {
            type: Number
        },
        label: {
            type: String
        },
        preamble: {
            type: Array,
            default: () => ([])
        },
        questions: {
            type: Array,
            default: () => ([])
        },
        displayOrder: {
            type: Number
        }
    },
    computed: {
        isVisible()
        {
            return this.filterDisplayedQuestions(this.questions).length > 0;
        }
    },
    data: () => ({
        componentLookup: {
            1: 'BasicQuestion',
            2: 'QuestionGroup'
        }
    }),
    methods: {
        orderDisplayQuestions(questions)
        {
            return questions.sort((a, b) => a.displayOrder - b.displayOrder);
        }
    }
}
;
</script>
