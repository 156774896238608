/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default {
    Equals: 0,
    NotEquals: 1,
    LessThan: 2,
    GreaterThan: 3,
    LessThanOrEqual: 4,
    GreaterThanOrEqual: 5
};