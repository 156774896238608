/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import PtAjax from '@plust/client-common/src/Ajax';

/**
 * This Vuex module contains all of server state flags.
 */
export default {
    namespaced: true,
    state: () => ({
        demo: false,
        development: false
    }),
    mutations: {
        setInitialState(state, data)
        {
            Object.assign(state, data);
        }
    },
    actions: {
        async loadInitialState({ commit })
        {
            await PtAjax({
                url: '/data/flags',
                type: 'get',
                silent: { success: true },
                success: {
                    run: (data) =>
                    {
                        commit('setInitialState', data.body.flags);
                    }
                }
            });
        }
    },
    getters: {
        all: (state) => state
    }
};