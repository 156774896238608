/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default [
    {
        name: 'Settings',
        path: '/settings/:section?/:subView?',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsMain'),
        props: route => ({ section: route.params.section ?? 'general', subView: route.params.subView }),
        meta: {
            icon()
            {
                return '$settings';
            }
        }
    }
];