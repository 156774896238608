<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div v-if="this.flags.demo || this.flags.development" class="lorem-ipsum" v-html="ipsum"/>
</template>

<script>
import { mapState } from 'vuex';

/**
 * Basic component that generates lorem ipsum on demand. Used for blockouts.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'PLoremIpsum',
    props: {
        maxSentencePerPara: {
            type: Number,
            default: 8
        },
        minSentencePerPara: {
            type: Number,
            default: 4
        },
        maxWordsPerSentence: {
            type: Number,
            default: 16
        },
        minWordsPerSentence: {
            type: Number,
            default: 4
        },
        num: {
            type: Number,
            required: true
        }
    },
    computed: {
        ...mapState([ 'flags' ])
    },
    data()
    {
        return {
            ipsum: ''
        };
    },
    async mounted()
    {
        let LoremIpsum = await import(/* webpackMode: "lazy" */ 'lorem-ipsum').then(mod => mod.LoremIpsum);
        const lorem = new LoremIpsum({
            sentencesPerParagraph: {
                max: this.maxSentencePerPara,
                min: this.minSentencePerPara
            },
            wordsPerSentence: {
                max: this.maxWordsPerSentence,
                min: this.minWordsPerSentence
            }
        }, 'html');
        this.ipsum = lorem.generateParagraphs(this.num);
    }
};
</script>
