/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

/*
To add:
content-changed
language
play
rewind
time-zone
 */

let systemIcons = {
    'logout': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/logout.svg?inline'),
    'change-password': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/change-password.svg?inline'),
    'edit-pencil': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/edit-pencil.svg?inline'),
    'edit-confirm': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/edit-confirm.svg?inline'),
    'edit-cancel': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/edit-cancel.svg?inline'),
    'play': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/play.svg?inline'),
    'rewind': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/rewind.svg?inline')
};

let moduleIcons = {
    'module-community': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/module/community.svg?inline'),
    'module-education': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/module/education.svg?inline'),
    'module-employment': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/module/employment.svg?inline'),
    'module-finances': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/module/finances.svg?inline'),
    'module-forum': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/module/forum.svg?inline'),
    'module-health': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/module/health.svg?inline'),
    'module-housing': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/module/housing.svg?inline'),
    'module-resources': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/module/resources.svg?inline'),
    'module-children': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/module/children.svg?inline'),
    'module-wellbeing': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/module/wellbeing.svg?inline'),

    'q-children': () => import(/*webpackChunkName: "icons" */ '@/assets/icons/questionnaire/q-children.svg?inline'),
    'q-employment': () => import(/*webpackChunkName: "icons" */ '@/assets/icons/questionnaire/q-employment.svg?inline'),
    'q-health': () => import(/*webpackChunkName: "icons" */ '@/assets/icons/questionnaire/q-health.svg?inline'),
    'q-housing': () => import(/*webpackChunkName: "icons" */ '@/assets/icons/questionnaire/q-housing.svg?inline'),
    'q-wellbeing': () => import(/*webpackChunkName: "icons" */ '@/assets/icons/questionnaire/q-wellbeing.svg?inline'),
    'q-finances': () => import(/*webpackChunkName: "icons" */ '@/assets/icons/questionnaire/q-finance.svg?inline')
};

let settingsIcons = {
    'settings': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings/settings.svg?inline'),
    'settings-about': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings/about.svg?inline'),
    'settings-accessibility': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings/accessibility.svg?inline'),
    'settings-appearance': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings/appearance.svg?inline'),
    'settings-communication': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings/communication.svg?inline'),
    'settings-general': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings/general.svg?inline'),
    'settings-help': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings/help.svg?inline'),
    'settings-kitbag': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings/kitbag.svg?inline'),
    'settings-privacy': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings/privacy.svg?inline'),
    'settings-security': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings/security.svg?inline'),
    'settings-sub-accounts': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings/sub-accounts.svg?inline')
};

let kitBagIcons = {
    'audio': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/audio.svg?inline'),
    'documents': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/documents.svg?inline'),
    'passport': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/passport.svg?inline'),
    'video': () => import (/* webpackChunkName: "icons" */ '@/assets/icons/video.svg?inline')
};

let contentIcons = {
    'content-completed': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/content-completed.svg?inline'),
    'content-in-progress': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/content-in-progress.svg?inline'),
    'content-redo': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/content-redo.svg?inline')
};

let formIcons = {
    'form-account-type': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/forms/account-type.svg?inline'),
    'form-dob': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/forms/dob.svg?inline'),
    'form-email': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/forms/email.svg?inline'),
    'form-name': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/forms/name.svg?inline'),
    'form-phone': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/forms/phone.svg?inline'),
    'form-sub-account-relationship': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/forms/sub-account-relationship.svg?inline')
};

let moodIcons = {
    'mood-good': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/green-thumb-icon-1.svg?inline'),
    'mood-ok': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/amber-thumb-icon-1.svg?inline'),
    'mood-bad': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/red-thumb-icon-1.svg?inline'),
    'mood-v-bad': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/black-thumb-icon-1.svg?inline')
};

export default {
    'pt-logo-small': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/plust-icon.svg?inline'),
    'cross': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/cross.svg?inline'),
    'locked': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/locked.svg?inline'),
    'unlocked': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/unlocked.svg?inline'),
    'avatar': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/avatar.svg?inline'),
    'camera': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/camera.svg?inline'),
    'search': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/search.svg?inline'),
    'dashboard': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/dashboard.svg?inline'),
    'costs-apply': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/costs-apply.svg?inline'),
    'calendar': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/calendar.svg?inline'),
    'help': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/help.svg?inline'),
    'emergency-help': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/emergency-help.svg?inline'),
    'feedback': () => import(/* webpackChunkName: "icons" */ '@/assets/icons/feedback.svg?inline'),
    'mood-tracker-alt' : () => import(/* webpackChunkName: "icons" */ '@/assets/icons/mood-tracker-alt.svg?inline'),
    'feedback-alt' : () => import(/* webpackChunkName: "icons" */ '@/assets/icons/feedback-alt.svg?inline'),
    'kit-bag-alt' : () => import(/* webpackChunkName: "icons" */ '@/assets/icons/kit-bag-alt.svg?inline'),
    'logout-alt' : () => import(/* webpackChunkName: "icons" */ '@/assets/icons/logout-alt.svg?inline'),
    'settings-alt' : () => import(/* webpackChunkName: "icons" */ '@/assets/icons/settings-alt.svg?inline'),
    ...kitBagIcons,
    ...systemIcons,
    ...contentIcons,
    ...moduleIcons,
    ...settingsIcons,
    ...formIcons,
    ...moodIcons
};