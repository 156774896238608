/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default [
    {
        path: '/calendar/:eventId?',
        name: 'Calendar',
        component: () => import(/* webpackChunkName: "calendar"*/ '@/views/Calendar/CalendarMain'),
        props: route => ({ eventId: route.params.eventId !== null ? Number(route.params.eventId) : null }),
        meta: {
            colour()
            {
                return '9a9da0';
            },
            icon()
            {
                return '$calendar';
            }
        }
    }
];