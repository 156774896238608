/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

/**
 * Holds the event listener group for each element
 */
class EventListenerPair {
    
    /**
     * @param onEnter {Function} the mouse enter event
     * @param onExit {Function} the mouse exit event
     */
    constructor(onEnter, onExit)
    {
        this.onEnter = onEnter;
        this.onExit = onExit;
    }
    
}

/**
 * Holds a list of listeners for this directive
 * @type {WeakMap<HTMLElement, EventListenerPair>}
 */
const listeners = new WeakMap();

/**
 * Apply listeners to an HTMLElement
 *
 * @param el {HTMLElement} the element to apply to
 * @param onEnter {Function} the mouse enter event
 * @param onExit {Function} the mouse exit event
 */
function applyListeners(el, onEnter, onExit)
{
    listeners.set(el, new EventListenerPair(onEnter, onExit));
    el.addEventListener('mouseenter', onEnter);
    el.addEventListener('mouseleave', onExit);
}

/**
 * Detach listeners from an HTMLElement
 *
 * @param el {HTMLElement} the element to remove from
 */
function detachListeners(el)
{
    let elp = listeners.get(el);
    el.removeEventListener('mouseenter', elp.onEnter);
    el.removeEventListener('mouseleave', elp.onExit);
    listeners.delete(el);
}

/**
 * Sets a value inside the vnode context property.
 *
 * @param Vue {Vue} the vue object
 * @param vnode {VNode} the virtual Vue compiler node
 * @param prop {string} A path to a property on the VNode
 * @param value {any} The value to that property
 */
function setVNodeContext(Vue, vnode, prop, value)
{
    let propBase = vnode.context.$data;
    let propParts = prop.split('.');
    let propName = propParts.splice(propParts.length - 1)[0];
    for(let x of propParts)
        if(propBase[x] !== undefined)
            propBase = propBase[x];
    Vue.set(propBase, propName, value);
}

export default {
    install(Vue)
    {
        
        /**
         * Vue directive to automatically update a value when the element is hovered.
         *
         * Default property is "hovered".
         * Setting directive equal to something means custom property.
         * Providing ".delay" modifier prevents initial hover check.
         */
        Vue.directive('pt-hover', {
            inserted(el, binding, vnode)
            {
                
                if(listeners.has(el))
                {
                    console.warn('Attempting to apply hover directive to element that already has one.', el);
                    return;
                }
                
                const onEnter = () => setVNodeContext(Vue, vnode, binding.expression ?? 'hovered', true);
                const onExit = () => setVNodeContext(Vue, vnode, binding.expression ?? 'hovered', false);
                
                applyListeners(el, onEnter, onExit);
                
                //if property is declared as "v-pt-hover.delay" then don't check if the mouse is already hovered
                if(!binding.modifiers.delay)
                {
                    setVNodeContext(Vue, vnode, binding.expression ?? 'hovered', el.matches(':hover'));
                }
            },
            
            unbind(el)
            {
                if(!listeners.has(el))
                    return;
                
                detachListeners(el);
            }
        });
        
    }
};