/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */
export default class {
    static GET    = 'get';
    static POST   = 'post';
    static PUT    = 'put';
    static DELETE = 'delete';
    static PATCH  = 'patch';
}

