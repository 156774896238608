<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="user-menu">

        <PConvertibleModal v-model="dialogs.log_out" persistent title="Log Out" max-width="290" force-desktop no-toolbar>
            Are you sure you want to log out now?

            <template #actions="{close}">
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="performLogOut">Log Out</v-btn>
            </template>
        </PConvertibleModal>

        <PDropdownMenu :items="dropdown_items">
            <template v-slot:activator="{ on, attrs }">
                <div v-ripple class="user-button-container" v-bind="attrs" v-on="on" tabindex="0">
                    <v-avatar min-height="45" min-width="45" max-width="45" max-height="45">
                        <template v-if="user.avatar === '/assets/icons/avatar.svg'" #default>
                            <v-icon size="100%">$avatar</v-icon>
                        </template>
                        <template v-else #default>
                            <v-img :src="user.avatar" eager height="45" width="45" alt="Avatar"/>
                        </template>
                    </v-avatar>
                    <v-icon>mdi-chevron-down</v-icon>
                    <div class="user-name">
                        {{ user.first_name }} {{ user.last_name }}
                    </div>
                </div>
            </template>
        </PDropdownMenu>

        <portal to="supermenu-usermenu">
            <PDropdownMenu :items="dropdown_items" :portalled-func="portalCall">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </template>
            </PDropdownMenu>
        </portal>

        <portal to="open_feedback_text">
            <v-btn dark color="blue" @click="dialogs.feedback = true;">Send Feedback</v-btn>
        </portal>

    </div>
</template>

<script>
import PDropdownMenu from '@/components/Layout/Menus/DropdownMenu/PDropdownMenu';
import { mapState } from 'vuex';

/**
 * The user menu for providing quick user management options.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'UserMenu',
    components: { PDropdownMenu },
    inject: [ 'setGlobalModalState' ],
    computed: {
        ...mapState([ 'user' ])
    },
    data()
    {
        return {
            /**
             * Failure state
             */
            failed: false,

            /**
             * Loading state
             */
            isLoading: true,

            dropdown_items: [],

            dialogs: {
                log_out: false
            }
        };
    },
    mounted()
    {
        this.$ptAjax({
            url: '/data/menu/user',
            type: 'get',
            silent: true,
            error: {
                run: () =>
                {
                    this.isLoading = false;
                    this.failed = true;
                }
            },
            success: {
                run: (r) =>
                {
                    this.isLoading = false;
                    this.dropdown_items = r.body.menu;
                }
            }
        });
    },
    methods: {
        /**
         * Open the feedback modal.
         */
        openFeedback()
        {
            this.setGlobalModalState('feedback', true);
        },

        portalCall(name, props)
        {
            this[name].call(this, props);
        },

        /**
         * Called when the user wants to log-out.
         */
        confirmLogOut()
        {
            this.dialogs.log_out = true;
        },

        performLogOut()
        {
            this.$ptAjax({
                url: '/data/auth/logout',
                type: 'post',
                silent: { success: true },
                success: {
                    run: () =>
                    {
                        this.$store.commit('set_secure_initialisation_state', false);
                        this.$store.commit('set_initialisation_state', false);
                        this.$store.dispatch('clearSecureStorage');
                        this.$router.push({ path: '/' });
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.user-button-container {
    display: flex;
    align-items: center
}

.user-menu {

    img {
        max-height: 100%;
    }

    .user-name {
        font-weight: bold;
        color: white;
        font-size: .88rem;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

}
</style>