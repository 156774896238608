/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

/**
 * Common functions that implement the standard MenuAPI at the base level.
 */
let Base = {
    provide()
    {
        return {
            'handleItemInvoke': this.handleItemInvoke,
            'handleItemModalInvoke': this.handleItemModalInvoke
        };
    }
};

/**
 * Common functions that implement the standard MenuAPI at the base level. Uses item prop. Meant for wrapped instances.
 */
export let MenuCommonProp = {
    mixins: [ Base ],
    inject: [ 'supermenu' ],
    props: {
        /**
         * A list of sections containing items to display in the menu.
         */
        items: {
            type: Array,
            default: () => ([])
        },

        /**
         * This one's a bunch of fun.
         *
         * So, in order to not duplicate the usermenu component entirely, a small part of it is portalled over to the supermenu for the cog.
         * Only problem is, that changes the $parent, despite technically belonging to the original usermenu. So during the portalling, a special
         * function is bound to the menu base which can subvert the "this" and operate inside the usermenu, despite being executed inside the supermenu.
         *
         * Yes, this is dark magic. Change my job title to "Professor of the Dark Arts" and I might explain more.
         *
         * (n.b. technically you could also use this to override the execution path in other ways too)
         */
        portalledFunc: {
            type: Function,
            default: null
        }
    },
    methods: {
        /**
         * Callback for the menuing system that allows a button to invoke an "event" in this component.
         *
         * @param data {Object}
         */
        handleItemInvoke(data)
        {
            if(this.portalledFunc !== null)
            {
                this.portalledFunc(data.name, data.params);
                this.supermenu.close();
                return;
            }

            this.$parent[data.name].call(this, data.params);
        },

        /**
         * Callback for the menuing system that allows a button to invoke a modal in this component.
         *
         * @param data
         */
        handleItemModalInvoke(data)
        {
            console.log('modalInvoke', data);
        }
    }
};

/**
 * Common functions that implement the standard MenuAPI at the base level. Uses item data value. Meant for self-contained instances.
 */
export let MenuCommonData = {
    mixins: [ Base ],
    data()
    {
        return {
            items: []
        };
    },
    methods: {
        /**
         * Callback for the menuing system that allows a button to invoke an "event" in this component.
         *
         * @param data {Object}
         */
        handleItemInvoke(data)
        {
            this[data.name].call(this, data.params);
        },

        /**
         * Callback for the menuing system that allows a button to invoke a modal in this component.
         *
         * @param data
         */
        handleItemModalInvoke(data)
        {
            console.log('modalInvoke', data);
        }
    }
};
