<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="page-body">
        <div class="page-body-content-container">
            <TabBar v-if="tabs.length > 0" :tabs="tabs" :activeTabIndex="activeTabId"/>
            <slot name="page-body-constant-content"/>
            <div class="page-body-item" v-if="tabs.length > 0">
                <v-tabs-items :value="activeTab" touchless>
                    <v-tab-item v-for="(tab, index) in tabs" :key="index">
                        <v-container fluid>
                            <slot v-if="hasDynamicTabSlot" name="tab-slot" v-bind:tab="tab"/>
                            <slot v-else :name="'tab-slot-' + tab.id" v-bind:tab="tab"/>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </div>
            <!-- This section is displayed if no tabs are present in the view, preventing the tab bar from being displayed -->
            <div class="page-body-item" v-if="tabs.length === 0">
                <slot name="single-page"/>
            </div>
        </div>
    </div>
</template>

<script>
import TabBar from '@/components/Layout/TabBar';

/**
 * Basic page body that slots content from the server.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'PageBody',
    components: { TabBar },
    props: {

        /**
         * List of tabs from the server (not containing layout data)
         */
        tabs: {
            type: Array,
            default: () => []
        },

        tabId: {
            type: [ Number, String ]
        }

    },
    computed: {
        activeTabId()
        {
            let theTabId = this.tabId;
            if (this.tabId === -1)
            {
                //No tab set.
                //Loop through tabs
                for (let i = 0, j = this.tabs.length; i < j; i++)
                {
                    if (this.tabId === -1)
                    {
                        theTabId = this.tabs[i].id;
                        break;
                    }
                    else if (this.tabs[i].id < this.tabId)
                    {
                        theTabId = this.tabs[i].id;
                        break;
                    }
                }
            }
            return theTabId;
        },

        /**
         * Get the active tab index.
         *
         * @returns {any}
         */
        activeTab()
        {
            return this.tabs.findIndex(x => x.id === this.activeTabId);
        },

        hasDynamicTabSlot()
        {
            return !!(this.$slots['tab-slot'] || this.$scopedSlots['tab-slot']);
        }
    },
    watch: {
        tabId()
        {
            this.$emit('tab-changed', this.tabId);
        }
    },
    methods: {
        /**
         * Generate the ID for the tab wrapper to link it to the button for AIRA compliance.
         *
         * @param tab {Object} the data for the tab from the server
         *
         * @returns {string} an ID to give the tab wrapper
         */
        tabContainerId(tab)
        {
            return `tab-container-${ tab.id }`;
        },

        /**
         * Generate the ID ref to link tabs to containers for ARIA compliance.
         *
         * @param tab {Object}
         *
         * @returns {string}
         */
        ariaLabelledBy(tab)
        {
            return `#tab-${ tab.id }`;
        }
    }
};
</script>

<style lang="scss" scoped>

.page-body {

    .page-body-content-container {
        position: relative;

        .page-body-item {
            padding: 15px 15px 15px 15px;

            flex: 1 0 auto;
            height: 100%;

            & > .v-tabs-items {
                background-color: unset;
            }
        }

    }

}

</style>

<style lang="scss">

.fade-enter-active > div, .fade-leave-active > div {
    transition: .25s opacity;
}

.fade-enter > div, .fade-leave-to > div {
    opacity: 0;
}
</style>
