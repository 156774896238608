<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <a :id="tabId"
       href="#"
       class="tab-button"
       :class="{'active': active, 'icon-only': isIconOnly, 'has-icon':hasIcon}"
       @click.prevent="buttonClicked"
       role="tab"
       :aria-label="label"
       :aria-controls="ariaControls"
       :aria-selected="active"
       :tabindex="active ? -1 : 0"
       v-ripple
    >

        <div class="border"/>
        <span class="tab-label">
            <span v-html="label"></span>
            <v-icon v-if="hasIcon || isIconOnly" :color="isIconOnly ? highlight_colour : 'white'">{{ icon }}</v-icon>
        </span>
    </a>
</template>

<script>
import { mapState } from 'vuex';

/**
 * A button on the TabBar
 *
 * TODO: make this emit events instead of directly update the router.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'TabButton',
    props: {
        /**
         * The label used on the tab button.
         */
        label: {
            type: String
        },

        /**
         * The ID of the tab button.
         */
        id: {
            type: [ Number, String ]
        },

        /**
         * The active state of this button.
         */
        active: {
            type: Boolean
        },

        changedPropName: {
            type: String,
            default: 'tabId'
        },

        icon: {
            type: String,
            default: null
        }
    },
    computed: {

        ...mapState([ 'highlight_colour' ]),

        /**
         * Generate the value of the AIRA controls property.
         *
         * @returns {string}
         */
        ariaControls()
        {
            return `tab-container-${ this.id }`;
        },

        /**
         * Generate the value of the ID attr.
         *
         * @returns {string}
         */
        tabId()
        {
            return `tab-${ this.id }`;
        },

        hasIcon()
        {
            return this.icon !== null && (this.label !== null && this.label !== undefined && this.label !== '');
        },

        isIconOnly()
        {
            return this.icon !== null && (this.label === null || this.label === undefined || this.label === '');
        }
    },
    methods: {

        /**
         * Fired when this tab is clicked to update the active index of the tab page.
         */
        buttonClicked()
        {
            if(this.$route.params[this.changedPropName] == this.id)
                return;
            let params = {};
            params[this.changedPropName] = this.id;
            if(this.changedPropName === 'section')
                params['subView'] = null;
            this.$router.push({ params });
        }
    }
};
</script>

<style lang="scss" scoped>
.tab-button {

    &.icon-only {
        background-color: unset;
        border: 2px solid var(--highlight-colour);
        border-radius: 100%;
        transition: 0.2s border, 0.2s transform;

        &:hover, &.active {
            border-color: white;
            transform: scale(1.1);
        }

        .tab-label {
            padding-right: 6px;
            padding-left: 6px;
        }
    }

    background-color: var(--highlight-colour);
    height: 40px;
    max-height: 40px;
    margin-right: .75rem;
    border-radius: 1.2em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    color: white;

    &:hover .border, &:focus .border, &.active .border {
        border-color: white;
    }

    .border {
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        border: 2px solid transparent;
        border-radius: 1.1em;
        transition: 0.2s border;
        z-index: 0;
    }

    &.has-icon {
        .tab-label {
            padding-right: 9px;
        }
    }

    .tab-label {
        padding-left: 15px;
        padding-right: 15px;

        display: flex;
        justify-content: center;
        align-items: center;
    }


}
</style>
