/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import { mapState } from 'vuex';

/**
 * Common functions that implement the standard MenuAPI for menu items.
 */
export default {
    inject: {
        'handleItemInvoke': {
            default: () => null
        },
        'handleItemModalInvoke': {
            default: () => null
        }
    },
    props: {
        /**
         * The text to be displayed.
         */
        label: {
            type: String
        },

        /**
         * The anchor target for the link.
         */
        href: {
            type: String,
            default: null
        },

        /**
         * An event to fire when this is clicked, instead of navigating to a URL.
         */
        event: {
            type: Object,
            default: null
        },

        /**
         * A modal to open when this is clicked, instead of navigating to a URL.
         */
        modal: {
            type: String,
            default: null
        },

        /**
         * Should this button generate a router link instead of an anchor link?
         */
        router: {
            type: Boolean,
            default: false
        },

        /**
         * The SVG shown on the right side of the menu.
         */
        icon: {
            type: String
        },

        /**
         * The colour of the icon when not hovered.
         */
        icon_colour: {
            type: String,
            default: '#9a9da0'
        },

        /**
         * The colour of the background when not hovered.
         */
        idle_colour: {
            type: String,
            default: 'transparent'
        },

        /**
         * The colour of the background when hovered.
         */
        hover_colour: {
            type: String,
            default: '#f8f9fa'
        },

        /**
         * Should this item be in an "active" state?
         */
        active: {
            type: Boolean,
            default: false
        },

        /**
         * Should this item be ignored outside of dev mode
         */
        dev: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState([ 'flags' ]),
        isVisible()
        {
            return !(!this.flags.development && this.dev);
        }
    },
    methods: {
        handleClick(evt)
        {
            if (this.href !== null)
                return;
            //This has to be here to make sure that if the link is just an anchor, we don't intercept, else we handle custom logic.
            //Otherwise I'd have put it in the template.
            evt.preventDefault();

            if (this.event !== null)
                this.handleItemInvoke(this.event);

            if (this.modal !== null)
                this.handleItemModalInvoke(this.modal);

        }
    }

};