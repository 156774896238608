<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-list class="mb-0 pb-0">
        <v-list-item @click="onClick" two-line>
            <v-list-item-icon style="margin-bottom: auto; margin-top: auto;">
                <v-progress-circular :value="trueProfileProgress" size="50" rotate="270" color="blue">
                    <div style="background-color: green; border-radius: 100%; width: 40px;height: 40px; display: flex; justify-content: center; align-items: center;">
                        <v-icon color="white">$avatar</v-icon>
                    </div>
                </v-progress-circular>

            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    <span v-if="trueProfileProgress === 100">
                        Update your Profile
                    </span>
                    <span v-else>
                        Finish Your Profile
                    </span>
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: unset; margin-right: 5px;">
                    Your profile is {{ trueProfileProgress }}% complete.
                    <span v-if="trueProfileProgress === 100">Click to update your choices to reflect your current situation.</span>
                    <span v-else>Click here to continue filling out your information.</span>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'QuestionniareSupermenuWidget',
    inject: [ 'openOnboardingQuestionnaire', 'supermenu' ],
    computed: {

        ...mapGetters('user', [ 'profileProgress' ]),

        trueProfileProgress()
        {
            return Number(this.profileProgress.toFixed(0));
        }
    },
    methods: {
        onClick()
        {
            this.openOnboardingQuestionnaire();
            this.supermenu.close();
        }
    }
};
</script>

<style scoped>

</style>