<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <v-alert border="left" colored-border color="primary" elevation="2">
        <v-row align="center">
            <v-col class="grow">
                <b>Not Implemented</b><br>
                This feature is not currently implemented in this build. If this is a production version, this is a bug and should be reported to the development team immediately.
            </v-col>
            <v-col class="shrink">
                <v-btn color="primary" @click="openGitHub" text>GitHub</v-btn>
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
export default {
    name: 'NotImplemented',
    methods: {
        openGitHub()
        {
            window.open('https://github.com/positive-transition-limited/app/issues', '_blank');
        }
    }
};
</script>

<style scoped>

</style>