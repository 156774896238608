<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="password-entry">
        <v-text-field :autocomplete="autocomplete" v-model="password" :type="this.show_password ? 'text' :'password'" :label="label" :disabled="disabled">
            <template #append>
                <v-btn block icon @click.stop="revealPassword">
                    <v-icon v-show="!show_password">mdi-eye</v-icon>
                    <v-icon v-show="show_password">mdi-eye-off</v-icon>
                </v-btn>
            </template>
        </v-text-field>
        <v-progress-linear v-if="zxcvbn && !(password === null || password.length === 0)" :value="(score/5)*100" :color="strengthVariant" height="25" rounded>{{ strengthText }}</v-progress-linear>
        <div class="invalid-feedback" v-if="!isStrongEnough">
            <div v-if="(zxcvbn || this.match !== undefined) && !(password === null || password.length === 0)" v-html="suggestions"/>
        </div>
    </div>
</template>

<script>
/**
 * Component for entering password validated by zxcvbn
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'PPasswordEntry',
    props: {

        /**
         * The ID of the element for ARIA generation
         */
        id: {
            type: String,
            required: true
        },

        /**
         * The 'name' of the input to hint to password managers
         */
        name: {
            type: String,
            default: ''
        },

        /**
         * Should the element run zxcvbn validation?
         */
        zxcvbn: {
            type: Boolean,
            default: false
        },

        /**
         * The textual label for the element
         */
        label: {
            type: String,
            default: ''
        },

        /**
         * The value of the element as an object containing text and zxcvbn score
         */
        value: {
            type: Object,
            default: () =>
            {
            }
        },

        /**
         * A password object from another password entry that must match for this input to validate.
         */
        match: {
            type: Object
        },

        enable_prepend: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        autocomplete: {
            type: String,
            default: null
        }
    },
    data()
    {
        return {

            /**
             * The realtime password string
             */
            password: '',

            /**
             * The response from zxcvbn about the strength of the password
             */
            zxcvbn_feedback: {},

            show_password: false
        };
    },

    /**
     * Copy the model into the temp data store
     */
    mounted()
    {
        if(this.value.password === undefined)
        {
            this.password = '';
            return;
        }
        this.password = this.value.password;
    },
    computed: {

        /**
         * Checks if the password is strong enough to validate this element.
         *
         * @returns {boolean}
         */
        isStrongEnough()
        {

            if(this.match !== undefined && this.match.password !== this.password)
                return false;

            if(!this.zxcvbn)
                return true;

            return this.zxcvbn_feedback.score >= 3;
        },

        /**
         * Get the numeric "human" score for display
         *
         * @returns {number}
         */
        score()
        {
            if(this.zxcvbn_feedback === undefined || this.zxcvbn_feedback.score === undefined)
                return 1;
            return this.zxcvbn_feedback.score + 1;
        },

        /**
         * Get the textual display for the progress bar rating the password.
         *
         * @returns {string}
         */
        strengthText()
        {
            switch(this.score - 1)
            {
                case 0:
                    return 'Insecure';
                case 1:
                    return 'Poor';
                case 2:
                    return 'Okay';
                case 3:
                    return 'Good';
                case 4:
                    return 'Excellent';
            }
            return 'Unknown';
        },

        /**
         * Get the colour variant of the progress bar for each score.
         *
         * @returns {string}
         */
        strengthVariant()
        {
            switch(this.score - 1)
            {
                case 0:
                    return 'red';
                case 1:
                    return 'orange';
                case 2:
                    return 'grey';
                case 3:
                    return 'blue';
                case 4:
                    return 'green';
            }
            return 'danger';
        },

        /**
         * Format the suggestions from zxcvbn into a UL for display in the invalid feedback.
         *
         * @returns {string}
         */
        suggestions()
        {
            let string = '<ul style="margin-bottom: 0">';

            //Add a manual section for non-matching passwords
            if(this.match !== undefined && this.match.password !== this.password)
                string += '<li><b>Warning</b>: passwords do not match!</li>';

            //If there is no feedback from zxcvbn, just return now.
            if(this.zxcvbn_feedback === undefined || this.zxcvbn_feedback.feedback === undefined || this.zxcvbn_feedback.feedback.suggestions === undefined)
                return `${ string }</ul>`;

            //Prepend the warning from zxcvbn as an important warning
            if(this.zxcvbn_feedback.feedback.warning !== '')
                string += `<li><b>Warning:</b> ${ this.zxcvbn_feedback.feedback.warning }</li>`;

            //Append all the zxcvbn feedback lines as uls
            for(let x of this.zxcvbn_feedback.feedback.suggestions)
                string += `<li>${ x }</li>`;

            return `${ string }</ul>`;
        }
    },
    watch: {

        /**
         * Updates the temp data from the v-model
         */
        value()
        {
            if(this.value.password === undefined)
            {
                this.password = '';
                return;
            }
            this.password = this.value.password;
        },

        /**
         * When the user changes the password text, re-evaluate it with zxcvbn.
         */
        async password()
        {
            if(this.zxcvbn)
            {
                let zxcvbnFunc = await import(/* webpackMode: "lazy" */ 'zxcvbn').then(mod => mod.default);
                this.zxcvbn_feedback = zxcvbnFunc(this.password);
            }


            //Fire off the input event to complete the v-model up-bind.
            this.$emit('input', { password: this.password, score: this.score, valid: this.isStrongEnough });
        }
    },
    methods: {
        revealPassword()
        {
            this.show_password = !this.show_password;
        }
    }
};
</script>

<style lang="scss" scoped>
.password-entry {

    input {
        border-right-width: 0px;
    }

    .reveal-password-btn {
        border-color: #ced4da !important;
        background-color: transparent !important;
        border-left-width: 0px;

        .loaded-svg {
            width: 24px;
            height: 24px;

            &:hover {
                opacity: 0.8;
            }
        }
    }

}
</style>