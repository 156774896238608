/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import NotFound from '@/views/System/NotFound';

Vue.use(VueRouter);

export const Utilities = {
    ProcessRoutes(context)
    {
        return context.keys()
            .map(context) // import module
            .map(m => m.default) // get `default` export from each resolved module
            .flat()
            .filter(x => x !== undefined);
    }
};

function loadRoutes()
{
    const context = require.context('@/views', true, /routes.js$/i);
    return Utilities.ProcessRoutes(context);
}

const routes = [
    ...loadRoutes(),
    {
        path: '*',
        name: 'Not Found',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});

router.beforeEach(async (to, from, next) =>
{
    try
    {
        await store.dispatch('initialise');
        if (!to.meta.insecure)
            await store.dispatch('initialiseSecure');
        next();
    }
    catch (e)
    {
        if (typeof e === 'object' && ('code' in e))
        {
            if (e.code >= 500 && e.code < 600)
            {
                await store.commit('setCriticalError', { title: 'Critical Error', subtitle: e.message });
                return;
            }
        }
        next('/');
    }
});

function getChildMatchWithValidMeta(parent, metaName)
{
    if (parent.meta[metaName] !== undefined)
        return parent;
    for (let i = parent.matched.length - 1; i >= 0; i--)
    {
        if (parent.matched[i].meta[metaName] !== undefined)
            return parent.matched[i];
    }
    return undefined;
}

router.beforeEach((to, from, next) =>
{
    let routeWithColour = getChildMatchWithValidMeta(to, 'colour');
    if (routeWithColour !== undefined)
    {
        let colour = routeWithColour.meta.colour(to);
        if (colour.indexOf('#') < 0)
            colour = '#' + colour;
        store.commit('set_highlight_colour', colour);
    }
    let routeWithTitle = getChildMatchWithValidMeta(to, 'title');
    if (routeWithTitle !== undefined)
    {
        document.title = routeWithTitle.meta.title(to) + ' | Positive Transition';
    }
    else
    {
        document.title = to.name + ' | Positive Transition';
    }
    next();
});

export default router;
