<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div>
        <PPleaseWait :value="dialogs.please_wait" text="Please wait..."/>
        <PDropdownMenu class="demo-menu" :items="dropdown_items">
            <template v-slot:activator="{ on, attrs }">
                <v-btn text color="white" dark v-bind="attrs" v-on="on">
                    Demo
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
        </PDropdownMenu>
    </div>
</template>

<script>
import PDropdownMenu from '@/components/Layout/Menus/DropdownMenu/PDropdownMenu';

/**
 * Menu containing actions for Tim's demos.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'DemoMenu',
    components: { PDropdownMenu },
    data()
    {
        return {
            isLoading: true,
            failed: false,

            /**
             * The items from the menu that are obtained from the server.
             */
            dropdown_items: [],
            dialogs: {
                please_wait: false
            }
        };
    },
    mounted()
    {
        this.$ptAjax({
            url: '/data/menu/demo',
            type: 'get',
            silent: true,
            error: {
                run: () =>
                {
                    this.isLoading = false;
                    this.failed = true;
                }
            },
            success: {
                run: (r) =>
                {
                    this.isLoading = false;
                    this.dropdown_items = r.body.menu;
                }
            }
        });
    },
    methods: {
        async resetStatus()
        {
            this.dialogs.please_wait = true;
            await this.$ptAjax({
                url: '/data/demo/reset_status',
                type: 'post',
                silent: { success: true }
            });
            await this.$store.dispatch('progression/loadInitialState');
            this.dialogs.please_wait = false;
            window.location.reload();
        },
        async completeCV()
        {
            this.dialogs.please_wait = true;
            await this.$ptAjax({
                url: '/data/demo/autocomplete_cv',
                type: 'post',
                silent: { success: true },
                success: {
                    run: () =>
                    {
                        window.location.reload();
                    }
                }
            });

            this.dialogs.please_wait = false;
        },
        async randomProgress()
        {
            this.dialogs.please_wait = true;
            await this.$ptAjax({
                url: '/data/demo/random_progress',
                type: 'post',
                silent: { success: true }
            });
            await this.$store.dispatch('progression/loadInitialState');
            this.dialogs.please_wait = false;
        },
        async resetOnboard()
        {
            this.dialogs.please_wait = true;
            await this.$ptAjax({
                url: '/data/demo/reset_onboarding',
                type: 'post',
                silent: { success: true }
            });
            await this.$store.dispatch('progression/loadInitialState');
            this.dialogs.please_wait = false;
        },
        async resetLocation()
        {
            this.dialogs.please_wait = true;
            await this.$ptAjax({
                url: '/data/demo/reset_location',
                type: 'post',
                silent: { success: true }
            });
            await this.$store.dispatch('progression/loadInitialState');
            this.dialogs.please_wait = false;
            window.location.reload();
        },
        async randomAddress()
        {
            this.dialogs.please_wait = true;
            await this.$ptAjax({
                url: '/data/demo/random_address',
                type: 'post',
                silent: { success: true }
            });
            await this.$store.dispatch('progression/loadInitialState');
            this.dialogs.please_wait = false;
            window.location.reload();
        }
    }
};
</script>

<style lang="scss" scoped>
.demo-menu {
    @media(min-width: 991px) {
        margin-left: 15px;
    }
}
</style>
