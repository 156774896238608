<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div class="pt-progress-bar" :class="classType" :style="cssVariables">
        <div v-if="!hide_percent">{{ percentValue }}%</div>
        <div v-if="!hide_percent" role="progressbar" :aria-valuenow="percent" aria-valuemin="0" aria-valuemax="100">{{ percentValue }}%</div>
        <div v-else role="progressbar" :aria-valuenow="percent" aria-valuemin="0" aria-valuemax="100"/>
    </div>
</template>

<script>
/**
 * Defines a global progressbar system.
 *
 * @author Ned Hyett <edward.hyett@plus-t.co.uk>
 */
export default {
    name: 'PProgressBar',
    props: {

        /**
         * The percent of the progress bar.
         */
        percent: {
            type: [ String, Number ],
            default: 0
        },

        /**
         * The colour of the progress bar.
         */
        colour: {
            type: String,
            default: '#f00'
        },

        /**
         * Should the progress bar display as indeterminate?
         */
        indeterminate: {
            type: Boolean,
            default: false
        },

        /**
         * Should the progress bar have a solid background.
         */
        solid: {
            type: Boolean,
            default: false
        },

        /**
         * Should the progress bar hide the percent text?
         */
        hide_percent: {
            type: Boolean,
            default: false
        },

        /**
         * How many decimal points the text display should be fixed to.
         */
        fixed: {
            type: Number,
            default: 0
        }
    },
    computed: {
        cssVariables()
        {
            return { '--progress-colour': this.colour, '--progress-amt': `${ this.percent }%` };
        },
        classType()
        {
            return { 'indeterminate': this.indeterminate, 'progress-solid': this.solid };
        },
        percentValue()
        {
            return Number(this.percent ?? 0).toFixed(this.fixed);
        }
    }
};
</script>

<style lang="scss" scoped>
.pt-progress-bar {
    position: relative;
    border: 2px solid var(--progress-colour);
    height: 30px;
    border-radius: 15em;
    text-align: center;

    &.progress-solid {
        border-color: #fff;
        background: #fff;
    }

    &.indeterminate {
        div {
            color: transparent !important;

            &:last-of-type {
                animation-duration: 2s;
                animation-name: indeterminate-bar;
                animation-iteration-count: infinite;
                animation-direction: normal;
                animation-timing-function: linear;
            }
        }
    }

    &:not(.indeterminate) {
        div {
            &:last-of-type {
                clip-path: inset(0 calc(100% - var(--progress-amt)) 0 0);
            }
        }
    }

    div {
        top: 2px;
        right: 2px;
        left: 2px;
        bottom: 2px;
        margin: 0;
        display: inline-block;
        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 15em;
        transition: clip-path 0.2s ease;

        &:first-of-type {
            background-color: transparent;
            color: var(--progress-colour);
        }

        &:last-of-type {
            color: white;
            background-color: var(--progress-colour);
        }
    }
}

@keyframes indeterminate-bar {
    0% {
        clip-path: inset(0 100% 0 0%);
    }
    10% {
        clip-path: inset(0 60% 0 0%);
    }
    90% {
        clip-path: inset(0 0% 0 60%);
    }
    100% {
        clip-path: inset(0 0% 0 100%);
    }
}
</style>