<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <PageBody>
        <template #single-page>
            <v-card>
                <v-card-title class="rubik">404 - Not Found</v-card-title>
                <v-card-text>This is not the page you are looking for.</v-card-text>
                <v-card-actions>
                    <v-btn to="/dashboard" color="primary">Go to dashboard</v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </PageBody>
</template>

<script>
import PageBody from '@/components/Layout/PageBody';

export default {
    name: 'NotFound',
    components: { PageBody }
};
</script>

<style scoped>

</style>