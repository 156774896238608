export default 
{
    install(Vue)
    {
        Vue.prototype.$ptErrorReport = function(){

            let ua = window.navigator.userAgent;

            /**
             * Generic function for sending the error data
             * @param {*} data 
             */
            let send_error = (data) => {
                this.$ptAjax({
                    url:'/data/submit-error',
                    type:'post',
                    silent: true,
                    data:data
                });
            };

            /**
             * Adds global error handler to vue
             * @param {*} error 
             * @param {*} vm 
             * @param {*} info 
             */
            Vue.config.errorHandler = function (error, vm, info) {                
                send_error({
                    type : 'vue',
                    userAgent : ua,
                    data : {
                        error : error,
                        info : info
                    }
                });
            }

            /**
             * Catch generic JS errors
             */
            window.addEventListener('error',(e)=>{
                /**
                 * e.message
                 * e.colno //column where error occurred
                 * e.lineno //line number
                 * e.filename //url
                 * e.error.stack //stack trace
                 */
                send_error({
                    type: 'generic',
                    userAgent : ua,
                    data: {
                        message : e.message,
                        colno: e.colno,
                        lineno: e.lineno,
                        filename: e.filename,
                        stackTrace: e.error.stack
                    }
                });                
            });
            
            /**
             * Catch any failed promises
             */
            window.addEventListener('unhandledrejection', function(e) {
                //e.reason contains the reason for the rejection
                send_error({
                    type : 'promise',
                    userAgent : ua,
                    data : {
                        reason : e.reason
                    }
                });
            });
        }
    }
}
