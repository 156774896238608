<!--
  - Copyright © 2020-2021 Positive Transition LTD
  - All rights reserved
  - For more information, please visit https://plus-t.co.uk/license
  -->

<template>
    <div>
        <svg
                xmlns="http://www.w3.org/2000/svg"
                height="256"
                width="256"
                xml:space="preserve"
                viewBox="0 0 1440.3 1438.3"
                y="0px"
                x="0px">
            <g>
                <path class="spinner" d="m 1229.4,174.1 -49,13.1 27.8,27.8 -488,488.1 -34.6,-34.6 v 0 L 232.1,215 259.9,187.2 211,174.1 162,161 l 13.1,49 13.1,48.9 27.8,-27.8 488.1,488.1 -34.6,34.5 v 0 l -453.5,453.5 -27.8,-27.8 -13.1,49 -13.1,48.9 49,-13.1 48.9,-13.1 -27.8,-27.8 488.1,-488.1 34.5,34.6 v 0 l 453.5,453.5 -27.8,27.8 49,13.1 48.9,13.1 -13.1,-48.9 -13.1,-49 -27.8,27.8 -488.1,-488 34.6,-34.6 453.5,-453.5 27.8,27.8 13.1,-48.9 13.1,-49 z"/>
                <path d="M 1015.1,718.90002 A 295.20001,295.20001 0 0 1 719.90002,1014.1 295.20001,295.20001 0 0 1 424.70001,718.90002 295.20001,295.20001 0 0 1 719.90002,423.70001 295.20001,295.20001 0 0 1 1015.1,718.90002" style="fill:none;stroke:#020203;stroke-width:16;stroke-miterlimit:10"/>
            </g>
            <g>
                <path d="M 1185.3,708.2 H 731.4 V 254.3 H 709.1 V 708.2 H 255.2 v 22.2 h 453.9 v 454 h 22.3 v -454 h 453.9 z"/>
                <g>
                    <path style="fill: #020203;" d="M692.5,220.2c-0.8,0-1.5-0.2-2-0.7s-0.7-1.2-0.7-2v-80.7c0-0.9,0.2-1.6,0.7-2.1s1.2-0.7,2-0.7h2.6    c0.8,0,1.4,0.2,1.8,0.6c0.4,0.4,0.7,0.7,0.7,0.9l45.5,69.5v-68.2c0-0.9,0.2-1.6,0.7-2.1s1.2-0.7,2-0.7h2.2c0.9,0,1.6,0.2,2.1,0.7    s0.7,1.2,0.7,2.1v80.6c0,0.8-0.2,1.5-0.7,2s-1.2,0.8-2,0.8h-2.6c-0.8,0-1.4-0.2-1.8-0.6c-0.4-0.4-0.6-0.7-0.8-0.9l-45.4-69.3v68    c0,0.8-0.2,1.5-0.7,2s-1.2,0.7-2.1,0.7L692.5,220.2z"/>
                </g>
                <g>
                    <path style="fill: #020203;" d="M1221.3,762.2c-0.8,0-1.5-0.2-2-0.7s-0.7-1.2-0.7-2v-80.7c0-0.9,0.2-1.6,0.7-2.1s1.2-0.7,2-0.7    h48.1c0.9,0,1.6,0.2,2.1,0.7s0.7,1.2,0.7,2.1v1.9c0,0.8-0.2,1.5-0.7,2s-1.2,0.7-2.1,0.7h-43v31.4h40.3c0.9,0,1.6,0.2,2.1,0.7    s0.7,1.2,0.7,2.1v1.9c0,0.8-0.2,1.5-0.7,2s-1.2,0.7-2.1,0.7h-40.3v32.7h44c0.9,0,1.6,0.2,2.1,0.7s0.7,1.2,0.7,2.1v1.9    c0,0.8-0.2,1.5-0.7,2s-1.2,0.7-2.1,0.7h-49.1V762.2z"/>
                </g>
                <g>
                    <path style="fill: #020203;" d="M167.1,762.2c-1,0-1.7-0.2-2.3-0.7c-0.5-0.5-0.9-1.2-1.2-2.3l-16.9-79.4    c-0.1-0.3-0.1-0.6-0.2-0.9c0-0.3-0.1-0.5-0.1-0.6c0-0.7,0.2-1.2,0.7-1.7s1.1-0.7,1.7-0.7h2.6c1.6,0,2.5,0.6,2.7,1.9l14.4,70.3    l15.3-50.2c0.2-0.7,0.7-1.4,1.3-2c0.6-0.6,1.5-0.9,2.5-0.9h2.7c1.2,0,2,0.3,2.6,0.9c0.6,0.6,1,1.2,1.2,2l15.3,50.2l14.4-70.3    c0.2-1.2,1.2-1.9,2.7-1.9h2.6c0.7,0,1.2,0.2,1.7,0.7s0.7,1.1,0.7,1.7c0,0.1,0,0.3-0.1,0.6c0,0.3-0.1,0.6-0.2,0.9l-16.9,79.4    c-0.2,1.1-0.6,1.8-1.2,2.3c-0.5,0.5-1.3,0.7-2.3,0.7H209c-1,0-1.8-0.2-2.4-0.7s-1.1-1.2-1.4-2.2l-16.4-52l-16.4,52    c-0.3,1-0.8,1.7-1.4,2.2s-1.4,0.7-2.4,0.7H167.1z"/>
                </g>
                <g>
                    <path style="fill: #020203;"
                          d="M720.5,1304.3c-6.7,0-12.3-1.1-16.9-3.2c-4.6-2.1-8.2-4.9-10.7-8.3s-3.8-6.9-3.9-10.7    c0-0.7,0.2-1.2,0.7-1.7s1.1-0.7,1.9-0.7h2.5c0.8,0,1.5,0.2,1.9,0.7c0.5,0.5,0.7,1.1,0.8,1.7c0.3,2.3,1.4,4.6,3.1,6.8    c1.7,2.3,4.3,4.2,7.6,5.7s7.7,2.3,13.1,2.3c8.1,0,13.9-1.5,17.6-4.4c3.7-2.9,5.5-6.8,5.5-11.8c0-3.3-1-5.9-2.9-8    c-1.9-2-4.9-3.8-8.8-5.4c-3.9-1.6-9-3.4-15.2-5.4c-5.8-1.9-10.6-3.8-14.4-5.9c-3.8-2-6.6-4.5-8.3-7.3c-1.8-2.9-2.6-6.6-2.6-11.1    c0-4.2,1.1-8,3.3-11.3s5.4-6,9.7-8c4.2-2,9.4-3,15.5-3c4.8,0,9.1,0.7,12.7,2c3.6,1.3,6.6,3.1,9,5.2c2.4,2.2,4.2,4.5,5.4,7    s1.9,5,2,7.3c0,0.6-0.2,1.1-0.6,1.7c-0.4,0.5-1.1,0.8-2,0.8H744c-0.4,0-0.9-0.2-1.5-0.5s-1-1-1.2-2c-0.5-4.1-2.7-7.5-6.5-10.2    s-8.8-4-14.9-4c-6.1,0-11.1,1.2-14.9,3.6c-3.9,2.4-5.8,6.1-5.8,11.2c0,3.3,0.9,6,2.6,8.1c1.7,2.1,4.4,3.9,8.1,5.5    c3.7,1.6,8.5,3.3,14.3,5.2c6.2,2,11.3,3.9,15.3,5.9s7,4.4,9,7.2c2,2.8,3,6.5,3,11c0,5-1.3,9.3-3.8,12.8s-6.1,6.2-10.7,8    C732.4,1303.3,726.9,1304.3,720.5,1304.3L720.5,1304.3z"/>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'PLoadingSpinner'
};
</script>

<style lang="scss" scoped>
.spinner {
    animation-name: rotation-easeOutBounce;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
}

@keyframes rotation-easeOutBounce {
    0% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(95deg);
    }

    85% {
        transform: rotate(87deg);
    }

    90% {
        transform: rotate(92.5deg);
    }

    95% {
        transform: rotate(89deg);
    }

    100% {
        transform: rotate(90deg);
    }


}
</style>