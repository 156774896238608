/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

export default [
    {
        path: '/access-denied',
        name: 'Access Denied',
        component: () => import(/* webpackChunkName: "system"*/ '@/views/System/AccessDenied/AccessDeniedView.vue'),
        meta: {
            insecure: true,
            hide_navigation: true
        }
    },
    {
        path: '/consent',
        name: 'GDPR Consent Error',
        component: () => import(/* webpackChunkName: "system"*/ '@/views/System/Consent/ConsentView'),
        meta: {
            insecure: true,
            hide_navigation: true
        }
    }
];