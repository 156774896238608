/*
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

import { createCategory } from '@plust/client-common/src/PTLogger';
import PtAjax from '@plust/client-common/src/Ajax';

const PTLogger = createCategory('Vuex|User');

const DefaultState = {
    first_name: '',
    last_name: '',
    avatar: '/assets/icons/avatar.svg',
    join_date: '',
    onboarded: false,
    profileProgress: 33
};

/**
 * This Vuex module contains all of the data about the active user.
 */
export default {
    namespaced: true,
    state: () => (JSON.parse(JSON.stringify(DefaultState))),
    mutations: {
        setInitialState(state, data)
        {
            Object.assign(state, data);
            PTLogger.debug('Saved initial user data from server');
        },
        clearInitialState(state)
        {
            for (let x in state)
            {
                // eslint-disable-next-line no-prototype-builtins
                if (DefaultState.hasOwnProperty(x))
                    state[x] = DefaultState[x];
                else
                    delete state[x];
            }
        },
        update_user_name(state, data)
        {
            state.first_name = data[0];
            state.last_name = data[1];
        },
        update_profile_picture(state, newPicture)
        {
            state.avatar = newPicture;
        },
        set_onboarding_complete(state, isComplete)
        {
            state.onboarded = isComplete;
        },
        updateProfileCompleteness(state, completeness)
        {
            state.profileProgress = completeness;
        }
    },
    actions: {
        async loadInitialState({ commit })
        {
            PTLogger.debug('Loading initial user data from server...');
            await this._vm.$ptAjax({
                url: '/data/user',
                type: 'post',
                silent: true,
                success: {
                    run: (data) =>
                    {
                        commit('setInitialState', data.body.user);
                    }
                },
                error: {
                    run: (data) =>
                    {
                        PTLogger.error('Failed to get initial state.', data);
                        throw data;
                    }
                }
            });
        },

        async setOnboardingCompleted({ commit })
        {
            await PtAjax({
                url: '/data/onboarding',
                type: 'post',
                silent: { success: true },
                success: {
                    run: () =>
                    {
                        commit('set_onboarding_complete', true);
                    }
                }
            });
        },

        async loadLatestProfileCompleteness({ commit })
        {
            await PtAjax({
                url: '/data/questionnaire/progress',
                type: 'get',
                silent: { success: true },
                success: {
                    run: ({ body: { progress } }) =>
                    {
                        commit('updateProfileCompleteness', progress);
                    }
                }
            });
        },

        async clearSecureState({ commit })
        {
            commit('clearInitialState');
        }
    },
    getters: {
        all: (state) => state,
        profileProgress: (state) => state.profileProgress
    }
};